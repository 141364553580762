.EditForm-Invitation .EditForm-InvitationLink {
  margin-bottom: 3rem; }
  .EditForm-Invitation .EditForm-InvitationLink h3 {
    font-weight: 400; }
  .EditForm-Invitation .EditForm-InvitationLink p {
    margin-bottom: 0; }

.LiveItem-Invitation.body {
  padding-bottom: 10vh;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }
  .LiveItem-Invitation.body .section.copy {
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 30rem;
    margin: 0 auto; }
  .LiveItem-Invitation.body .Invitation-Message {
    display: block;
    margin: 2rem 0 1rem;
    font-weight: 200; }
  .LiveItem-Invitation.body #FB-Button,
  .LiveItem-Invitation.body #FB-Login-Btn {
    margin: 0 auto 0.5rem; }
  .LiveItem-Invitation.body .LoginWidget-Social {
    padding: 1rem 0 0.5rem;
    margin: 0 auto;
    max-width: 35rem;
    -webkit-justify-content: center;
            justify-content: center; }
    @media (min-width: 576px) {
      .LiveItem-Invitation.body .LoginWidget-Social {
        _padding-top: 4rem; } }

.CreatePage,
.EditPage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--pageBg);
  z-index: 9; }
  .CreatePage > .header,
  .EditPage > .header {
    box-shadow: var(--shadow); }
    .CreatePage > .header .Toolbar,
    .EditPage > .header .Toolbar {
      width: 100%; }
  .CreatePage > .body,
  .EditPage > .body {
    max-width: var(--maxContentWidth);
    overflow: hidden visible;
    min-height: 100%; }
  .CreatePage > .footer,
  .EditPage > .footer {
    box-shadow: var(--shadow); }
    .CreatePage > .footer .Toolbar,
    .EditPage > .footer .Toolbar {
      max-width: var(--maxContentWidth); }
      .CreatePage > .footer .Toolbar .leading,
      .EditPage > .footer .Toolbar .leading {
        grid-gap: 1.5em;
        gap: 1.5em; }
    .CreatePage > .footer .Dropdown,
    .EditPage > .footer .Dropdown {
      box-shadow: var(--shadow); }

/***************************
 ** Form specific styling **
 ***************************/
.ViewPage,
.EditModal.Modal,
.IEditItem {
  min-height: 100vh;
  width: 100%; }
  .ViewPage .form-nav,
  .EditModal.Modal .form-nav,
  .IEditItem .form-nav {
    max-width: var(--maxContentWidth);
    margin-left: auto;
    margin-right: auto;
    -webkit-justify-content: center;
            justify-content: center; }
  .ViewPage h1,
  .ViewPage .Mobiledoc,
  .ViewPage .form-field,
  .ViewPage .form-check,
  .ViewPage .__mobiledoc-editor,
  .EditModal.Modal h1,
  .EditModal.Modal .Mobiledoc,
  .EditModal.Modal .form-field,
  .EditModal.Modal .form-check,
  .EditModal.Modal .__mobiledoc-editor,
  .IEditItem h1,
  .IEditItem .Mobiledoc,
  .IEditItem .form-field,
  .IEditItem .form-check,
  .IEditItem .__mobiledoc-editor {
    display: block;
    max-width: var(--maxContentWidth);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem; }
  .ViewPage .form-input-object-field > .form-input-field > .form-input-object-field,
  .EditModal.Modal .form-input-object-field > .form-input-field > .form-input-object-field,
  .IEditItem .form-input-object-field > .form-input-field > .form-input-object-field {
    border-left: 4px solid var(--primary-20);
    padding-left: 1rem; }
  .ViewPage > .formlib-FormRows > .form-row-object > .form-input-field > .form-input-object-field,
  .EditModal.Modal > .formlib-FormRows > .form-row-object > .form-input-field > .form-input-object-field,
  .IEditItem > .formlib-FormRows > .form-row-object > .form-input-field > .form-input-object-field {
    border-width: 0;
    padding-left: 0; }

/**
 * Rich text widget styling
 * TODO: Move to proper packages
 **/
.RichText-Widget-left {
  float: left;
  margin: 0 1em 0.6em 0;
  width: 40%;
  min-width: 15rem; }

.RichText-Widget-right {
  float: right;
  margin: 0 0 0.6em 1em;
  width: 40%;
  min-width: 15rem; }

.RichText-Widget-center {
  display: block;
  text-align: center;
  margin: 2em 0; }

.RichText-Widget-inline {
  display: inline-block; }

.RichText-ImageContainer {
  position: relative; }

.RichText-Image {
  width: 100%; }

.RichText-PodcastContainer {
  position: relative; }

.RichText-Podcast {
  height: 21rem; }

.RichText-YoutubeContainer {
  position: relative; }

.RichText-Youtube {
  height: 21rem; }

.RichText-MediaContainer {
  position: relative; }

.RichText-ResultTable {
  width: 100%;
  counter-reset: resultTableNr;
  margin: 2rem 0 4rem; }

.RichText-ResultTableRow {
  border-bottom: 1px solid #ccc; }

.RichText-ResultTableCell {
  min-height: 1em;
  padding: 1rem 0.5rem 0.5rem; }

.RichText-ResultTableCell-Name {
  width: 70%;
  padding-left: 4rem; }

.RichText-ResultTableCell-Name:before {
  position: absolute;
  left: 0rem;
  width: 2.7rem;
  counter-increment: resultTableNr;
  content: counter(resultTableNr) "."; }

.RichText-ResultTableCell-Result {
  width: 30%;
  min-width: 5em;
  text-align: right; }

.Medium-placeholder {
  position: absolute;
  pointer-events: none;
  text-align: left;
  padding: 0.5rem 2rem;
  font-size: 1.5em; }

.Medium-rich {
  -webkit-user-modify: read-write;
  -webkit-user-select: text;
  user-select: text;
  text-align: left;
  padding: 0.5rem 2rem;
  font-size: 1.5em; }

.RichEditor-StickyFormattingToolbar {
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  z-index: 1; }

.RichEditor-StickyFormattingToolbar--hidden {
  display: none; }

@media (max-width: 500px) {
  .RichEditor-FormattingToolbarContainer {
    font-size: 12px; } }

.RichEditor-FormattingToolbar {
  margin-bottom: 1em; }

.RichEditor-FormattingButton {
  padding: 0.8em 1em;
  margin-right: 1px;
  background: gray;
  display: inline-block;
  color: white;
  cursor: pointer;
  min-width: 3em;
  text-align: center; }

.RichEditor-FormattingButton:hover {
  background-color: darkgray; }

.RichEditor-InsertActionButton {
  padding: 0.8em 1em;
  margin-right: 1px;
  background: #555;
  display: inline-block;
  color: white;
  cursor: pointer;
  min-width: 3em;
  text-align: center; }

.RichEditor-InsertActionButton:hover {
  background-color: darkgray; }

.RichEditor-WidgetButton {
  padding: 0.8em 1em;
  margin-right: 1px;
  background: #333;
  display: inline-block;
  color: white;
  cursor: pointer;
  min-width: 3em;
  text-align: center; }

.RichEditor-WidgetButton:hover {
  background-color: #222; }

.RichText-Widget {
  position: relative; }

.RichText-WidgetButtonToolbar {
  position: absolute;
  left: 0.5rem;
  top: 0.5rem; }

.RichText-WidgetFormattingButton {
  display: inline-block;
  width: 2em;
  height: 2em;
  padding: 0.2em;
  margin-left: 0.5em;
  border: 1px solid #fff;
  border-radius: 0.2em;
  background-color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  opacity: 0.3;
  transition: opacity 0.3s ease-in-out; }

.RichText-WidgetFormattingButton:hover {
  opacity: 1;
  background-color: white; }

.RichText-Widget:hover .RichText-WidgetFormattingButton {
  opacity: 1; }

.RichText-DeleteButton {
  position: absolute;
  bottom: 0.5em;
  right: 0.5em; }

.RichText-EditButton {
  position: absolute;
  top: 0.5em;
  right: 0.5em; }

.RichText-PodcastContainer {
  height: 20rem; }
