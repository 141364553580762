.VideoCard.isTemplate {
  & > .video-player {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 56%;
    background-color: var(--inputRowLineColor);

    &:after {
      content: 'Video';
      font-size: 2em;
      font-weight: bold;
      opacity: 0.15;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  & > .body {
    pointer-events: none;

    caption {
      width: 20em;
      height: 2em;
      margin: 0.75rem 0;
      background-color: var(--inputRowLineColor);
    }
  }
}

.VideoCard {
  position: relative;
  
  .video-player {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.3%;
  
  
    .poster-image {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      opacity: 0.95;
      transition: opacity 0.5s ease-in;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  
    &.video-playing {
      .poster-image {
        pointer-events: none;
        opacity: 0;
      }
    }
  
    .video-play {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%) scale(1.0);
      color: #fff;
      pointer-events: none;
  
      #OvalShadow {
        opacity: 0;
        transition: opacity 0.1s ease-in;
      }
    }
    
    &:hover .video-play {
      transform: translate(-50%, -50%) scale(1.04);
      #OvalShadow {
        opacity: 0.4;
      }
    }
  
    .video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
