.LivePage.Profile {

  .Filter {
    justify-content: center;
  }

  .profile-post {
    padding-top: 5rem;
  }
  
  .ListPage {
    margin-bottom: 0;
  }
  
  .ListPage-BlogPost {
    margin-top: 0;
    min-height: calc(100vh - 3rem); // Allow header image to scroll away (should use - var(--infernoUXHeaderHeight)
  }

  .IEditItem {
    padding: 0;
  }

  .Profile-Destinations,
  .Profile-Recipes,
  .Profile-Guides {
    max-width: 40rem;
    margin: 2rem auto 0;

    h2 {
      font-size: 1.2em;
    }
  }
}
