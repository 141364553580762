.AvatarImageFieldWidget.formlib-FileUploadWidget {
  position: relative;
  display: block;
  border-radius: 50%;

  .Onboarding-Avatar {
    margin: 0;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    gap: var(--gapVert);
    transform: translate(-50%, -75%);
  }

  button.change {
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }

  button.remove {
    color: white;
    &:hover {
      background-color: red;

    }
  }

  .upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);

    color: white;
    font-weight: 400;
    
    .IconButton {
      color: var(--iconInvertedFill);
      &:hover {
        color: var(--iconHover);
      }
      &:active {
        color: var(--iconActive);
      }
    }

    .formlib-UploadProgress {
      overflow: hidden;
      border-radius: 50%;
    }
  }
}