.Icon-Clock,
.Icon-People {
  vertical-align: text-bottom;
}

/* Edit Viwe */
.RecipeModal {
  .RecipeCard {
    .Gallery {
      max-width: 100%!important;
      margin-left: 0!important;
    }
  }

  #ingredients__Field .form-input-object-field {
    display: flex;
    justify-content: space-between;

    .form-row input {
      border-top: none;
      border-right: none;
      border-radius: 0;
    }
    .form-row:nth-child(1) {
      max-width: 4rem;
    }
  }

  #steps__Field .form-input-object-field {
    .form-row:nth-child(2) input {
      max-width: 4rem;
    }
  }
}

.RecipeCard.isTemplate {
  & > h2 {
    display: block;
    width: 15em;
    height: 1.5em;
    background-color: var(--inputRowLineColor);
  }

  & > .Gallery {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 56%;
    background-color: var(--inputRowLineColor);

    &:after {
      content: 'Recipe';
      font-size: 2em;
      font-weight: bold;
      opacity: 0.15;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  & > .body {
    pointer-events: none;

    caption {
      width: 20em;
      height: 2em;
      margin: 0.75rem 0;
      background-image: var(--templateListPlaceholder);
    }

    .ingredients ul {
      display: block;
      height: 10em;
      background-image: var(--templateListPlaceholder);
    }

    Button {
      background-color: var(--inputRowLineColor);
    }
  }
}

.RecipeCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  caption {
    font-size: 1em;
    margin-bottom: 1rem;
  }

  h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 1em;
  }

  .body {
    width: 100%;
  }

  .section {
    @media (max-width: 576px) {
      flex-direction: column-reverse;
    }
  }

  .ingredients {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    @media (max-width: 576px) {
      flex-direction: column-reverse;
    }

    h2 {
      margin: 0.5rem 0;
      font-size: 1em;
      font-weight: 500;
      text-align: left;
      color: var(--textAccentedColor);
    }

    ul {
      padding-left: 0;
      font-size: 0.9em;
      font-family: adobe caslon pro;
      margin-bottom: 0;
    }

    li {
      list-style: none;
      font-weight: 100;
      text-transform: lowercase;
      margin-bottom: 0.5em;
    }
  }

  .info {
    h3 {
      flex-grow: 0;
      width: auto;
      text-align: left;
      font-size: 1em;
      color: var(--textAccentedColor);
      
      margin-bottom: 1rem;
      white-space: nowrap;

      .Icon-People,
      .Icon-Clock {
        color: var(--iconAccentedFill);
        display: inline-block;
        width: 1.5rem;
        height: auto;
        margin-right: 0.25rem;
      }
    }

    i {
      position: relative;
      display: flex;
      align-content: center;
      margin-top: -0.5em;
      margin-bottom: 1em;
      color: var(--primaryBg);
    }
  }
  
  .steps {
    display: flex;
    flex-direction: column;
    justify-items: left;
    flex-grow: 1;
    height: fit-content;

    button {
      margin-bottom: 1rem;
    }

    & > p {
      margin-bottom: 1em;
    }

    ol {
      margin: 0;
      padding-left: 1.1rem;
    }

  }
}

.CookingTimer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  top: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  
  color: var(--primaryBg);
  font-family: Adobe Caslon Pro;
  font-size: 1.25em;
  line-height: 1em;
  padding: 0 1rem;
  border-radius: 1.8rem;
  height: 3.6rem;

  background-color: white;
  border: 2px solid var(--primaryBg);

  .Icon-Clock {
    color: var(--primaryBg);
    width: 2rem;
    margin-right: 1rem;
    vertical-align: sub;
  }

  i {
    margin-top: 0.1em;
  }

  .Icon-Pause {
    border-right: 5px solid var(--primaryBg);
    border-left: 5px solid var(--primaryBg);
    height: 1.4rem;
    width: 1rem;
    margin-left: 1.3rem;
    margin-right: 0.2rem;
  }

  .Icon-Play {
    border: 0.9rem solid transparent;
    border-left-color: var(--primaryBg);
    transform: scale(1.3, 1);
    height: 0;
    width: 0;
    margin-left: 1.3rem;
    margin-right: -0.8rem;
  }

  .Button {
    margin-right: -0.6rem;
    border-radius: 1.5rem;
    max-width: 100%;
    transition: all 0.2s ease-out;

    &.hidden {
      max-width: 0;
      opacity: 0;
      overflow: hidden;
      margin: 0 0.3rem 0 0;
      padding: 0;
    }
  }
}
