.MediaLibrary {

  .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .thumbnailDetails {
    &.no-selection {
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 1.3em;
      }
      .info {
        text-align: center;
        font-size: 0.75em;
      }
    }
  }

  .list {
    position: relative;
    height: 100%;
  }

  .MessageCanvas.withOverlay {
    background-color: var(--backdropFill);
    z-index: 9;
  }

  .formlib-UploadProgress {
    --backdropFill: rgba(0,0,0,0.6);
  }

  .DragNDroppable {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.6s ease-in;
    border-radius: 0;
    
    .text {
      display: none;
    }
    
    &.DragNDroppable--drag {
      position: absolute;
      pointer-events: all;
      opacity: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,0.6);
      
      .text {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 400;
        color: white;
        font-size: 1.85em;
      }
    }
  }
}

.MediaSelectItem {
  position: relative;
  opacity: 0;
  transition: opacity 0.2s ease-in;
  width: 100%;

  .thumbnail {
    border-radius: 0;
  }

  &.loaded {
    opacity: 1;
  }

  &:hover {
    background-color: #ddd;
    cursor: pointer;
  }

  & > caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: var(--padVert) var(--padHoriz);
    font-size: 0.75rem;
    color: white;
    background: var(--backdropFill);
    text-align: center;
    line-height: 1.1em;
    font-weight: 400;
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 var(--padHoriz);
    font-size: 0.75rem;
    background: var(--imageTextBackgroundGradientHoriz);

    caption {
      color: white;
      line-height: 1.1em;
      font-weight: 400;
    }
  }
  
}

.MediaSelectItem.isType-Unsplash {
  .footer {
    img {
      border-radius: 50%;
      width: calc(1rem + 4px);
      height: calc(1rem + 4px);
      border: 1px solid white;
      box-shadow: rgba(0,0,0,0.2) 1px 1px 3px;
      margin-right: 0.3rem;
    }

    a {
      color: white;
    }
  }
  
  .downloadOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.6);
  }
}

.MediaSelectDetails {
  display: flex;
  flex-direction: column;

  & > .body {
    padding: 0.35rem;
    width: 100%;
  }

  & > .footer {
    background: none;
    padding: 0.25rem;
    height: auto;
    justify-content: center;
  }

  .formlib-FormRows {
    .form-row {
      margin-bottom: 0.5rem;
    }
    
    .form-input-label {
      font-size: 0.6em;
      font-weight: 400;
    }
  
    .form-input {
      border: none;
      padding: 0;
      line-height: 1.5;
      height: auto;
      font-size: 0.8em;
    }
  }
}