.footer.ImageListFieldToolbar {
  // Drop toolbar that hides when not visible
  // needs proper height
  height: 10rem;
}

.ImageListField.DragNDroppable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 10rem;
  padding: 0 var(--padHoriz);
  border-radius: 0;

  & > .body {
    flex-grow: 1;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    overflow: auto hidden;
    height: 100%;
    transition: opacity 0.2s ease-in;

    & > .slider {
      white-space: nowrap;


      & > .image {
        display: inline-block;
        margin-right: var(--gapHoriz);
        &:last-child {
          margin-right: 0;
        }

        img {
          max-height: calc(10rem - var(--padVert) * 2);
        }
      }
    }
  }

  & > .footer {
    flex-shrink: 0;
    width: 15rem;
    transition: background-color 0.2s ease-in, opacity 0.2s ease-in;
  }

  .drop-message.MessageCanvas {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity 0.2s ease-in;

    .body {
      opacity: 1;
      background-color: transparent;
      color: white;
      font-size: 2em;
    }
  }

  &.DragNDroppable--drag {
    & > .body {
      opacity: 0.3;
      pointer-events: none;
    }
    & > .footer {
      background-color: transparent;
      opacity: 0.3;
      pointer-events: none;
    }
    .drop-message.MessageCanvas {
      opacity: 0.6;
    }
  }

}
.ImageListField-InlineMediaLibrary.ModalSheet {
  height: calc(100vh - 10rem);
  & > .content {
    min-height: 0; /* Allow animation */
    height: calc(100vh - 10rem);
    border-bottom: 2px solid var(--borderMutedColor);

    & > .header {
      padding-bottom: 0;
      width: 100%;
      z-index: 2;
    }

    .thumbnails {
      padding: var(--padVert) var(--padVert);
    }
  }

  .formlib-UploadProgress,
  .DragNDroppable {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
}