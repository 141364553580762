.LiveItem-Invitation.body {
  padding-bottom: 10vh;
  justify-content: flex-start;


  .section.copy {
    flex-direction: column;
    max-width: 30rem;
    margin: 0 auto;
  }

  .Invitation-Message {
    display: block;
    margin: 2rem 0 1rem;
    font-weight: 200;
  }

  #FB-Button,
  #FB-Login-Btn {
    margin: 0 auto 0.5rem;
  }
  
  .LoginWidget-Social {
    padding: 1rem 0 0.5rem;
    margin: 0 auto;
    max-width: 35rem;
    justify-content: center;


    @media (min-width: 576px) {
      _padding-top: 4rem;
    }
  }
}