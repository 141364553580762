.EditForm-User {
  #avatarUrl__Row {
    .ImageField.card {
      max-height: 10rem;
      max-width: 10rem;
    }
  }
  
  .form-header {
    position: fixed!important;
    top: 0;
    left: 0;
    right: 0;
    height: 3rem;
    z-index: 9;
    border-bottom: 1px solid #eee;
    background-color: rgba(255,255,255, 0.95);
  
    &.btn-group > .btn {
      flex-grow: 0;
    }
  }
  
  .AdminAccountForm {
    display: flex;
    flex-direction: row;
    align-items: center;

    h2 {
      flex-grow: 1;
      text-align: left;
    }

    .Button {
      flex-grow: 0;
      height: 2.5em;
    }
  }
}
