.Guide.ViewPage,
.GuideLightbox {
  .Hero {
    position: relative;
    overflow: hidden;
    height: 20rem;
    max-width: 60rem;
    margin: 0 auto;
    background-size: cover;
    background-position: center;
  
    .AvatarContainer {
      position: absolute;
      padding: 1rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: -2.5rem;
    
      .Avatar {
        display: block;
        width: 100%;
        height: 100%;
        max-width: 7rem;
        border-radius: 50%;
        transform-origin: center top;
        box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
        border: 2px solid white;
        background-color: rgba(0,0,0,0.05);
      }  
    }  
    
    .UserNameContainer {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: 2rem;
      font-size: 0.75em;
      transform: translate(-50%, -50%);
      color: white;
    }
    .HeaderContainer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0.75rem 0.5rem 0.5rem;
      background-color: rgba(255,255,255,0.85);
  
      h3 {
        font-family: 'Adobe Caslon Pro', serif;
        font-size: 0.7em;
        letter-spacing: 0.25em;
        margin-bottom: 0;
        color: var(--primaryBg);
        text-align: center;
      }
      h2 {
        margin: 0;
        font-family: 'Adobe Caslon Pro', serif;
        font-weight: normal;
        text-align: center;
      }
    }
  }
  
  .Guide-Content {
    max-width: 40rem!important;
    margin-top: 2rem;
    
    .section-header {
      font-family: 'Adobe Caslon Pro', serif;
      font-size: 1.1em;
      font-weight: normal;
      color: #ccc;
      border-bottom: 1px solid #ddd;
      padding: 0.5rem;
      margin: 0.5rem 0 1rem;
      text-align: center;
    }
  
    .Guide-TOC {
      max-width: 30rem;
      margin: 2rem auto;

      .Guide-Chapter {
        margin: 2rem 0 .5rem;
        h3 {
          font-family: 'Adobe Caslon Pro', serif;
          font-weight: 600;
          font-size: 1em;
          text-align: left;
          margin: 0 .5rem .5em;
        }

        p {
          font-family: 'Adobe Caslon Pro', serif;
          font-size: 0.9em;
          line-height: 1.85em;
          margin: 0 .5rem .5em;
        }
      }

      .Guide-Page {
        position: relative;
        padding: 0.5rem 0.5rem 0.35rem;
        border-radius: 8px;
        margin-bottom: 0.5rem;
        
        h3 {
          font-family: 'Adobe Caslon Pro', serif;
          font-weight: normal;
          font-size: 1em;
          text-align: left;
          margin: 0;
        }

        h4 {
          position: absolute;
          font-family: 'Adobe Caslon Pro', serif;
          font-weight: normal;
          font-size: 0.75em;
          color: var(--secondary);
          text-align: left;
          margin: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &.public {
          cursor: pointer;

          &::after {
            position: absolute;
            right: 0.5rem;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            display: block;
            width: 1px;
            height: 1px; 
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 8px solid var(--primaryBg);
          }
    
          &:hover {
            background-color: #eee;
          }
        }
      }

      .btn {
        display: block;
        margin: 3.5rem auto 3rem;
      }
    }
  }
}