/*
  Sticky
*/
.sticky-vert {
  position: sticky;
  top: 0;

  &.bottom {
    top: auto;
    bottom: 0;
  }
}

.sticky-horiz {
  position: sticky;
  left: 0;

  &.bottom {
    left: auto;
    right: 0;
  }
}