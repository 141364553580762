.Gallery {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  &.form-row {
    flex-direction: row;
    max-width: var(--maxContentWidth);
    margin: 0 auto var(--formRowGap);

    .imgPlaceholder {
      background-color: var(--iconMutedFill);
      height: auto;
      width: 100%;
    }
  }
  
  .image,
  img {
    cursor: pointer;
    object-fit: cover;
    position: relative;

    i {
      content: attr(data-show-more);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: 'Times New Roman', Times, serif;
      font-weight: 400;
      font-size: 4em;
      color: white;
      text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
    }
  }
  .image {
    display: flex;
    overflow: hidden;
    max-height: 50vh;

    img {
      height: 100%;
    }
    i {
      pointer-events: none;
    }
  }

  &.cover {
    align-items: unset;
    img {
      object-fit: cover;
    }
  }

  &.one {
    .image {
      max-height: 90vh;
      width: 100%;
      img {
        object-fit: contain;
        max-height: 90vh;
      }
    }
  }
  
  &.two {
    .image .imgPlaceholder {
      min-height: 40vh;
    }
    .image:nth-child(1),
    .image:nth-child(2) {
      width: calc(50% - 1px);
    }
  }

  &.one_two {
    .image:nth-child(1) {
      width: 100%;

      .imgPlaceholder {
        max-height: 35vh;
      }
    }
    .image:nth-child(2),
    .image:nth-child(3) {
      width: calc(50% - 1px);
      max-height: 25vh;
    }
  }
  
  
  &.one_three {
    .image:nth-child(1) {
      width: 100%;
    }
    .image:nth-child(2),
    .image:nth-child(3),
    .image:nth-child(4) {
      width: calc(33.33% - 2px);
      max-height: 25vh;
    }
  }

  &.one_two_horizontal {
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 55% 45%;
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    width: calc(100% + 8rem);
    max-width: 100vw;

    .Lightbox & {
      width: 100%;
    }

    .image:nth-child(1) {
      grid-row: 1 / -1;
      max-height: 100%;
      
      img {
        max-height: 100%;
      }
    }
    .image:nth-child(2) {}
    .image:nth-child(3) {}
  }

  &.one_four_horizontal {
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 40% 30% 30%;
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    width: calc(100% + 8rem);
    max-width: 100vw;

    .Lightbox & {
      width: 100%;
    }

    .image:nth-child(1) {
      grid-row: 1 / -1;
      max-height: 100%;

      .imgPlaceholder {
        min-height: 40vh;
      }
      
      img {
        max-height: 100%;
      }
    }
    .image:nth-child(2) {}
    .image:nth-child(3) {}
  }
}

.Card {
  max-width: var(--maxContentWidth);

  caption {
    font-size: 0.85em;
    padding: var(--padVert) var(--padHoriz);
    text-align: left;
    margin: 1em auto 2em;
  }

  h2 {
    margin-top: 0.25em;
    align-self: center;
    text-align: center;

    @media (max-width: 576px) {
      text-align: center;
    }
  }

  h3 {
    width: 100%;
    font-size: 1.2em;
    margin-bottom: 1em;
    text-align: center;
  }
}

.EditModal,
.Modal .Lightbox,
.content .Lightbox {
  overflow: hidden;
  height: 100vh;
  max-width: unset;
  border-radius: 0;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;

  & > .header {
    position: sticky;
    padding: var(--padVert) var(--padHoriz);
    top: 0;
    z-index: 9;
    background: var(--menuBg);
  }

  .page {
    // Pages in modals should not have rounded corners
    border-radius: 0;
  }

  .card-form-container {
    /*  use this container to make sure we can scroll forms
        that contain a image drop bar so it disappears */
    min-height: calc(100vh + 7rem);
  }

  form > .footer.fixed {
    width: 100%;
  }

  form > .footer-sticky {
    padding-top: 4px; // To show the shadow
  }

  form > .footer,
  form > .footer-sticky > .footer {
    box-shadow: var(--shadow);
    z-index: 9;

    .formlib-FormRows {
      max-width: 100%;
      margin: 0 auto;

      & > .form-row.form-row-list {
        margin-bottom: 0;
      }
    }

    label#images {
      display: none;
    }
  }

  form > .body {
    .formlib-FormRows {
      max-width: var(--maxFormWidth);
      margin: 0 auto;
    }
  }

  .EditPreview {
    margin-bottom: calc(-1rem + 3px);
  }

  .modal-header {
    position: fixed;
    justify-content: center;
    top: 0;
    left: 0rem;
    height: 3rem;
    z-index: 99;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
  }

  .modal-title {
    align-self: center;
    margin-right: auto;
  }

  .modal-content .modal-body {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    top: 0;
    padding: 3rem 0;
    overflow-x: hidden;

    & > h2, & > h3 {
      text-align: center;
    }
  }

  .media {
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-footer {
    position: fixed;
    bottom: 3rem;
    left: 0;
    right: 0;
    transform: translateY(100%);
    height: 3rem;
    max-height: calc(100vh - 3rem);
    overflow-y: scroll;
    left: 0;
    border-radius: 0;
    transition: all 0.3s ease-in;
    background: rgba(255, 255, 255, 0.8);

    &.expand {
      height: 100%;
      transform: translateY(0);
    }

    p {
      margin-right: auto;
    }
  }
  
  &.MediaLightbox .image {
    display: block;
    margin-bottom: 1rem;
  }
  .image:last-child {
    margin-bottom: 0;
  }

  .image-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .lightbox-image {
    max-width: 100%;
    max-height: calc(100vh - 6rem);
    width: auto;
  }

  // Styling of connectedTitle switch
  .formlib-FormRows {
    .form-row {
      max-width: var(--maxFormWidth);
    }
    #connectedTitle__Row {
      // To make it more snug when empty
      margin-top: -1.5rem;
  
      .form-input-help {
        line-height: 1.2em;
        font-size: 0.8em;
      }
    }
  }
}

.MediaCardModal,
.PlaceCardModal,
.CityGuideModal,
.RecipeModal,
.ListCardModal {
  & > form > .body {
    // This is the space for the footer to allow to scroll to reveal the entire form
    padding-bottom: 10rem;
  }
}

.CardEditButton {
  position: absolute;
  top: 0.5rem;
  right: 0;
}


.MediaCard,
.PlaceCard,
.CityGuideCard,
.RecipeCard,
.ListCard {
  h2.hidden {
    display: none;
  }

  // This is a hack bc I couldn't mutate the card
  // from the edit form
  h2.hideableInEditor {
    max-height: 5rem; // max 5 rows...
    transition: all 0.3s ease-out;
    overflow: hidden; 

    .hideCardTitle & {
      max-height: 0;
      margin-top: 0;
      margin-bottom: 0;
      transition: all 0.3s ease-in;
    }
  }
}

.EditPreview {
  & > * {
    margin: var(--padVert) auto;
  }
}