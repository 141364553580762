.ContentPage {
  display: flex;
  position: relative;
  height: 100vh;
  overflow-y: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 576px) {
    padding-top: 3.5rem;
  }

  & > form > .body
  & > .body {
    max-width: var(--maxContentWidth);
    margin: 1rem auto;
    padding: 0 var(--padHoriz);
  }

  .section {
    display: flex;
    flex-direction: column;
    max-width: var(--maxContentWidth);
    margin: 0 auto;

    & > * {
      flex-grow: 1;
      flex-basis: 0;
    }
    
    @media (min-width: 576px) {
      flex-direction: row;
      gap: 3rem;
    }
  }
  

  #Page-Logo {
    position: absolute;
    z-index: 9;
    left: 50%;
    transform: translateX(calc(-50% + 8px));
    width: 8rem;
    top: 1rem;
    margin: 0;
    opacity: 1;
    transition: 0.2s ease-in;
  }

  h2 {
    margin: 2.5rem 0 2rem;
    text-align: center;
    width: 100%;
  }

  h3 {
    margin: 2.5rem 0 2rem;
    font-size: 1.85em;
    text-align: center;
    width: 100%;
  }

  .container-fluid {
    max-width: 50rem;

    .col {
      max-width: 35rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .Link-Button {
    margin: 1rem auto;
  }
}