.Icon-Clock,
.Icon-People {
  vertical-align: text-bottom;
}


.CityGuideModal {
  #gps__Field .form-input-object-field {
    display: flex;
    justify-content: space-between;

    .form-row input {
      border-top: none;
      border-right: none;
      border-radius: 0;
      max-width: 4rem;
    }  
  }
}

.CityGuideCard.isTemplate {
  & > h2 {
    display: block;
    width: 15em;
    height: 1.5em;
    background-color: var(--inputRowLineColor);
  }

  & > .Gallery {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 56%;
    background-color: var(--inputRowLineColor);

    &:after {
      content: 'Destination';
      font-size: 2em;
      font-weight: bold;
      opacity: 0.15;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  & > .body {
    pointer-events: none;

    caption {
      width: 20em;
      height: 2em;
      margin: 0.75rem 0;
      background-image: var(--templateListPlaceholder);
    }

    .CityGuideCard-Info p {
      display: block;
      height: 6em;
      background-image: var(--templateListPlaceholder);
    }
    
    .CityGuideCard-Info ul {
      display: block;
      height: 10em;
      background-image: var(--templateListPlaceholder);
    }

    Button {
      background-color: var(--inputRowLineColor);
    }
  }
}


.CityGuideCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 40rem;
  margin: 0 auto;

  & > h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 1em;
  }

  img {
    width: 100%;
  }

  .body {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > .section {
      flex-grow: 1;
      flex-direction: row;
    }
    & > .column {
      flex-grow: 1;
    }
  }

  .CityGuideCard-Media,
  .CityGuideCard-Info {
    
    .Lightbox & {
      padding: 0;
    }

    ul {
      padding-left: 0;
      font-size: 1em;
      font-family: adobe caslon pro;
      margin: 0;
    }

    li {
      list-style: none;
      font-weight: 100;
      margin-bottom: 0.75em;
      line-height: 1.3em;
    }

    h3 {
      font-size: 1.2rem;
      margin-top: 1em;
      color: var(--textAccentedColor);
    }

    p {
      margin: 0;
    }

    .Button {
      margin-top: auto;
      margin-bottom: 0;
    }
  }

  ul.CityGuideCard-TopSpots {
    margin-bottom: var(--colGap);

    li {
      list-style-type: decimal;
      list-style-position: inside;
    }
  }
}

.CityGuideLightbox {
  .CityGuideCard-Steps {
    max-height: none;

    h3 {
      font-family: Adobe Caslon Pro;
      margin-bottom: 1rem;
      color: var(--cyan);
    }
  }
}

.Related-Info {
  padding: 1rem;

  h3 {
    font-size: 1em;
  }
}