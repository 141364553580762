.PageNavbar {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--white);
  margin-left: -15px;
  text-align: center;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);

  .Actions {
    max-width: 80rem;
    width: 100%;
    height: 3.25rem;
    margin: 0 auto;
  }

  .btn.CloseButton {
    flex-grow: 0;
    flex-shrink: 0;
    width: 6rem;
    margin-right: auto;
  }
  .spacer {
    width: 6rem;
    margin-left: auto;
  }
}

.ViewPage > .content {
  padding-top: 3.25rem;
}