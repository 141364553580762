.EditPage.BlogPost {

  & > .body {
    padding-top: 1.5rem;
  }

  .formlib-FormRows {
    #connectedTitle__Row {
      // To make it more snug when empty
      margin-top: -1.5rem;
      margin-bottom: 1.5rem;
  
      .form-input-help {
        line-height: 1.2em;
        font-size: 0.8em;
      }
    }
  }


  .form-row.IMobiledocField {
    max-width: var(--maxContentWidth);
  }

  .IMobiledocField {
    .MobiledocToolbar {
      position: fixed;
      top: 0;
      left: 0;
      width: calc(100% - var(--scrollbarWidth));
      max-width: initial;
      height: var(--headerHeight);
      z-index: 99;
    }
  }

  label#title,
  label#body {
    display: none;
  }

  #title__Field {
    font-size: 1.5em;
  }
}