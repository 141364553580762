.GuideRow-Edit {
  display: flex;
  margin-left: -15px;

  .btn {
    margin-left: 1rem;
  }

  .btn-outline-danger {
    border-color: white;
  }
}

.GuideRow.row {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border: 1px solid white;
  border-radius: 4px;
  cursor: pointer;
  margin-left: -30px;
  
  &:hover {
    background-color: var(--primaryBg);

    .status,
    a {
      color: white;
    }
  }
  
  .status {
    margin: 0;
  }

  .list-item-body {
    a {
      cursor: text;

      &:hover {
        text-decoration: none;
      }
    }

    h3 {
      text-align: left;
      font-size: 0.65em;
      margin-bottom: -1em;
      line-height: 1;
    }
  }
}
#content__Field {
  .form-row {
    margin-bottom: 0.25rem;
  }

  .formlib-ListFieldActionBar {
    margin-top: 0.75rem;
  }
}