.ImageField {
  height: 15rem;
}

.ImageListField,
.ImageField {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .image {
    position: relative;
    margin-right: auto;
    overflow: hidden;

    img {
      width: auto;
      max-width: 100%;
      max-height: 15rem;
    }
  }

  .formlib-FileUploadWidget {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  
    .DragNDroppable {
      padding-bottom: 0;
    }
  
    .Button.link {
      font-weight: 300;
    }
  }

  .image .IconButton {
    transition: 0.15s ease-out;
    opacity: 0.4;
    transform: scale(0.65);
    transform-origin: 75% 25%;
  }
  .image:hover .IconButton {
    opacity: 0.75;
  }

  .remove {
    position: absolute;
    top: -1.5rem;
    right: -1.5rem;
    width: 6rem;
    height: 6rem;
    color: var(--iconDarkFill);
    border-radius: 0;
    border-bottom-left-radius: 50%;
    background: rgba(0,0,0,0);
    transition: 0.15s ease-out;
    
    &:hover {
      color: var(--iconWarningFill);
      background: rgba(0,0,0,0.3);
      opacity: 1;
    }

    svg {
      width: 32px;
      height: 32px;
    }
  }
}