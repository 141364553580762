.Modal.MediaLibrary {
  width: 100vw;
  height: 100vh;

  & > .content {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    width: calc(100% - 2rem);
    height: calc(100vh - 2rem);
    overflow: hidden;
    min-height: 0;
    
    & > .header {
      padding-bottom: 0;
    }

    & > .body {
      flex-grow: 1;
      padding: 0;
      overflow: hidden;
    }
  }

  .Toolbar {
    max-width: 100%;
  }

}

.MediaLibrary {
  position: relative;
  height: 100%;
  width: 100%;

  .filter {
    position: absolute;
    z-index: 1;
    top: 0.3rem;
    right: 0.3rem;
    width: calc(20rem - 0.6rem);

    #search__Field {
      height: calc(1.7em + .75rem + 2px);
      padding: .375rem .75rem .375rem 1em;
      font-size: 1rem;
      font-weight: 300;
      line-height: 1.7;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: calc((1.7em + 0.75rem) / 2);
    }
  }

  & > .body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;

    .thumbnails {
      flex-grow: 1;
      height: 100%;
      overflow-y: scroll;
      position: relative;
    }

    .thumbnailDetails {
      width: 20rem;
      margin-top: 4rem;
      padding: var(--padVert) var(--padHoriz);
    }

  }


}


// Is this used?
.ModalMenu {
  margin: 0 auto;
  width: 100%;
  justify-content: center;

  .isLive:not(.PullUp) & .NavStateButton {
    color: white !important;
  }

  .PullUp & {
    flex-grow: 1;
    flex-basis: auto;
    width: auto;
  }
}