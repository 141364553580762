.Toolbar .btn {
  border-radius: 0!important;
}

.Toolbar .dropdown {
  margin: 0 0.5rem;
}

.__mobiledoc-editor {
  min-height: 20vh;
  max-width: var(--maxContentWidth);
  border: 1px solid rgba(0,0,0,0.05);
  border-top: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: var(--borderRadiusSmall);
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  // This is just so we keep the editor stacked to the left
  outline: none;
}
.__has-no-content {
  
}

@media (max-width: 576px) {
  .Toolbar.btn-group {
    overflow-x: scroll;
    max-width: 100%;

  }
  
  .Toolbar.btn-group > .btn {
    font-size: 0.75rem;
  }
}

.__mobiledoc-card {
  display: block;
  margin-top: -1.8rem;
  margin-bottom: -1.5em;
  border: 1px dashed #e5e5e5;
}

/* Typography */
.__mobiledoc-editor,
.Mobiledoc {
  h1 {
    margin: 1em auto 0.5em;
  }
  h2 {
    font-size: 1.5rem;
    margin: 1em auto 0.5em;
  }
  h3 {
    font-size: 1.2rem;
    margin: 1em auto 0.5em;
  }
  
  p {
    font-size: 1.1em;
    margin: 0 auto 1em;
    line-height: 1.85em;
    text-align: left;
  }

  blockquote {
    position: relative;
    font-size: 1.5em;
    max-width: 25rem;
    margin: 2em auto;
    text-align: left;
    padding-left: 0.5em;
    border-left: 3px solid var(--textSubtleColor);
    color: var(--textIconColor);

    &::after {
      content: '"';
      position: absolute;
      top: -0.1em;
      right: calc(100% + 0.175em);
      font-family: cursive;
      font-size: 5em;
      line-height: 1em;
      color: var(--primaryBg);
    }
  }
}
