.ListPageSection-MediaCard {
  min-height: calc(100vh - 3rem);
  
  .masonry {
    text-align: center;
    flex-shrink: 0;
  }
  .masonry-col {
    max-width: 320px;
  }
}