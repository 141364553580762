.IBlogRelationField {

  .form-input-field {
    display: flex;
    flex-direction: row;
    gap: var(--gapHoriz);

    .AutoCompleteDropdown {
      flex-grow: 1;
    }
  
    .Button {
      flex-shrink: 0;
      color: var(--textErrorColor);
    }
  }
}

// TODO: This should probably be general styling
.AutoCompleteDropdown {
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
  margin-left: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  flex-direction: column-reverse;
  
  &.showTypeAhead {
    display: flex;
  }


  .formlib-AutoComplete-TypeAhead {
    pointer-events: none; /* This tag obscrues the intranet tag preventing it from being removed */
    padding: 0.22em 0.5em 0.1rem; /* For some reason we need more padding than input. Border? */
    position: absolute;
    top: 0;
    left: 0;
  }

  .formlib-AutoComplete-TypeAheadPrefix {
    visibility: hidden;
  }

  .formlib-AutoComplete-TypeAheadSuffix {
    color: var(--textSubtleColor);
  }
}

.formlib-AutocompleteItem {
  padding: var(--padVert) var(--padHoriz);
  border-bottom: 1px solid var(--inputBorderColor);
  cursor: pointer;
  text-align: left; // new

  &:hover {
    background-color: var(--primaryHover) !important;
    color: var(--primaryFg);

    h3,
    h4 {
      color: var(--primaryFg);
    }
  }

  &:last-child {
    border-bottom: 0;
  }
}

.formlib-AutocompleteItem--Highlight {
  background-color: var(--primaryBg);
  color: var(--primaryFg);
}

.formlib-AutosuggestItemThumbnail {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin: var(--padVert) var(--padHoriz) var(--padVert) 0;
}

.formlib-AutocompleteItem--Animation {
  &-leave {
    // Leave animation start state
    // width: auto;
    opacity: 1;
  }

  &-leave-active {
    // Leave animation transitions
    overflow: hidden;
    transition: height 0.3s ease-out, opacity 0.2s ease-in;
  }

  &-leave-end {
    // Leave animation end state
    // width: 0;
    height: 0;
    opacity: 0;
  }

  &-enter {
    // Enter animation start state
    height: 0;
    opacity: 0;
  }

  &-enter-active {
    // Enter animation transitions
    transition: height 0.2s ease-out, opacity 0.5s ease-in;
  }

  &-enter-end {
    // Enter animation end state
    opacity: 1;
  }
}