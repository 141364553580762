.AdminMenu,
.MainMenu {
  --linkHover: #dfe7fa;
  
  .NavStateButton {
    color: black;
    font-weight: 300;

    &:hover {
      background-color: var(--linkHover);
    }    
  }
}

.AdminMenu {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  z-index: 9;
  height: var(--headerHeight);
  width: auto;

  .AvatarWrapper {
    flex-shrink: 0;
    width: auto;
    height: auto;
  }
  .Avatar {
    border-radius: 50%;
    height: calc(var(--headerHeight) - var(--padVert));
    margin: calc(var(--padVert) / 2);
    width: auto;
  }
}

.MainMenu {
  .NavSlot {
    width: 0;
    z-index: 9;
    font-size: 1.25em;
  }
}

/**
 * Animations
 */

.NavBar {
  &-enter {
    opacity: 0;
    
  }
  &-enter-active {
    transition: opacity 0.5s ease-in;
  }
  &-enter-end {
    opacity: 1;
  }
  /* ----------------------------- */
  &-leave {
    opacity: 1;
  }
  &-leave-active {
    transition: opacity 0.3s ease-in;
  }
  &-leave-end {
    opacity: 0;
  }
}
