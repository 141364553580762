/* New stuff trying to fix sizing when empty */
.SectionPage {


  .List-Container {
    z-index: 0;
    padding-bottom: var(--footerHeight);
  }

  .masonry-item-container.media {
    padding: 0;
    margin: 0 0 var(--gridGap);
  }
}

.FormFilter {
  margin: 0 auto;
}

.ListPageSection {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: unset!important;
  width: 100%;
  z-index: 2;

  .cover & {
    max-width: 100%;
  }

  & > .toolbar {
    position: sticky;
    width: 100%;
    bottom: 0;
  }

  .Hero {
    background-size: cover;
    background-position: center;
    transition: opacity 0.5s ease-in;
    opacity: 0.15;

    .isLive & {
      opacity: 1;
    }

  }

  .UserNameContainer {
    padding-right: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 5.7rem;
    color: white;
    text-shadow: 1px 1px 3px rgba(0,0,0,0.75);
    min-width: 20rem;
    text-align: center;
    text-overflow: ellipsis;
    opacity: 1;
    transition: opacity 0.3s ease-in;

    .PullUp & {
      opacity: 0;
    }

    .isLive & {
      color: white
    }
  }
}

.LivePage.cover {
  .CollapseMe {
    overflow: visible;
    z-index: 9;
  }
  .ListPageSection-BlogPost,
  .ListPageSection-MediaCard {
    margin-top: calc(-66.6vh + 7rem);
  }
  &.Profile .ListPageSection-BlogPost {
    margin-top: calc(-66.6vh + 2.5rem);
  }

  .Hero {
    height: 100vh;
    width: 100vw;
    background-position: center;
    
    @media (min-width: var(--maxHeaderWidth)) {
      margin-left: calc(-50vw + 35rem);
    }
  }

  .AvatarContainer {
    padding: 1rem;
    top: auto;
    left: 0;
    bottom: 3.5rem;
    transform: translate(0,50%);

    @media (min-width: var(--maxHeaderWidth)) {
      left: calc(-50vw + 35rem);
    }

    .AvatarWrapper {
      width: 5rem;
      height: 5rem;
    }

  }

  .UserNameContainer {
    top: auto;
    left: 7rem;
    bottom: calc(1rem + 2.5rem);
    transform: translate(0,50%);
    text-align: left;
    margin: 0;
    font-size: 0.7em;

    .PullUp & {
      opacity: 0;
    }

    @media (min-width: var(--maxHeaderWidth)) {
      left: calc(-50vw + 35rem + 7rem);
    }
  }

  .MainMenuContainer {
    display: none;
  }

  .PullUpMenu-Background {
    @media (min-width: var(--maxHeaderWidth)) {
      left: calc(-50vw + 35rem);
      width: 100vw;
    }
  }
}

.ListPage.container-fluid {
  margin-left: 0;
}

.thumbnail {
  border-radius: 4px;
}

.toolbar {
  position: fixed;
  z-index: 9;
  bottom: 0;
  left: 0;
  background: #f7f7f7;
  right: 0;
  text-align: center;
  padding: 0.5rem;

  .action {
    margin: 0 1.5rem;
  }
}

.ListPageSection-User,
.ListPageSection-Invitation,
.ListPageSection-Cards,
.ListPageSection-BlogPost {
  display: block;
  position: relative;
  transition: margin-top 0.2s;
  flex-grow: 1;
  flex-shrink: 1;
  min-height: calc(100vh - 3rem); // Allow header image to scroll away (should use - var(--infernoUXHeaderHeight)
  max-width: var(--maxHeaderWidth);
  padding-bottom: 3.5rem;

  .isLive & {
    margin-top: 1.5rem;
  }

  .live-list-item {
    flex-direction: column;
    text-align: center;

    &:hover {
      background-color: transparent;
    }

    h2 {
      font-size: 1.75em;
    }

    h3 {
      font-size: 1em;
    }
  }
}

.ListPageSection-BlogPost {
  .MessageCanvas {
    position: sticky;
    left: 0;
    height: 15rem;
  }
}

.Card-RelatedGrid,
.ListPageSection-MediaCard .List-Container {
  --gridGap: 0.5rem;
  grid-template-columns: repeat( auto-fit, minmax(10rem, 1fr) );
  width: 100%;
  padding: var(--padVert) var(--padHoriz);
  margin-right: auto;
  margin-left: auto;

  transition: margin-top 0.3s;

  .list-item {
    overflow: hidden;
    border-radius: 2px;
    padding: 0;
    margin: 0 0 0.5rem;

    .thumbnail {
      object-fit: cover;
      height: 100%;
      border-radius: 0;
      border-radius: 2px;
    }
  }
}

.CollapseMe {
  transition: margin 0.6s, opacity 0.2s;
  opacity: 1;

  .MainMenu {
    opacity: 1;
    transition: opacity 0.2s;
  }

  .PullUp & {
    .MainMenu {
      opacity: 0;
    }
  }
}

.AvatarContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transition: margin 0.6s;
  transform: translate(-50%, -50%);

  .formlib-FileUploadWidget.Avatar-Field {
    margin: 0 auto;
    overflow: hidden;
  
    .Onboarding-Avatar {
      margin: 0;
    }

    .buttons {
      transform: translate(-50%, -50%);
    }

    button.remove {
      display: none;
    }
  }
}

.AvatarWrapper {
  width: 15rem;
  height: 15rem;
  margin: 0 auto;
  
  &.hidden {
    visibility: hidden;
  }
}

.Avatar {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform-origin: center top;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
  border: 2px solid white;
  background-color: rgba(0,0,0,0.05);
}

.MainMenu-sticky {
  display: none!important;
  position: fixed;
  
  .PullUp & {
    display: block;
    left: 50%;
    top: 0;
    margin-left: -50%;
    border-bottom: none;
  }
}

.FilterContainer {
  padding: 0 var(--padHoriz);
  display: flex;
  justify-content: center;
  margin-bottom: var(--padVert);

  form {
    width: 100%;
  }

  .formlib-FormRows {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    max-width: var(--maxHeaderWidth);

    .form-row {
      margin: 0;
    }
  }

  #publishWorkflow__Row {
    margin-right: auto;
  }

  #search__Row {
    max-width: 20rem;

    #search {
      display: none;
    }
  
    #search__Field {
      font-size: 1em;
      padding: 0.2rem var(--padHoriz);
      border: 1px solid var(--borderMutedColor);
      border-radius: 2em;
      background-color: var(--pageBg);
    }
  }
  
  .sticky-row {
    background: none;
    bottom: auto;
    z-index: 9;
    border-top: none;

    .form-nav,
    form {
      background: rgba(255,255,255,0.8);
    }
  }

  .form-nav,
  form {
    padding: 8px 0 0;
    background: white;
  }

  .form-nav {
    justify-content: center;
  }

  .form-row {
    margin-bottom: 8px;
  }
}

.PullUpMenu {
  display: flex;
  position: fixed;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  
  z-index: 10;
  top: 0;
  left: 0;
  width: calc(100% - var(--scrollbarWidth) - 2px);
  padding: 0 calc(50vw - var(--maxHeaderWidth) / 2);
  
  transition: transform 0.3s, opacity 0.15s;
  transform: translateY(-100%);
  
  opacity: 0;
  background: var(--menuBg);
  box-shadow: var(--shadow);


  .PullUpMenu-Background {
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% + 2px);
    height: 100%;
    background: white;
    z-index: -1;
  }

  .isLive & {
    background: rgba(255,255,255,0.85);
  }

  &.active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  .MainMenu {
    position: relative;
    margin: 0;
  }

  .AvatarWrapper {
    width: 3rem;
    height: 3rem;
    margin: 0 0 0 0.2rem;
    align-self: center;
    flex-shrink: 0;
  }

  .Avatar {
    width: 100%;
    height: 100%;

    &-animating {
      transition: transform 0.4s, opacity 0.6s;
    }
  }
}

.CollapseMe {
  overflow: hidden;

  .Hero {
    height: 23.75rem;
    transition: margin 0.6s, opacity 0.4s ease-in;

    .isLive & {
      opacity: 1;
    }
  }
}

.EditLiveMenu {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;

  .MainMenu & {
    position: absolute;
  }

  .PullUp & {
    margin-left: 3.5rem;
  }
}

.MainMenu {
  .isLive:not(.PullUp) & .NavStateButton {
    color: var(--menuBg);
  }
  
  .isLive .PullUp & .NavStateButton {
    color: black;
  }

  .PullUp & {
    flex-grow: 1;
    flex-basis: auto;
    width: auto;
  }
}

.action-wrapper {
  .placeholder {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -.75rem;
    left: 50%;
    transform: translate(-50%, -100%);
  
    background: hsl(0, 0%, 93%);
    border-radius: 8px;
    text-align: center;
    width: calc(100vw - 4rem);
    padding: 2rem 2rem 1rem;
    max-width: 30rem;
    align-content: center;
    justify-content: center;
    min-height: 10rem;
    color: var(--gray);
  
    &:after {
      content: " ";
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 100%);
        width: 0; 
        height: 0; 
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid hsl(0, 0%, 93%);
    }
  }
}