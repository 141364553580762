.ConnectedTitleFieldWidget {
  display: flex;
  flex-direction: row;
  gap: var(--gapHoriz);
  align-items: center;

  .form-input-help {
    flex-grow: 1;
  }

  .SwitchButton {
    --colorSuccess: #74BA6F;
    --colorSuccessHover: #60a75b;
    --colorSuccessLight: #99d195;
    --borderRadius: 0.5rem;
    --padHoriz: 0.5rem;
    --padVert: 0.5rem;

    --primaryBg: #BBB;
    --primaryBgLight: #D1D1D1;

    margin-right: auto;
    width: 6.9rem;
    font-size: 0.8em;
    padding-left: var(--padHoriz);
    padding-right: var(--padHoriz);
    flex-grow: 0;

    .toggle {
      width: 16px;
    }

    .textSelected {
      text-align: left;
    }

    &.isSelected {
      background-color: var(--colorSuccess);
      svg {
        color: var(--colorSuccessLight);
      }.toggle {
        background-color: var(--colorSuccessLight);
      }

      &:hover {
        background-color: var(--colorSuccessHover);
      }
    }
  }
}

.IConnectedTitleField {
  label.form-input-label.checkbox {
    padding: 0;
  }
}

.EditModal.RecipeModal {
  .ConnectedTitleFieldWidget .SwitchButton {
    --primaryBg: #5591FF;
    --primaryBgLight: #65A7F4;
    width: 6rem;
  }
}