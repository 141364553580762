.App {
  min-width: 300px;
  max-width: 100%;
  overflow-x: hidden;
  background: white; // #222;

  &.public {
  }
}

a,
a:hover,
a:link,
a:active {
  color: var(--linkFg);
  text-decoration: none;
  background-color: initial;
}

.Modal {
  a,
  a:hover,
  a:link,
  a:active {
    color: var(--linkFg);
    text-decoration: none;
    background-color: initial;
  }

  .content .body {
    /* Text at end of image lists */
    & > caption {
      max-width: var(--maxContentWidth);
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}


.placeholder {
  width: 100%;
  position: relative;
}

.placeholder-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #aaa;
}

.placeholder-text h2 {
  text-align: center;
  width: 100%;
}

.placeholder-cta {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

.placeholder-cta,
.placeholder-cta a {
  font-size: 2rem;
  color: #aaa;
}

.placeholder-cta a:hover,
.placeholder-cta a:hover .placeholder-cta-arrow {
  font-size: 2rem;
  color: #e83e8c;
  stroke: #e83e8c;
  fill: #e83e8c;
}

.placeholder-cta-arrow {
  stroke: #aaa;
  fill: #aaa;
}

.content {
  background-color: #fff;
}


.login {
  overflow-x: hidden;
}

.menu-profile {
  text-align: center!important;
}

.menu-profile h4 {
  font-weight: lighter;
}

.menu-profile .avatar {
  border-radius: 50%;
  padding: 1rem;
}

.menu-profile .logout {
  margin-bottom: 1rem;
  display: block;
}


.App > .content {
  /* Typography */
  h1 {
    width: 100%;
    margin: 1rem auto 2rem;
    font-weight: 200;
    color: var(--dark);
  }
}

.login .content {
  transform: translateX(100%);
  opacity: 0.5;
  pointer-events: none;
}

.shortcut {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  background: #fff;
  z-index: 1;
  border-left: 1px solid #eee;
  color: #ccc;
}

.shortcut:hover {
  text-decoration: none;
  background: #007bff;
  color: #fff;
}

.login-page {
  position: fixed;
  top: 3.5rem;
  left: 0;
  right: 0;
  bottom: 0;
}

.FadeIn-enter {
  opacity: 0;
  
}
.FadeIn-enter-active {
  transition: opacity 0.5s ease-in;
}
.FadeIn-enter-end {
  opacity: 1;
}
/* ----------------------------- */
.FadeIn-leave {
  opacity: 1;
}
.FadeIn-leave-active {
  transition: opacity 0.5s ease-in;
}
.FadeIn-leave-end {
  opacity: 0;
}

.Appear-enter {
  opacity: 0;
  height: 0;
}
.Appear-enter-active {
  transition: opacity 0.5s ease-out, height 0.3s ease-out;
}
.Appear-enter-end {
  opacity: 1;
}
/* ----------------------------- */
.Appear-leave {
  opacity: 1;
}
.Appear-leave-active {
  transition: opacity 0.4s ease-out, height 0.3s ease-out;
}
.Appear-leave-end {
  opacity: 0;
  height: 0;
}

/**
 * Success message
 */
.SuccessMessage {
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  opacity: 0.95;
}

.SucessAnimation {
  &-enter {
    opacity: 0;
  }
  &-enter-active {
    transition: all 0.15s ease-in;
  }
  &-enter-end {
    opacity: 1;
  }
  /* ----------------------------- */
  &-leave {
    opacity: 1;
  }

  &-leave-active {
    transition: all 0.65s ease-out;
  }
  &-leave-end {
    opacity: 0;
  }
}

/**
 * Page nav
 */

.PageNav {
  &-enter {
    opacity: 0;
    transform: translateX(30%);
  }
  &-enter-active {
    transition: opacity 0.7s ease-out, transform 0.5s ease-out;
  }
  &-enter-end {
    opacity: 1;
    transform: translateX(0);
  }
  /* ----------------------------- */
  &-leave {
    opacity: 1;
  }
  &-leave-active {
    position: absolute;
    width: 100%;
    transition: opacity .7s ease-out, transform 0.5s ease-out;
  }
  &-leave-end {
    opacity: 0;
    transform: scale(1.3);
  }
}
