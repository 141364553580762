.ListItem {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  transition: all 0.1s ease-in;
  
  &:hover {
    background-color: var(--listItemHoverColor);
  }
  
  & > .body {
    flex-grow: 1;
    
    a {
      color: var(--textMutedColor);

      &:hover {
        color: var(--textAccentedColor);
        background: none;
      }
    }
  
    h3 {
      font-size: 0.75em;
      color: #aaa;
      text-align: left;
  
      i {
        font-style: normal;
        font-weight: 700;
      }
    }
  }
  
  .workflow {
    .Icon-Clock {
      vertical-align: -3px;
      width: 1em;
      height: 1em;
      margin-right: 0.25rem;
    }
  
    .status {
      text-align: right;
      font-size: 1.5em;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--textSubtleColor);
      margin: 0;
    }
    
    .actions {
      font-size: 0.75em;
      
      button,
      button.selected,
      button:hover {
        line-height: 1em;
        border-radius: 0.25rem;
        padding: 0.5em 0.5em 0.25em;
      }
      
      button.selected,
      button.selected:hover {
        border-bottom: 3px solid var(--primaryBg);
      }
    }
  }  
}



