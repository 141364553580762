.Lightbox.RecipeCard {
  // .ButtonBar {
  //   max-width: var(--maxBodyWidth);  
  //   margin: 0 auto;
  // }
  
  .body {
    width: var(--maxBodyWidth);
    max-width: 100%;
    margin: 0 auto; 
  }

  .section {
    gap: 1rem;
  }

  
  .ingredients {
    max-width: 20rem;

    @media (max-width: 576px)  {
      max-width: 100%;
    }
  
    .Gallery {
      margin-bottom: 1rem;
      max-width: 100%;
    }
    
    .info {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: var(--gapHoriz);
    }

    ul {
      display: flex;
      flex-direction: column;
      margin: .5rem auto 1rem;
      min-width: 50%;
    }

    li {
      font-size: 1.2em;
    }
  }

  .steps {
    h2 {
      margin-top: 1em;
    }

    li p {
      max-width: 25rem;
    }
  }

  .cookingTimer {
    margin: 0.5rem auto 1rem -1rem;

    button {
      font-size: 0.75em;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      padding: 0.5rem;
    }

    .Icon-Clock {
      width: 1rem;
      height: 1rem;
      display: inline-block;
      margin-right: 0.5rem;
    }
  }

}
