.formlib-ListFieldActionBar {
  .btn {
    margin-right: 1rem;
  }
}

.IGuide {
  padding-top: 3.5rem;
  
  .FilterContainer {
    position: fixed;
    top: calc(2.5rem + 1px);
    max-width: 50rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background: rgba(255,255,255,0.8);
    z-index: 99;
  }

  .btn-outline-primary {
    border-color: white;
  }

  #content__Field #title__Row {
    margin-bottom: 0;
  }

  #content__Field #title__Field {
    text-align: left;
    font-size: 1.2rem;
    font-weight: 400;
  }

  #content__Field #shortDescription__Field {
    font-size: 1em;
    border: none;
    background-attachment: local;
    background-image:
        linear-gradient(to right, white 0px, transparent 0px),
        linear-gradient(to left, white 0px, transparent 20px),
        repeating-linear-gradient(white, white 31px, #ddd 31px, #ddd 32px, white 32px);
    line-height: 28px;
    padding: 8px 20px 8px 0px;
  }

  .IEditItem > div > .formlib-FormRows > .form-input-list-field > .form-input-field > #content__Field > .formlib-DragItem {
    &:before {
      content: " ";
      position: absolute;
      right: calc(-3rem + 0.5rem);
      width: 2px;
      height: 95%;
      background-color: var(--primary-20);
    }

    & > .formlib-DragHandle {
      right: -3rem;
      background-color: white;
    }

    & .formlib-DragItem .formlib-DragHandle {
      // Nested drag handles
      left: -1.55rem;
      top: 46%;
      background-color: white;
    }
  }

  #content__Field .formlib-FormRows {

    .form-input-list-field {
      padding-left: 1rem;
      border-left: 2px solid var(--primaryBg);

      .formlib-ListFieldActionBar {
        margin-top: 1rem;
      }

      .formlib-ListFieldRowDeleteBtnWrapper {
        position: absolute;
        left: 100%;
      }
    }
  }
}