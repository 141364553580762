.TimedPublishing {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .Dropdown {
    --dropdownHoverBg: transparent;
    --dropdownHoverFg: inherit;

  }

  .status {
    color: var(--secondaryBg);
    font-size: 1em;
    text-align: right;
    font-weight: 600;
    text-transform: uppercase;
  }

  .Dropdown .header {
    font-size: 1.2em;
    margin-bottom: 1rem;
  }

  .Icon-Clock {
    vertical-align: -0.35em;
    color: var(--iconFill);
    fill: currentColor;
    stroke: currentColor;

    &.active {
      color: var(--iconAccentedFill);
    }
  }
}
