.modal,
.EditModal,
.EditPage {
  .Toolbar .Dropdown-Wrapper > .Button.link {
    color: var(--textIconColor);
  }
}  

.ActionBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 ;
  background-color: rgba(255,255,255,0.95);
}


.footer.ActionsToolbar {
  overflow: visible;
  width: 100%;

  .Toolbar .status-btn {
    padding: 0;
    height: 2rem;
    width: 2rem;
  }
}

.IEditItem {
  background-color: var(--pageBg);

  & > .ButtonBar {
    max-width: var(--maxFormWidth);
    margin: 1rem auto;
    padding: 1rem 0;
  }
}
