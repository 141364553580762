.Hero-Field.formlib-FileUploadWidget {

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1rem;
    left: 1rem;
    gap: var(--gapVert);
  }

  button.change {
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }

  button.remove {
    display: none;
    color: white;
    &:hover {
      background-color: red;

    }
  }

  .upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0rem;
    left: 0rem;
    width: 15rem;
    height: 10rem;

    color: var(--iconFill);
    font-weight: 400;
    
    .IconButton {
      color: var(--iconFill);
      &:hover {
        color: var(--iconHover);
      }
      &:active {
        color: var(--iconActive);
      }
    }
  }
}
