.PullUpMenu.active .MainMenu {
  margin-left: -3rem;
  border-bottom: 1px solid #eee;
}

.MainMenuContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  border-bottom: 1px solid #ddd;
  max-width: var(--maxHeaderWidth);
  margin: 0 auto;
}

.AdminMenu,
.ModalMenu,
.EditLiveMenu,
.MainMenu {
  --linkHover: #dfe7fa;
  
  .NavStateButton {
    color: black;
    font-weight: 300;

    &:hover {
      background-color: var(--linkHover);
    }    
  }
}

.EditMenu {
  display: none;
  @media (max-width: 576px) {
    display: block;
  }
}

.MainMenu {
  display: flex;
  max-width: var(--maxHeaderWidth);
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  
  .MainMenu-Slot {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    &:first-child > span { margin-right: auto; }
    &:last-child  > span { margin-left: auto;  }
  }

  .MainMenu-Slot.EditSlot {
    @media (max-width: 576px) {
      opacity: 0;
    }
  }

  .MainMenu-Slot.NavSlot {
    width: 0;
    z-index: 9;
    font-size: 1.25em;
  }

  .MainMenu-Slot.SearchSlot {
    /* This is to center searchbox when nav menu is hidden, but it is not responsive */
    padding: 0 var(--padHoriz);
    justify-content: flex-end;
    height: var(--headerHeight);
  }

  .SearchContainer {
    width: 12rem;
    padding: 0 5px;
    transition: width 0.3s, margin 0.3s;

    @media (max-width: 576px) {
      width: 3.5rem;
    }

    #search__Row {
      flex: 0 0;
    
      .form-input-label {
        display: none;
      }
    
      #search__Field {
        border-radius: 2em;
        padding: var(--padVert) calc(var(--padHoriz) * 2);
        border: 1px solid var(--borderMutedColor);
        outline: none;
      }
    }
  }


  &.search {
    .SearchContainer {
      width: 100vw;
      
      #search__Field {
        width: calc(100vw - 10px);
        background-color: rgba(255,255,255,0.8);
      }
    }
  }
}

.TopMenuContainer {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  box-shadow: var(--shadow);
  background: var(--pageBg);

  .AdminMenu {
    flex-grow: 0;
    margin-right: 0.25rem;
  }

  .LogoutMenu {
    flex-grow: 0;
    margin-left: auto;

    .Button:hover {
      background-color: var(--primaryBg);
      color: var(--primaryFg);
    }
  }

  .EditLiveMenu {
    display: none;
    position: relative;

    @media (max-width: 576px) {
      display: block;
    }
  }
}

.dark-overlay {
  .PullUpMenu:not(.active) {
    #search__Field {
      color: #fff;
      &::placeholder {
        border: 1px solid #fff;
      }
    }  
  }
}

.SearchHeader {
  font-size: 1.25rem;
  margin: 1rem auto 3rem;
  max-width: 40rem;
}

.SearchContainer {

  .formlib-FormRows {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #search__Field {
    color: var(--textColor);
    max-width: 40rem;
    opacity: 0.75;
    background: transparent;
    border: 1px solid #eaeaea;
    border-radius: 2em;
    transition: margin 0.3s, width 0.3s, opacity 0.5s;
    width: 12rem;

    @media (max-width: 576px) {
      width: 3rem;
      border: none;
    }

    &::placeholder {
      color: #ddd;
      @media (max-width: 576px) {
        opacity: 0;
      }
    }

    &.form-input {
      font-weight: 300;
    }

    &:focus {
      opacity: 1;

      @media (min-width: 576px) {
        width: 15rem;
      }
    }
  }
  
  .sticky-row {
    background: none;
    bottom: auto;
    z-index: 9;
    border-top: none;

    .form-nav,
    form {
      display: none;
      background: rgba(255,255,255,0.8);
    }
  }

  .form-nav,
  form {
    padding: 8px 0 0;
    background: white;
  }

  .form-nav {
    justify-content: center;
  }

  .form-row {
    margin-bottom: 5px;

    .PullUp & {
      margin-bottom: 0;
    }
  }
}

.NavBar {
  &-enter {
    opacity: 0;
    
  }
  &-enter-active {
    transition: opacity 0.5s ease-in;
  }
  &-enter-end {
    opacity: 1;
  }
  /* ----------------------------- */
  &-leave {
    opacity: 1;
  }
  &-leave-active {
    transition: opacity 0.3s ease-in;
  }
  &-leave-end {
    opacity: 0;
  }
}

.BlogPublicSwitch.SwitchButton {
  --colorSuccess: #74BA6F;
  --colorSuccessHover: #60a75b;
  --colorSuccessLight: #99d195;
  --borderRadius: 0.5rem;

  margin-left: auto;
  margin-right: calc(var(--padHoriz) / 2);
  margin-top: -6px; // Compensate search box padding to make nav flush on bottom
  width: 11.5rem;
  font-size: 1.1em;

  .textDefault {
    padding-left: 43px;
  }

  &.isSelected {
    background-color: var(--colorSuccess);
    svg {
      color: var(--colorSuccessLight);
    }.toggle {
      background-color: var(--colorSuccessLight);
    }

    &:hover {
      background-color: var(--colorSuccessHover);
    }
  }
}