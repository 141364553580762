.Button,
.ButtonClose,
.StateButton,
.NavStateButton,
.SwitchButton {
  --padHoriz: 1rem;
  --padVert: 0.75rem;
  --borderRadius: var(--borderRadiusSmall);

  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font: var(--baseFont);
  font-weight: 400;
  text-align: center;
  border-radius: var(--borderRadius);
  padding: var(--padVert) var(--padHoriz);
  border: 0;
  line-height: 1em;

  flex-grow: 0;
  flex-shrink: 0;

  transition: 0.1s ease-in;

  svg {
    /* Make sure icons fill their space but not more */
    width: 100%;
    height: 100%;
  }

  // Don't underline if button is <a>
  &,
  &:link,
  &:hover,
  &:visited {
    text-decoration: none;
  }

  &.link + i,
  i + &.link {
    // Fix spacing between <i> and link button
    // Note on performance of adjacent selector:
    // https://www.sitepoint.com/optimizing-css-id-selectors-and-other-myths/
    margin-left: calc(0px - var(--padHoriz));
  }
}

.Button { 
  &.primary {
    background-color: var(--primaryBg);
    color: var(--primaryFg);
    
    &:hover {
      background-color: var(--primaryHover);
    }
  }
  &.secondary {
    background-color: var(--secondaryBg);
    color: var(--secondaryFg);

    &:hover {
      background-color: var(--secondaryHover);
    }
  }
  &.link {
    background-color: var(--linkBg);
    color: var(--linkFg);
    
    &:hover {
      background-color: var(--linkHover);
    }
  }
}

.ButtonClose {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  padding-left: 0.5rem;
  padding-right: 1.5rem;
  gap: var(--gapHoriz);

  background-color: var(--linkBg);
  color: var(--linkFg);

  &:hover {
    background-color: var(--primaryHover);
    color: var(--primaryFg);

    .icon {
      fill: var(--primaryFg);
    }
  }

  .icon {
    fill: var(--linkFg);
    height: 0.8rem;
  }
}

.ButtonBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--gapHoriz);

  &.inline {
    display: inline-flex;
  }
}

.NavStateButton,
.StateButton {
  background-color: var(--linkBg);
  color: var(--linkFg);
  border-bottom: 3px solid transparent;
  border-radius: 0;
  
  &:hover {
    background-color: var(--linkHover);
    color: var(--linkFg);
  }

  &.isSelected {
    border-bottom-color: var(--linkFg);
  }

}

.NavStateButton {
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: none;
  }
}

.IconButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--padVert) var(--padHoriz);
  color: var(--iconMutedFill);
  border: none;
  background: none;
  border-radius: var(--borderRadius);
  transition: 0.15s ease-out;
  
  &:hover {
    color: var(--iconHover);
  }
  &:active {
    color: var(--iconActive);
  }

  
  svg {
    width: 100%;
    height: 100%;
  }
}

.SwitchButton { 
  --primaryBgLight: #65A7F4;
  --secondnaryBgLight: #65A7F4;
  --linkBgLight: #65A7F4;

  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  overflow: hidden;

  &.notMounted {
    .textDefault,
    .textSelected,
    .toggle,
    svg {
      transition: none;
    }
  }

  .textDefault {
    flex-wrap: nowrap;
    white-space: nowrap;
    max-width: 100%;
    padding-left: 30px;
    opacity: 1;
    transition: all 0.3s ease-in;
  }
  .textSelected {
    flex-wrap: nowrap;
    white-space: nowrap;
    position: absolute;
    max-width: 0;
    opacity: 0;
    transition: all 0.3s ease-in;
    margin-right: 2em;
  }

  .toggle,
  svg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: auto;
    transition: all 0.2s ease-in;
  } .toggle {
    width: 30px;
  }

  &.isSelected {
    .textDefault {
      position: absolute;
      max-width: 0;
      padding-left: 0;
      opacity: 0;
    }
    .textSelected {
      position: relative;
      max-width: 100%;
      opacity: 1;
    }
    .toggle,
    svg {
      left: 100%;
      transform: translateX(-100%);
    }
  }

  &.primary {
    background-color: var(--primaryBg);
    color: var(--primaryFg);
    
    svg {
      color: var(--primaryBgLight);
    }.toggle {
      background-color: var(--primaryBgLight);
    }
    
    &:hover {
      background-color: var(--primaryHover);
    }
  }
  &.secondary {
    background-color: var(--secondaryBg);
    color: var(--secondaryFg);
    svg {
      color: var(--secondaryBgLight);
    }.toggle {
      background-color: var(--secondaryBgLight);
    }
    
    &:hover {
      background-color: var(--secondaryHover);
    }
  }
  &.link {
    background-color: var(--linkBg);
    color: var(--linkFg);
    svg {
      color: var(--linkBgLight);
    }.toggle {
      background-color: var(--linkBgLight);
    }
    
    &:hover {
      background-color: var(--linkHover);
    }
  }
}