.Gallery {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  
  .image,
  img {
    cursor: pointer;
    object-fit: cover;
    position: relative;

    i {
      content: attr(data-show-more);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: 'Times New Roman', Times, serif;
      font-weight: 400;
      font-size: 4em;
      color: white;
      text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
    }
  }
  .image {
    display: flex;
    overflow: hidden;

    img {
      height: 100%;
    }
    i {
      pointer-events: none;
    }
  }

  &.cover {
    align-items: unset;
    img {
      object-fit: cover;
    }
  }

  &.one {
    .image {
      max-height: 90vh;
      width: 100%;
      img {
        object-fit: contain;
        max-height: 90vh;
      }
      .placeholder {
        min-height: 20rem;
      }
    }
  }
  
  &.two {
    .image:nth-child(1) {
      width: calc(50% - 1px);
      margin-right: 1px;
    }
    .image:nth-child(2) {
      width: calc(50% - 1px);
      margin-left: 1px;
    }
    .placeholder {
      min-height: 20rem;
    }
  }

  &.one_two {
    gap: 2px;
    
    .image:nth-child(1) {
      width: 100%;
    
      .placeholder {
        min-height: 16rem;
        max-height: 35vh;
      }
    }
    .image:nth-child(2),
    .image:nth-child(3) {
      width: calc(50% - 1px);
      max-height: 25vh;

      .placeholder {
        min-height: 10rem;
        max-height: 35vh;
      }
    }
  }
  
  
  &.one_three {
    .image:nth-child(1) {
      width: calc(100% - 1px); // remove 1px because of rounding issue with three below
      margin-bottom: 2px;

      .placeholder {
        min-height: 16rem;
        max-height: 35vh;
      }
    }
    .image:nth-child(2),
    .image:nth-child(3),
    .image:nth-child(4) {
      width: calc(33.33% - 1px);
      max-height: 25vh;

      .placeholder {
        min-height: 10rem;
        max-height: 35vh;
      }
    }
    .image:nth-child(3) {
      width: calc(33.33% - 2px);
      margin: 0 2px;
    }
  }

  &.one_two_horizontal {
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 50% 50%;
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    width: calc(100% + 4rem);
    margin-left: -2rem;
    max-width: 100vw;

    .image:nth-child(1) {
      grid-row: 1 / -1;
      max-height: 100%;;
      
      img {
        max-height: 100%;
      }

      .placeholder {
        min-height: 26rem;
      }
    }
    .image:nth-child(2) {}
    .image:nth-child(3) {}
  }

  &.one_four_horizontal {
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 40% 30% 30%;
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    width: calc(100% + 8rem);
    margin-left: -4rem;
    max-width: 100vw;

    .image:nth-child(1) {
      grid-row: 1 / -1;
      max-height: 100%;

      .placeholder {
        min-height: 26rem;
      }
      
      img {
        max-height: 100%;
      }
    }
    .image:nth-child(2) {}
    .image:nth-child(3) {}
  }
}