@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600|Roboto:200,300,400,500");
/* RESET https://dev.to/hankchizljaw/a-modern-css-reset-6p3 */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box; }

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0; }

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0; }

/* Set core body defaults */
body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5; }

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none; }

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto; }

/* Make images easier to work with */
img {
  width: 100%;
  display: block; }

/* Natural flow and rhythm in articles by default */
/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
  margin: 0; }

/* Remove outline on input elements in chrome */
input:focus, textarea:focus, select:focus {
  outline: none; }

/* /END RESET */
/* Custom fonts */
caption {
  display: block; }

body {
  overflow: hidden; }

/* Main Colour Variables scoped to :root */
:root {
  font-size: 16px;
  --primaryBg: #5591FF;
  --primaryFg: white;
  --primaryHover: #307AFF;
  --secondaryBg: #AFAFAF;
  --secondaryFg: white;
  --secondaryHover: #939393;
  --linkBg: transparent;
  --linkFg: var(--primaryBg);
  --linkHover: #EEF0F5;
  --errorBg: rgb(237, 159, 159);
  --errorFg: var(--primaryFg);
  --borderMutedColor: #ddd;
  --inputBorderColor: #eee;
  --inputRowLineColor: #ddd;
  --listItemHoverColor: rgba(0,0,0, 0.025);
  --shadow: 0px 1px 5px 0px rgba(0,0,0,0.25);
  --shadowMuted: 0px 1px 0px 0px rgba(0,0,0,0.1);
  --pageBg: white;
  --stickyHeaderBg: rgba(255,255,255,0.6);
  --stickyFooterBg: rgba(0,0,0,0.05);
  --backdropFill: rgba(0, 0, 0, 0.2);
  --textColor: black;
  --textIconColor: #666;
  --textMutedColor: #999;
  --textSubtleColor: #bbb;
  --textAccentedColor: var(--primaryBg);
  --textErrorColor: rgb(206, 88, 88);
  --iconFill: var(--secondaryBg);
  --iconMutedFill: #ccc;
  --iconDarkFill: black;
  --iconAccentedFill: var(--primaryBg);
  --iconWarningFill: var(--textErrorColor);
  --baseFont: "Helvetica Neue";
  --headerFont: semi-bold;
  --textFont: regular;
  --baseFontSize: 1.4rem;
  --h1FontSizeEm: 2.0em;
  --h2FontSizeEm: 1.8em;
  --h3FontSizeEm: 1.6em;
  --padHoriz: 0.5rem;
  --padHorizNeg: -0.5rem;
  --padVert: 0.5rem;
  --padVertNeg: -0.5rem;
  --gapHoriz: 1rem;
  --gapHorizNeg: -1rem;
  --gapVert: 0.5rem;
  --gapVertNeg: -0.5rem;
  --colGap: 1.5rem;
  --colGapNeg: 1.5rem;
  --borderRadius: 0.4rem;
  --borderRadiusSmall: 2px;
  --maxHeaderWidth: 70rem;
  --maxBodyWidth: 60rem;
  --maxContentWidth: 50rem;
  --maxFormWidth: 40rem;
  --maxContentWidthNarrow: 30rem;
  --headerHeight: 3rem;
  --footerHeight: 3.5rem;
  --formRowGap: 2rem;
  --imageTextBackgroundGradientHoriz: linear-gradient(90deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  --imageTextBackgroundGradientVert: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  --scrollbarWidth: 15px;
  /*
    Styling scope is written in PascalCase, such as components. Variants added with dash:
    .ThisComponent-VariantName

    Styling on classes in a component/scope is lowercase and short and should
    always be defined in a scope:

    .Component {
      .body {}
    }

  */
  font: var(--baseFont); }

.Button,
.ButtonClose,
.StateButton,
.NavStateButton,
.SwitchButton {
  --padHoriz: 1rem;
  --padVert: 0.75rem;
  --borderRadius: var(--borderRadiusSmall);
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font: var(--baseFont);
  font-weight: 400;
  text-align: center;
  border-radius: var(--borderRadius);
  padding: var(--padVert) var(--padHoriz);
  border: 0;
  line-height: 1em;
  flex-grow: 0;
  flex-shrink: 0;
  transition: 0.1s ease-in; }
  .Button svg,
  .ButtonClose svg,
  .StateButton svg,
  .NavStateButton svg,
  .SwitchButton svg {
    /* Make sure icons fill their space but not more */
    width: 100%;
    height: 100%; }
  .Button, .Button:link, .Button:hover, .Button:visited,
  .ButtonClose,
  .ButtonClose:link,
  .ButtonClose:hover,
  .ButtonClose:visited,
  .StateButton,
  .StateButton:link,
  .StateButton:hover,
  .StateButton:visited,
  .NavStateButton,
  .NavStateButton:link,
  .NavStateButton:hover,
  .NavStateButton:visited,
  .SwitchButton,
  .SwitchButton:link,
  .SwitchButton:hover,
  .SwitchButton:visited {
    text-decoration: none; }
  .Button.link + i,
  i + .Button.link,
  .ButtonClose.link + i,
  i +
  .ButtonClose.link,
  .StateButton.link + i,
  i +
  .StateButton.link,
  .NavStateButton.link + i,
  i +
  .NavStateButton.link,
  .SwitchButton.link + i,
  i +
  .SwitchButton.link {
    margin-left: calc(0px - var(--padHoriz)); }

.Button.primary {
  background-color: var(--primaryBg);
  color: var(--primaryFg); }
  .Button.primary:hover {
    background-color: var(--primaryHover); }

.Button.secondary {
  background-color: var(--secondaryBg);
  color: var(--secondaryFg); }
  .Button.secondary:hover {
    background-color: var(--secondaryHover); }

.Button.link {
  background-color: var(--linkBg);
  color: var(--linkFg); }
  .Button.link:hover {
    background-color: var(--linkHover); }

.ButtonClose {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 1.5rem;
  gap: var(--gapHoriz);
  background-color: var(--linkBg);
  color: var(--linkFg); }
  .ButtonClose:hover {
    background-color: var(--primaryHover);
    color: var(--primaryFg); }
    .ButtonClose:hover .icon {
      fill: var(--primaryFg); }
  .ButtonClose .icon {
    fill: var(--linkFg);
    height: 0.8rem; }

.ButtonBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--gapHoriz); }
  .ButtonBar.inline {
    display: inline-flex; }

.NavStateButton,
.StateButton {
  background-color: var(--linkBg);
  color: var(--linkFg);
  border-bottom: 3px solid transparent;
  border-radius: 0; }
  .NavStateButton:hover,
  .StateButton:hover {
    background-color: var(--linkHover);
    color: var(--linkFg); }
  .NavStateButton.isSelected,
  .StateButton.isSelected {
    border-bottom-color: var(--linkFg); }

.NavStateButton {
  text-decoration: none; }
  .NavStateButton:hover, .NavStateButton:active {
    text-decoration: none; }

.IconButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--padVert) var(--padHoriz);
  color: var(--iconMutedFill);
  border: none;
  background: none;
  border-radius: var(--borderRadius);
  transition: 0.15s ease-out; }
  .IconButton:hover {
    color: var(--iconHover); }
  .IconButton:active {
    color: var(--iconActive); }
  .IconButton svg {
    width: 100%;
    height: 100%; }

.SwitchButton {
  --primaryBgLight: #65A7F4;
  --secondnaryBgLight: #65A7F4;
  --linkBgLight: #65A7F4;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  overflow: hidden; }
  .SwitchButton.notMounted .textDefault,
  .SwitchButton.notMounted .textSelected,
  .SwitchButton.notMounted .toggle,
  .SwitchButton.notMounted svg {
    transition: none; }
  .SwitchButton .textDefault {
    flex-wrap: nowrap;
    white-space: nowrap;
    max-width: 100%;
    padding-left: 30px;
    opacity: 1;
    transition: all 0.3s ease-in; }
  .SwitchButton .textSelected {
    flex-wrap: nowrap;
    white-space: nowrap;
    position: absolute;
    max-width: 0;
    opacity: 0;
    transition: all 0.3s ease-in;
    margin-right: 2em; }
  .SwitchButton .toggle,
  .SwitchButton svg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: auto;
    transition: all 0.2s ease-in; }
  .SwitchButton .toggle {
    width: 30px; }
  .SwitchButton.isSelected .textDefault {
    position: absolute;
    max-width: 0;
    padding-left: 0;
    opacity: 0; }
  .SwitchButton.isSelected .textSelected {
    position: relative;
    max-width: 100%;
    opacity: 1; }
  .SwitchButton.isSelected .toggle,
  .SwitchButton.isSelected svg {
    left: 100%;
    transform: translateX(-100%); }
  .SwitchButton.primary {
    background-color: var(--primaryBg);
    color: var(--primaryFg); }
    .SwitchButton.primary svg {
      color: var(--primaryBgLight); }
    .SwitchButton.primary .toggle {
      background-color: var(--primaryBgLight); }
    .SwitchButton.primary:hover {
      background-color: var(--primaryHover); }
  .SwitchButton.secondary {
    background-color: var(--secondaryBg);
    color: var(--secondaryFg); }
    .SwitchButton.secondary svg {
      color: var(--secondaryBgLight); }
    .SwitchButton.secondary .toggle {
      background-color: var(--secondaryBgLight); }
    .SwitchButton.secondary:hover {
      background-color: var(--secondaryHover); }
  .SwitchButton.link {
    background-color: var(--linkBg);
    color: var(--linkFg); }
    .SwitchButton.link svg {
      color: var(--linkBgLight); }
    .SwitchButton.link .toggle {
      background-color: var(--linkBgLight); }
    .SwitchButton.link:hover {
      background-color: var(--linkHover); }

.formlib-DraggableList {
  position: relative; }
  .formlib-DraggableList .formlib-Draggable {
    transition: all 0.1s ease-in; }
    .formlib-DraggableList .formlib-Draggable.source {
      opacity: 0.6; }
    .formlib-DraggableList .formlib-Draggable.insertBefore {
      margin-left: 3rem; }
    .formlib-DraggableList .formlib-Draggable.insertAfter {
      margin-right: calc(var(--padHoriz) + 3rem); }
    .formlib-DraggableList .formlib-Draggable.hideSource {
      opacity: 0.2; }
  .formlib-DraggableList .debugMarkerLeft,
  .formlib-DraggableList .debugMarkerRight {
    position: absolute;
    top: 0;
    height: 1rem;
    width: 3px; }
  .formlib-DraggableList .debugMarkerLeft {
    background: red; }
  .formlib-DraggableList .debugMarkerRight {
    background: green; }
  .formlib-DraggableList .debugMarkerIndex {
    position: absolute;
    top: 1rem;
    left: 1rem;
    background-color: black;
    color: white; }
  .formlib-DraggableList .debugDragMarker {
    position: absolute;
    background-color: black;
    width: 3px;
    height: 100%;
    top: 0; }

.formlib-Draggable--Animation-enter {
  opacity: 0;
  width: 3rem;
  /* size of placeholder */ }

.formlib-Draggable--Animation-enter-active {
  transition: all 1.5s ease-out; }

.formlib-Draggable--Animation-enter-end {
  opacity: 1; }

.formlib-Draggable--Animation-leave {
  opacity: 1; }

.formlib-Draggable--Animation-leave-active {
  transition: all 1.1s ease-in; }

.formlib-Draggable--Animation-leave-end {
  opacity: 0;
  width: 0; }

.Dropdown-Wrapper {
  position: relative;
  line-height: 1; }

.Dropdown {
  --dropdownBg: var(--pageBg);
  --dropdownBorderColor: var(--borderMutedColor);
  --dropdownSelect: var(--linkHover);
  --dropdownHoverBg: var(--linkFg);
  --dropdownHoverFg: var(--dropdownBg);
  --dropdownPadVert: var(--padVert);
  --dropdownPadHoriz: var(--padHoriz);
  --dropdownSpacer: 0px;
  --dropdownBorderRadius: var(--borderRadiusSmall);
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: var(--dropdownBg);
  border: 1px solid var(--dropdownBorderColor); }
  .Dropdown .item {
    border-width: 0;
    border-radius: 0;
    padding: var(--dropdownPadVert) var(--dropdownPadHoriz);
    background-color: var(--dropdownBg);
    cursor: pointer; }
    .Dropdown .item.isFocused {
      background-color: var(--dropdownSelect); }
    .Dropdown .item:hover {
      color: var(--dropdownHoverFg);
      background-color: var(--dropdownHoverBg); }
  .Dropdown button {
    text-align: left; }
  .Dropdown.placeBottomLeft {
    bottom: 0;
    left: 0;
    transform: translate(0, calc(100% + var(--dropdownSpacer))); }
  .Dropdown.placeBottomCenter {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, calc(100% + var(--dropdownSpacer))); }
  .Dropdown.placeBottomRight {
    bottom: 0;
    right: 0;
    transform: translate(0, calc(100% + var(--dropdownSpacer))); }
  .Dropdown.placeTopLeft {
    top: 0;
    left: 0;
    transform: translate(0, calc(-100% - var(--dropdownSpacer)));
    border-top-left-radius: var(--dropdownBorderRadius);
    border-top-right-radius: var(--dropdownBorderRadius); }
  .Dropdown.placeTopCenter {
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - var(--dropdownSpacer)));
    border-top-left-radius: var(--dropdownBorderRadius);
    border-top-right-radius: var(--dropdownBorderRadius); }
  .Dropdown.placeTopRight {
    top: 0;
    right: 0;
    transform: translate(0, calc(-100% - var(--dropdownSpacer)));
    border-top-left-radius: var(--dropdownBorderRadius);
    border-top-right-radius: var(--dropdownBorderRadius); }
  .Dropdown.placeLeftCenter {
    top: 50%;
    left: 0;
    transform: translate(calc(-100% - var(--dropdownSpacer)), -50%); }
  .Dropdown.placeRightCenter {
    top: 50%;
    right: 0;
    transform: translate(calc(100% - var(--dropdownSpacer)), -50%); }

/**
 * Animate rows and list rows
 */
.Dropdown--Animation-leave {
  opacity: 1; }

.Dropdown--Animation-leave-active {
  overflow: hidden;
  transition: height 0.1s ease-out, opacity 0.1s ease-in; }

.Dropdown--Animation-leave-end {
  height: 0;
  opacity: 0; }

.Dropdown--Animation-enter {
  height: 0;
  opacity: 0; }

.Dropdown--Animation-enter-active {
  transition: height 0.1s ease-out, opacity 0.1s ease-in; }

.Dropdown--Animation-enter-end {
  opacity: 1; }

.form-row {
  display: flex;
  flex-direction: column; }

.tab-page,
.page {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  max-height: -fill-available;
  overflow: hidden auto; }
  .tab-page > .header,
  .tab-page > .footer,
  .page > .header,
  .page > .footer {
    flex-shrink: 0; }
  .tab-page > .body,
  .page > .body {
    flex: 1 0 auto;
    width: 100%; }

.PageAnimation-enter-active {
  transition: opacity 0.6s ease-out, transform 0.5s ease-out; }

.PageAnimation-leave-end, .PageAnimation-enter {
  opacity: 0;
  transform: translateX(50%); }

.PageAnimation-leave, .PageAnimation-enter-end {
  opacity: 1;
  transform: translateX(0); }

.PageAnimation-leave-active {
  transition: opacity 0.3s ease-in, transform 0.3s ease-in; }

.masonry {
  --gridGap: 0.5rem;
  display: grid;
  grid-gap: var(--gridGap);
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  white-space: nowrap; }
  .masonry.rendered {
    display: block;
    overflow-x: hidden;
    padding-right: 0; }
  .masonry .masonry-col {
    display: inline-block;
    vertical-align: top;
    margin-right: var(--gridGap); }
    .masonry .masonry-col:last-child {
      margin-right: 0; }
  .masonry .masonry-col > * {
    margin-bottom: var(--gridGap); }
    .masonry .masonry-col > *:last-child {
      margin-bottom: 0; }

.grid {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(15rem, 1fr));
  overflow-x: hidden; }

.media {
  display: flex;
  flex-direction: column; }
  .media.horizontal {
    flex-direction: row; }

.body {
  display: block;
  margin: 0 auto;
  padding: var(--padVert) var(--padHoriz); }

.section {
  display: flex;
  flex-direction: column; }
  .section.has-columns {
    flex-direction: row;
    gap: var(--gapHoriz); }
    .section.has-columns > * {
      flex-grow: 1;
      flex-basis: 0; }
    @media (max-width: 576px) {
      .section.has-columns {
        flex-direction: column; }
        .section.has-columns .column {
          max-width: 100%;
          width: 100%; } }
  .section.sticky {
    position: sticky; }

.footer {
  display: flex;
  width: 100%;
  height: var(--footerHeight);
  transition: transform 0.2s ease-in; }
  .footer-sticky {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 1; }
    .footer-sticky .footer {
      background-color: white;
      /* TODO: Should be variable */
      pointer-events: all; }
  .footer.fixed {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: white;
    /* TODO: Should be variable */
    flex-shrink: 0; }
  .footer.hide {
    transition: transform 0.4s ease-in;
    transform: translateY(100%) scaleY(0); }

.header {
  display: flex;
  width: 100%;
  transition: transform 0.2s ease-in;
  height: var(--headerHeight); }
  .header-sticky {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 1; }
    .header-sticky .header {
      background-color: white;
      /* TODO: Should be variable */
      pointer-events: all; }
  .header.fixed {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    z-index: 1;
    background-color: white;
    /* TODO: Should be variable */
    flex-shrink: 0; }
  .header.hide {
    transition: transform 0.5s ease-in;
    transform: translateY(-100%); }

.trailing {
  margin-left: auto; }

.Filter {
  gap: 1px; }

.MessageCanvas {
  --canvasBg: var(--pageBg);
  --canvasFg: var(--iconMutedFill);
  --canvasPadVert: 2rem;
  --canvasPadHoriz: 2rem;
  --canvasArrowFill: var(--canvasFg);
  --canvasArrowSize: 2rem;
  --canvasArrowInnerSize: 1rem;
  --canvasArrowSpacer: 4px;
  display: flex;
  position: absolute;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  height: 100%; }
  .MessageCanvas .body {
    display: inline-flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: var(--canvasPadVert) var(--canvasPadHoriz);
    background-color: var(--canvasBg);
    border-radius: var(--borderRadius);
    color: var(--canvasFg);
    font-weight: 400; }
    .MessageCanvas .body h1, .MessageCanvas .body h2, .MessageCanvas .body h3, .MessageCanvas .body h4 {
      font-weight: 600; }
    .MessageCanvas .body *:last-child {
      margin-bottom: 0; }
  .MessageCanvas.arrowBottomCenter .body::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: var(--canvasArrowSize);
    height: var(--canvasArrowSize);
    transform: translate(-50%, calc(100% + var(--canvasArrowSpacer)));
    border-top: var(--canvasArrowInnerSize) solid var(--canvasArrowFill);
    border-right: var(--canvasArrowInnerSize) solid transparent;
    border-left: var(--canvasArrowInnerSize) solid transparent; }
  .MessageCanvas.arrowTopCenter .body::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: var(--canvasArrowSize);
    height: var(--canvasArrowSize);
    transform: translate(-50%, calc(-100% - var(--canvasArrowSpacer)));
    border-bottom: var(--canvasArrowInnerSize) solid var(--canvasArrowFill);
    border-right: var(--canvasArrowInnerSize) solid transparent;
    border-left: var(--canvasArrowInnerSize) solid transparent; }
  .MessageCanvas.arrowLeftCenter .body::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: var(--canvasArrowSize);
    height: var(--canvasArrowSize);
    transform: translate(calc(-100% - var(--canvasArrowSpacer)), -50%);
    border-right: var(--canvasArrowInnerSize) solid var(--canvasArrowFill);
    border-top: var(--canvasArrowInnerSize) solid transparent;
    border-bottom: var(--canvasArrowInnerSize) solid transparent; }
  .MessageCanvas.arrowRightCenter .body::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: var(--canvasArrowSize);
    height: var(--canvasArrowSize);
    transform: translate(calc(100% - var(--canvasArrowSpacer)), -50%);
    border-left: var(--canvasArrowInnerSize) solid var(--canvasArrowFill);
    border-top: var(--canvasArrowInnerSize) solid transparent;
    border-bottom: var(--canvasArrowInnerSize) solid transparent; }

:root {
  --transitionModalEnter: transform 0.4s ease-out, opacity 0.4s ease-in;
  --transitionModalLeave: transform 0.3s ease-in, opacity 0.2s ease-in;
  --transitionModalBackdropEnter: opacity 0.4s ease-in;
  --transitionModalBackdropLeave: opacity 0.4s ease-in;
  --modalHeaderHeight: 3.5rem;
  --modalFooterHeight: 3.5rem; }

.ModalSheet,
.Modal {
  position: fixed;
  display: flex;
  top: 0;
  width: 100vw;
  height: 100vh; }
  .ModalSheet > .content,
  .Modal > .content {
    display: flex;
    position: relative;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    pointer-events: auto;
    background-color: var(--pageBg);
    background-clip: padding-box;
    border-radius: var(--borderRadius);
    outline: 0; }
    .ModalSheet > .content > .header,
    .Modal > .content > .header {
      border-bottom: 1px solid var(--borderMutedColor);
      padding: var(--padVert) var(--padHoriz);
      height: var(--modalHeaderHeight);
      left: 0;
      right: 0; }
      .ModalSheet > .content > .header > .Toolbar,
      .ModalSheet > .content > .header > .ButtonBar,
      .Modal > .content > .header > .Toolbar,
      .Modal > .content > .header > .ButtonBar {
        max-width: 100%;
        margin-top: var(--padVertNeg); }
    .ModalSheet > .content > .body,
    .Modal > .content > .body {
      overflow: visible;
      width: 100%; }
    .ModalSheet > .content > .footer,
    .Modal > .content > .footer {
      height: var(--modalFooterHeight); }
  .ModalSheet .backdrop,
  .Modal .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--backdropFill); }

/*************************
** Modal Animations CSS **
**************************/
.Modal > .backdrop.fade {
  transition: var(--transitionModalBackdropLeave);
  opacity: 0; }

.Modal > .backdrop.show {
  transition: var(--transitionModalBackdropEnter);
  opacity: 1; }

.Modal > .content.fade {
  transition: var(--transitionModalLeave);
  opacity: 0.5;
  transform: translateY(-50%); }

.Modal > .content.show {
  transition: var(--transitionModalEnter);
  opacity: 1;
  transform: unset; }

:root {
  --transitionModalSheetEnter: all 0.3s ease-in;
  --transitionModalSheetLeave: all 0.2s ease-in; }

.ModalSheet--Animation-enter-end, .ModalSheet--Animation-leave {
  opacity: 1; }

.ModalSheet--Animation-leave-active {
  transition: var(--transitionModalSheetLeave); }

.ModalSheet--Animation-enter-active {
  transition: var(--transitionModalSheetEnter); }

.ModalSheet--Animation-enter, .ModalSheet--Animation-leave-end {
  opacity: 0;
  height: 0; }

.NavFilter {
  gap: 1px; }

.formlib-UploadProgress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--backdropFill);
  z-index: 1; }
  .formlib-UploadProgress .progress {
    position: absolute;
    top: 50%;
    left: 10%;
    right: 10%;
    height: 1rem;
    transform: translateY(-50%);
    background-color: var(--borderMutedColor);
    opacity: 0.9;
    overflow: hidden; }
    .formlib-UploadProgress .progress .bar {
      height: 100%;
      background-color: var(--iconAccentedFill);
      transition: width 0.3s ease-in-out;
      color: white; }
  .formlib-UploadProgress .body {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 10%;
    right: 10%;
    transform: translateY(-50%); }

/*
  Sticky
*/
.sticky-vert {
  position: sticky;
  top: 0; }
  .sticky-vert.bottom {
    top: auto;
    bottom: 0; }

.sticky-horiz {
  position: sticky;
  left: 0; }
  .sticky-horiz.bottom {
    left: auto;
    right: 0; }

.Toolbar {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  max-width: var(--maxBodyWidth);
  margin: 0 auto; }
  .Toolbar.has-shadow {
    box-shadow: var(--shadowMuted);
    /* Shadow blocker */ }
    .Toolbar.has-shadow::before {
      content: '';
      position: absolute;
      z-index: -1;
      /* To avoid blocking content */
      top: 0;
      bottom: 0;
      left: -5px;
      right: -5px;
      background-color: var(--pageBg); }
  .Toolbar > .hidden {
    visibility: hidden; }
  .Toolbar > .trailing,
  .Toolbar > .leading {
    flex-shrink: 1;
    flex-grow: 0;
    min-width: 10rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--gapHoriz); }
  .Toolbar > .trailing {
    justify-content: flex-end;
    margin-left: auto; }
  .Toolbar > .body {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    /*  vert align */
    justify-content: center;
    /* horiz align */ }

.Toolbar.align-center .trailing,
.Toolbar.align-center .leading {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.Toolbar.align-center .leading {
  left: 0; }

.Toolbar.align-center .trailing {
  right: 0; }

/* Bootstrap overrides */
.invalid-feedback {
  display: block !important; }

/* DateField Widget */
.form-input-date button.unset {
  opacity: 1;
  margin-left: 0.5rem;
  color: red;
  cursor: pointer;
  align-self: center;
  font-size: 0.75em; }
  .form-input-datetime.noValue .form-input-date button.unset {
    opacity: 0;
    margin-left: 0;
    width: 0; }

.form-input-date input {
  min-width: 5.5em; }

.form-input-date input:first-child {
  min-width: 6.5em; }

.form-input-date .Dropdown {
  --dropdownHoverBg: transparent;
  --dropdownHoverFg: inherit; }

.form-input-datetime {
  display: flex;
  flex-direction: row;
  gap: var(--gapHoriz);
  align-items: center; }
  .form-input-datetime .form-input {
    width: 6.5em; }

.DateSelector .header .yearMonth {
  white-space: nowrap;
  flex-grow: 1;
  text-align: center; }
  .DateSelector .header .yearMonth button {
    margin: 0;
    font-size: 1.5em; }

.DateSelector .footer {
  padding: 0.5rem;
  text-align: center;
  background-color: #f7f7f7;
  border-top: 1px solid #ebebeb; }

.DateSelector .Calendar thead {
  width: 100%;
  border-bottom: 1px solid var(--borderMutedColor); }
  .DateSelector .Calendar thead th {
    text-align: center;
    color: #aaa;
    padding-bottom: 0.5rem; }

.DateSelector .Calendar tbody tr:first-child td {
  padding-top: 0.5rem; }

.DateSelector .Calendar tbody td {
  text-align: center;
  color: #666; }
  .DateSelector .Calendar tbody td.text-muted button {
    color: #bbb !important; }
  .DateSelector .Calendar tbody td.today button {
    position: relative;
    border: 0;
    font-weight: 500;
    border-radius: var(--borderRadiusSmall); }
    .DateSelector .Calendar tbody td.today button small {
      position: absolute;
      font-size: 8px;
      line-height: 12px;
      top: -2px;
      left: 50%;
      transform: translateX(-50%);
      text-transform: uppercase;
      font-weight: 500; }
  .DateSelector .Calendar tbody td.selected button {
    background-color: var(--primaryBg);
    color: var(--primaryFg); }

.DateSelector .YearSelect {
  display: flex;
  flex-direction: row;
  gap: var(--gapHoriz); }
  .DateSelector .YearSelect ul {
    display: grid;
    grid: repeat(3, auto)/1fr 1fr 1fr;
    margin: 0;
    padding: 0; }
    .DateSelector .YearSelect ul li {
      padding: 0;
      margin: 0;
      list-style: none; }

.DateSelector .DateFieldItem:hover,
.DateSelector .DateFieldItem--selected {
  color: var(--primaryFg) !important;
  background-color: var(--primaryBg);
  border-radius: var(--borderRadiusSmall); }

.DateSelector .nav-item {
  flex: 0 1; }

.DateSelector .nav-link:hover {
  border-radius: var(--borderRadiusSmall);
  color: var(--primaryFg);
  background-color: var(--primaryBg); }

.DateSelector .DateFieldMonth {
  flex: 1 0;
  text-align: center; }

.DateSelector .DateField-YearSelect {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 1rem; }

.DateSelector .DateField-YearSelect-day {
  list-style: none;
  padding: 0.5rem;
  color: var(--primary);
  cursor: pointer; }

.DateSelector .DateField-YearSelect-minus,
.DateSelector .DateField-YearSelect-plus {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0.5rem; }
  .DateSelector .DateField-YearSelect-minus i,
  .DateSelector .DateField-YearSelect-plus i {
    align-self: center;
    font-weight: bold;
    font-style: normal;
    color: var(--primary);
    cursor: pointer; }

.DateSelector .DateField-YearSelect-minus {
  left: 0; }

.DateSelector .DateField-YearSelect-plus {
  right: 0; }

/**
*  DateTimeWidget
*/
.input-group.form-input-datetime {
  display: block; }
  .input-group.form-input-datetime > .formlib-DateTime_date {
    display: inline-block;
    width: 7.5em;
    transition: all 0.15s linear; }
  .input-group.form-input-datetime--noValue > .formlib-DateTime_date {
    width: 13.5em !important;
    border-radius: var(--borderRadiusSmall) !important; }
  .input-group.form-input-datetime > .formlib-DateTime_time {
    display: inline-block;
    width: 6em;
    transition: all 0.15s linear; }
  .input-group.form-input-datetime--noValue > .formlib-DateTime_time {
    width: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    border-width: 0 !important; }

/*
    ActionBar
*/
.ActionBar,
.formlib-ActionBar {
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
  width: 100%;
  border-top: 1px solid var(--inputBorderColor); }
  .ActionBar .btn-success,
  .formlib-ActionBar .btn-success {
    margin-right: 1rem;
    color: var(--white); }

.formlib-StickyActionBar {
  position: fixed;
  left: 0;
  box-shadow: var(--shadow); }
  .formlib-StickyActionBar--hidden {
    display: none; }

/**
 * FileUploadWidget
 */
.DragNDroppable {
  display: flex;
  align-items: center;
  flex-grow: 1;
  cursor: pointer;
  transition: background-color 0.15s ease-in, color 0.15s ease-in; }
  .DragNDroppable.DragNDroppable--drag {
    background-color: rgba(0, 0, 0, 0.4);
    border-color: rgba(0, 0, 0, 0); }
  .DragNDroppable.DragNDroppable--drag-hover {
    background-color: rgba(0, 0, 0, 0.6);
    border-color: rgba(0, 0, 0, 0); }
  .DragNDroppable.DragNDroppable--drag .placeholder,
  .DragNDroppable.DragNDroppable--drag-hover .placeholder {
    color: white; }

.formlib-ErrorMsg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--backdropFill); }
  .formlib-ErrorMsg .body {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 10%;
    right: 10%;
    transform: translateY(-50%); }
  .formlib-ErrorMsg .body p {
    font-size: 0.85em;
    color: white;
    margin-bottom: 0.6em; }

.formlib-FileUploadWidget .form-input-file {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: -1; }

.formlib-FileUploadWidget .placeholder {
  position: relative;
  margin: 0.5rem 1rem 0;
  font-size: 0.9em;
  color: var(--textMutedColor);
  top: auto;
  left: auto;
  position: inherit;
  transform: none;
  width: 100%; }

/*
  ImageField
*/
.ImageField.card {
  flex-direction: row;
  align-items: center;
  border: none;
  margin-bottom: 0;
  border-radius: 0; }
  .ImageField.card .card-img {
    border-radius: 0;
    overflow: hidden;
    object-fit: contain;
    max-height: 10rem;
    max-width: 30rem;
    width: auto;
    margin-right: auto !important; }
  .ImageField.card .card-title {
    font-weight: 100;
    font-size: 0.85em;
    margin: 0; }
  .ImageField.card .card-subtitle {
    margin: 0;
    margin-top: 0.2em; }
  .ImageField.card .card-text {
    font-size: 0.8em;
    margin: 0;
    margin-top: 0.2em; }
  .ImageField.card .card-body {
    padding: 0;
    height: 100%; }
  .ImageField.card .card-footer {
    padding: 0;
    background: none;
    border: none; }
  .ImageField.card .ImageField-Action {
    line-height: 0.8em;
    vertical-align: bottom; }

.form-input-object-field {
  display: block; }

.formlib-FormRows {
  width: 100%;
  margin: 0 auto; }
  .formlib-FormRows .form-row {
    max-width: var(--maxFormWidth);
    margin: 0 auto var(--formRowGap);
    flex-grow: 1; }
  .formlib-FormRows .form-input {
    font-weight: 200;
    font-size: 1em;
    padding: 0.1rem 0.5rem;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid var(--inputBorderColor);
    width: 100%; }
    .formlib-FormRows .form-input.isNotValid {
      border-bottom: 2px solid var(--textErrorColor); }
  .formlib-FormRows .form-input-textarea {
    font-size: 1.3em;
    line-height: 1.15em;
    min-height: 4em;
    background-image: linear-gradient(transparent, transparent calc(1.15em - 1px), var(--inputRowLineColor) 0px);
    background-size: 100% 1.15em;
    border-bottom: none; }
  .formlib-FormRows select.form-input {
    font-size: 1.3em; }
    .formlib-FormRows select.form-input option {
      font-size: 0.9em; }
  .formlib-FormRows .form-input-label {
    color: var(--textMutedColor);
    font-weight: 400;
    font-size: 1em;
    margin-bottom: 0; }
  .formlib-FormRows .form-input-help {
    color: var(--textMutedColor);
    font-weight: 200;
    font-size: 0.9em; }
  .formlib-FormRows .form-input-feedback {
    font-weight: 200;
    font-size: 0.9em; }
    .formlib-FormRows .form-input-feedback.isNotValid {
      color: var(--textErrorColor); }
  .formlib-FormRows .form-row-list > .form-input-label,
  .formlib-FormRows .form-row-object > .form-input-label {
    border-bottom: 1px solid var(--borderMutedColor);
    border-left: 5px solid var(--borderMutedColor);
    padding-left: 0.5em;
    display: block;
    font-size: 1.5em;
    font-weight: 200;
    margin: 1em 1rem 1em 0; }
  .formlib-FormRows .form-row-list > .form-input-feedback.isNotValid,
  .formlib-FormRows .form-row-object > .form-input-feedback.isNotValid {
    padding: var(--padVert) var(--padHoriz);
    border-radius: var(--borderRadiusSmall);
    background-color: var(--errorBg);
    color: var(--errorFg);
    text-align: center; }
  .formlib-FormRows .form-row-object > .form-input-field > .form-input-object-field {
    border-left: 2px solid var(--borderMutedColor);
    padding-left: 1rem; }
  .formlib-FormRows .form-row-list .formlib-ListFieldRow {
    display: flex; }
    .formlib-FormRows .form-row-list .formlib-ListFieldRow .formlib-ListFieldRowDeleteBtn.btn-danger {
      background: none;
      color: var(--textErrorColor);
      height: 2.5em;
      border: none;
      align-self: center; }
    .formlib-FormRows .form-row-list .formlib-ListFieldRow .formlib-ListFieldRowDeleteBtn:hover {
      background: var(--errorBg);
      color: var(--errorFg); }
    .formlib-FormRows .form-row-list .formlib-ListFieldRow > .form-row {
      flex-grow: 1; }
  .formlib-FormRows .form-input-label.checkbox {
    position: relative;
    padding-left: 2rem;
    line-height: 1em;
    text-align: left; }
    .formlib-FormRows .form-input-label.checkbox .form-input-checkbox {
      position: absolute;
      width: 1.2rem;
      height: 1.2rem;
      left: 0;
      top: 50%;
      transform: translate(0, -50%); }

.formlib-DragItem {
  position: relative; }
  .formlib-DragItem .formlib-DragHandle {
    width: 1rem;
    height: 1rem;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    background: url(./img/icons/drag_handle.svg);
    background-size: contain;
    cursor: grab; }

.form-row.IDateField .DateFieldSelector {
  width: 20rem; }

.form-row.IDateField .form-input-field > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--gapHoriz); }
  .form-row.IDateField .form-input-field > div .form-input {
    width: 7em; }
  .form-row.IDateField .form-input-field > div > a {
    flex-grow: 1; }

/**
 * Animate error messages
 */
.formlib-ErrorMsg--Animation-leave {
  opacity: 1; }

.formlib-ErrorMsg--Animation-leave-active {
  overflow: hidden;
  transition: height 0.3s ease-out, opacity 0.2s ease-in; }

.formlib-ErrorMsg--Animation-leave-end {
  height: 0;
  opacity: 0; }

.formlib-ErrorMsg--Animation-enter {
  height: 0;
  opacity: 0; }

.formlib-ErrorMsg--Animation-enter-active {
  transition: height 0.2s ease-out, opacity 0.5s ease-in; }

.formlib-ErrorMsg--Animation-enter-end {
  opacity: 1; }

/**
 * Animate rows and list rows
 */
.formlib-ListFieldRow--Animation-leave,
.FormRow--Animation-leave {
  opacity: 1; }

.formlib-ListFieldRow--Animation-leave-active,
.FormRow--Animation-leave-active {
  overflow: hidden;
  transition: height 0.3s ease-out, opacity 0.2s ease-in; }

.formlib-ListFieldRow--Animation-leave-end,
.FormRow--Animation-leave-end {
  height: 0;
  opacity: 0; }

.formlib-ListFieldRow--Animation-enter,
.FormRow--Animation-enter {
  height: 0;
  opacity: 0; }

.formlib-ListFieldRow--Animation-enter-active,
.FormRow--Animation-enter-active {
  transition: height 0.2s ease-out, opacity 0.5s ease-in; }

.formlib-ListFieldRow--Animation-enter-end,
.FormRow--Animation-enter-end {
  opacity: 1; }

#icon_ok #Oval {
  fill: #74BA6F; }

#icon_ok #Combined-Shape {
  fill: #FFFFFF; }

.LoginWidget {
  display: flex;
  flex-direction: column;
  justify-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 25rem;
  text-align: left;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25); }
  .LoginWidget .header {
    padding: 0.5rem 1.5rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    font-size: 1.35em;
    color: var(--textMutedColor);
    text-align: center; }
  .LoginWidget .body {
    width: 100%;
    padding: 1rem 1.5rem; }
  .LoginWidget .footer {
    padding: 1rem 1.5rem;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .LoginWidget .section {
    align-items: center; }
  .LoginWidget #Login-Logo {
    position: absolute;
    left: 50%;
    top: -7.5rem;
    transform: translateX(-50%); }
  .LoginWidget .row {
    justify-content: center; }
  .LoginWidget .card-footer {
    text-align: center; }
  .LoginWidget .LoginWidget-Form {
    justify-content: center;
    margin-bottom: 1rem; }
  .LoginWidget .LoginWidget-Separator {
    margin: 2rem 1rem;
    position: relative; }
    .LoginWidget .LoginWidget-Separator:before {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 0%;
      right: 0%;
      height: 0;
      border-bottom: 1px solid #ccc; }
    .LoginWidget .LoginWidget-Separator i {
      z-index: 0;
      padding: 0 1rem;
      background-color: #fff;
      color: #999; }
  .LoginWidget .LoginWidget-Social {
    margin-top: 1rem;
    margin-bottom: 0.75rem; }

.LoginWidget-SeparatorText {
  font-size: 1.25rem;
  color: #ccc;
  text-align: center;
  font-weight: 100;
  font-style: italic; }

.LoginWidget-Alternative {
  text-align: center; }

.thumbnail {
  position: relative;
  object-fit: contain; }
  .thumbnail .icon {
    position: absolute;
    top: 0px;
    right: 3px;
    width: 1.5rem;
    height: 1.5rem;
    fill: white;
    opacity: 0.8; }

/* Main Colour Variables scoped to :root */
:root {
  font-size: 16px;
  --primaryBg: #5591FF;
  --primaryFg: white;
  --primaryHover: #307AFF;
  --secondaryBg: #AFAFAF;
  --secondaryFg: white;
  --secondaryHover: #939393;
  --linkBg: transparent;
  --linkFg: var(--primaryBg);
  --linkHover: #EEF0F5;
  --errorBg: rgb(237, 159, 159);
  --errorFg: var(--primaryFg);
  --borderMutedColor: #ddd;
  --inputBorderColor: #eee;
  --inputRowLineColor: #ddd;
  --listItemHoverColor: rgba(0,0,0, 0.025);
  --shadow: 0px 1px 5px 0px rgba(0,0,0,0.25);
  --shadowMuted: 0px 1px 0px 0px rgba(0,0,0,0.1);
  --pageBg: white;
  --menuBg: rgba(255, 255, 255, 0.75);
  --backdropFill: rgba(0, 0, 0, 0.2);
  --textColor: black;
  --textIconColor: #666;
  --textMutedColor: #999;
  --textSubtleColor: #bbb;
  --textAccentedColor: var(--primaryBg);
  --textErrorColor: rgb(206, 88, 88);
  --iconFill: var(--secondaryBg);
  --iconInvertedFill: var(--secondaryFg);
  --iconHover: #c0d4f9;
  --iconActive: #8ab3ff;
  --iconMutedFill: #ccc;
  --iconDarkFill: black;
  --iconAccentedFill: var(--primaryBg);
  --iconWarningFill: var(--textErrorColor);
  --baseFont: Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  --headerFont: semi-bold;
  --textFont: regular;
  --baseFontSize: 1.4rem;
  --h1FontSizeEm: 2.0em;
  --h2FontSizeEm: 1.8em;
  --h3FontSizeEm: 1.6em;
  --padHoriz: 0.5rem;
  --padHorizNeg: -0.5rem;
  --padVert: 0.5rem;
  --padVertNeg: -0.5rem;
  --gapHoriz: 1rem;
  --gapHorizNeg: -1rem;
  --gapVert: 1rem;
  --gapVertNeg: -1rem;
  --colGap: 1.5rem;
  --colGapNeg: 1.5rem;
  --borderRadius: 0.4rem;
  --borderRadiusSmall: 2px;
  --maxHeaderWidth: 70rem;
  --maxBodyWidth: 60rem;
  --maxContentWidth: 50rem;
  --maxFormWidth: 40rem;
  --maxContentWidthNarrow: 30rem;
  --infernoUXHeaderHeight: 3rem;
  --infernoUXFooterHeight: 3.5rem;
  --imageTextBackgroundGradientHoriz: linear-gradient(90deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  --imageTextBackgroundGradientVert: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  /*
    Styling scope is written in PascalCase, such as components. Variants added with dash:
    .ThisComponent-VariantName

    Styling on classes in a component/scope is lowercase and short and should
    always be defined in a scope:

    .Component {
      .body {}
    }

  */
  font: var(--baseFont);
  /*
    App specific styling
  */
  --templateListPlaceholder: repeating-linear-gradient(0deg, var(--inputRowLineColor), var(--inputRowLineColor) 1.75em, var(--pageBg) 1.75em, var(--pageBg) 2em); }

body {
  font-family: var(--baseFont);
  font-weight: 100; }

h1,
h2,
h3 {
  font-weight: 300;
  text-align: center;
  margin: 0.5em 0; }

h4 {
  font-weight: 400; }

p {
  margin-bottom: 1em; }

strong {
  font-weight: 500; }

/* CONTENT STYLING AND TYPOGRAPHY */
.Article-Header_2,
.Article-Header_3,
.Article-Header_4 {
  line-height: 1.2em;
  margin-bottom: 0.5em;
  font-weight: bold; }

.Article-Header_2 {
  font-size: 1.6em; }

.Article-Header_3 {
  font-size: 1.3em; }

.Article-Header_4 {
  font-size: 1.3em;
  color: #444;
  margin-top: 3em; }

.Article-Paragraph {
  font-size: 1em;
  line-height: 1.5em;
  margin-bottom: 1em; }

.Article-Quote {
  display: block;
  font-size: 2rem;
  margin: 1em 0;
  padding-left: 1em;
  background: #f9f9f9;
  border-left: 1rem solid #ccc;
  quotes: "“" "”" "‘" "’"; }

.Article-Quote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0;
  margin-right: 0.1em;
  vertical-align: -0.45em; }

.Article-UnorderedList {
  font-size: 1em;
  line-height: 1.5em;
  margin: 1em 0; }

.Article-UnorderedList-Item {
  font-size: 1em;
  margin-bottom: 0.5em;
  list-style-type: circle;
  list-style-position: inside; }

.Mobiledoc b, .Mobiledoc strong {
  font-weight: 400; }

.LoginWidget #Login-Logo {
  max-width: 13rem;
  margin-left: 0.65rem; }

.LoginWidget .header,
.LoginWidget .footer {
  justify-content: center; }

.App > .content {
  height: 100vh; }

.Modal.MessageModal .content {
  margin-top: 3rem;
  max-width: 35rem;
  height: 20rem; }
  .Modal.MessageModal .content > .header {
    text-align: center;
    font-size: 1.25em;
    align-items: center;
    justify-content: center; }
  .Modal.MessageModal .content > .body {
    display: flex;
    flex-direction: column;
    padding: var(--padVert) var(--padHoriz);
    font-size: 1.1em;
    max-width: 30rem;
    align-self: center;
    justify-content: center;
    height: calc(20rem - var(--modalFooterHeight) - var(--modalHeaderHeight));
    margin-left: auto;
    margin-right: auto; }
  .Modal.MessageModal .content > .footer .ButtonBar {
    justify-content: center;
    margin-bottom: var(--padVert);
    width: 100%; }
    .Modal.MessageModal .content > .footer .ButtonBar button {
      padding: 1rem 2.5rem; }

.Fullpage {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden; }

.modal .Toolbar .Dropdown-Wrapper > .Button.link,
.EditModal .Toolbar .Dropdown-Wrapper > .Button.link,
.EditPage .Toolbar .Dropdown-Wrapper > .Button.link {
  color: var(--textIconColor); }

.ActionBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.95); }

.footer.ActionsToolbar {
  overflow: visible;
  width: 100%; }
  .footer.ActionsToolbar .Toolbar .status-btn {
    padding: 0;
    height: 2rem;
    width: 2rem; }

.IEditItem {
  background-color: var(--pageBg); }
  .IEditItem > .ButtonBar {
    max-width: var(--maxFormWidth);
    margin: 1rem auto;
    padding: 1rem 0; }

.ListItem {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  transition: all 0.1s ease-in; }
  .ListItem:hover {
    background-color: var(--listItemHoverColor); }
  .ListItem > .body {
    flex-grow: 1; }
    .ListItem > .body a {
      color: var(--textMutedColor); }
      .ListItem > .body a:hover {
        color: var(--textAccentedColor);
        background: none; }
    .ListItem > .body h3 {
      font-size: 0.75em;
      color: #aaa;
      text-align: left; }
      .ListItem > .body h3 i {
        font-style: normal;
        font-weight: 700; }
  .ListItem .workflow .Icon-Clock {
    vertical-align: -3px;
    width: 1em;
    height: 1em;
    margin-right: 0.25rem; }
  .ListItem .workflow .status {
    text-align: right;
    font-size: 1.5em;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--textSubtleColor);
    margin: 0; }
  .ListItem .workflow .actions {
    font-size: 0.75em; }
    .ListItem .workflow .actions button,
    .ListItem .workflow .actions button.selected,
    .ListItem .workflow .actions button:hover {
      line-height: 1em;
      border-radius: 0.25rem;
      padding: 0.5em 0.5em 0.25em; }
    .ListItem .workflow .actions button.selected,
    .ListItem .workflow .actions button.selected:hover {
      border-bottom: 3px solid var(--primaryBg); }

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  overflow: hidden; }

.ContactInfo ul {
  padding: 0; }

.ContactInfo li {
  list-style: none; }
