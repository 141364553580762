body {
  font-family: var(--baseFont);
  font-weight:  100;
}

h1,
h2,
h3 {
  font-weight: 300;
  text-align: center;
  margin: 0.5em 0;
}
h4 {
  font-weight: 400;
}

p {
  margin-bottom: 1em;
}

strong {
  font-weight: 500;
}

/* CONTENT STYLING AND TYPOGRAPHY */
.Article-Header_2,
.Article-Header_3,
.Article-Header_4 {
  line-height: 1.2em;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.Article-Header_2 {
  font-size: 1.6em;
}

.Article-Header_3 {
  font-size: 1.3em;
}
.Article-Header_4 {
  font-size: 1.3em;
  color: #444;
  margin-top: 3em;
}

.Article-Paragraph {
  font-size: 1em;
  line-height: 1.5em;
  margin-bottom: 1em;
}

.Article-Quote {
  display: block;
  font-size: 2rem;
  margin: 1em 0;
  padding-left: 1em;
  background: #f9f9f9;
  border-left: 1rem solid #ccc;
  quotes: "\201C""\201D""\2018""\2019";  
}

.Article-Quote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0;
  margin-right: 0.1em;
  vertical-align: -0.45em;
}

.Article-UnorderedList {
  font-size: 1em;
  line-height: 1.5em;
  margin: 1em 0;
}
.Article-UnorderedList-Item {
  font-size: 1em;
  margin-bottom: 0.5em;
  list-style-type: circle;
  list-style-position: inside;
}

.Mobiledoc {
  b, strong {
    font-weight: 400;
  }
}