.LivePage-Feed {
  max-width: var(--maxContentWidth);
  margin-left: auto;
  margin-right: auto;

  .InterPost {
    position: relative;
    width: 100%;
    height: 0;
    border-bottom: dashed 2px rgba(240,240,240,1);
    margin: 5rem 0;

    &::after {
      content: '';
      position: absolute;
      top: -1px;
      left: 50%;
      transform: translate(-50%, 0%);
      width: 3rem;
      height: 1.5rem;
      border-radius: 1.5rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(250,250,250,1) 65%, rgba(250,250,250,1) 100%);
      background: white;
      border: 3px dashed rgba(245,245,245,1);
      border-top: none;
    }

    .placeholder {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
    }
  }
}

.IPublicForm {
  padding-left: var(--padHoriz);
  padding-right: var(--padHoriz);

  .Gallery {
    &.one_two_horizontal,
    &.one_four_horizontal {
      margin-left: -4rem;
    }
  }

  @media (max-width: 576px) {
    .Gallery {
      margin-left: -var(--padHoriz);
      margin-right: -var(--padHoriz);

      &.one_two_horizontal,
      &.one_four_horizontal {
        margin-left: 0;
      }
    }  
  }
}