.CardPanel.Modal {

  & > .content {
    width: calc(100vw - 2rem)!important;
    height: auto;
    min-height: 0;
    max-width: var(--maxContentWidth);
    overflow-y: auto;
    margin: 2.1rem auto auto;
    padding: 0;
    border: 1px solid var(--borderMutedColor);
    box-shadow: var(--shadow);

    & > .header {
      border-bottom: none;
      padding: 1rem;
      margin-bottom: 1rem;
      
      h2 {
        font-size: 1.4em;
        font-weight: 400;
        margin: 0;
        line-height: 1em;
        color: var(--textMutedColor);
      }
    }
  
    & > .body {
      margin-top: 1.5rem;
      columns: 2;
      column-gap: 4rem;
      column-fill: auto;
      padding: 0 1.5rem;
  
      @media (max-width: 576px) {
        columns: 1;
      }
    }
  }


  .CardPanel-Group {
    display: inline-block;
    text-align: left;
    margin-bottom: 0.5rem;

    @media (max-width: 576px) {
      & > p {
        display: none;
      }
      
      & > h3 {
        display: none;
      }
    }

    & > h3 {
      font-size: 1em;
      margin-bottom: 1em;
      text-align: left;
      color: var(--iconAccentedFill);
      font-weight: 400;
    }

    & > p {
      margin-bottom: 1em;
      min-height: calc(1.25 * 3em);
    }

    .media {
      flex-direction: row;
      cursor: pointer;
      margin: -0.25rem -0.25rem 1.25rem;
      padding: 0.25rem;
      border-radius: 4px;
      min-height: 3.2rem;

      & > .body {
        width: 100%;
      }

      p {
        margin-bottom: 0;
      }

      &:hover {
        background-color: var(--primaryHover);
        color: var(--primaryFg);
      }
    }

    h4 {
      display: flex;
      font-size: 1em;
      font-weight: 300;
      margin-bottom: 0.25em;
    }

    p {
      font-size: 0.7em;
      line-height: 1.25em;
    }

    .arrow {
      width: 1.44em;
      height: 1.44em;
      margin: 0 0 0 auto;
      vertical-align: -0.35em;
    }

    svg {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
      margin-top: var(--padVert);

      
      color: var(--iconAccentedFill);
      fill: currentColor;
      stroke: currentColor;
      .inverse {
        fill: var(--pageBg);
      }
      .inverse-stroke {
        stroke: var(--pageBg);
      }
    } .media:hover {
      svg {
        color: var(--pageBg);
        .inverse {
          fill: var(--iconAccentedFill);
        }
        .inverse-stroke {
          stroke: var(--iconAccentedFill);
        }
      }
    }
  }
  
  .footer {
    padding: 0.5rem 1rem;
    text-align: left;
    background-color: #eee;
    display: flex;
    flex-direction: row;
    gap: var(--colGap);
    
    & > * {
      flex-grow: 1;
      flex-basis: 0;
    }
    
    @media (max-width: 576px) {
      display: none;
    }
    
    p {
      display: inline-block;
      font-size: 0.7em;
      line-height: 1.25em;
      margin: 0 0 0.5em;
    }
  }
}