.SectionPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: var(--infernoUXHeaderHeight);

  & > .section:first-child {
    margin-top: -3rem; // Why can't we use calc negative var(--infernoUXHeaderHeight) here?
  }
  
  & > * {
    width: 100%;
    max-width: var(--maxHeaderWidth);
    margin: 0 auto;
  }

  .filter-container {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .actionbar-container {
    position: fixed;
    z-index: 2;
    background-color: var(--stickyFooterBg);
    max-width: unset;
    width: calc(100% - var(--scrollbarWidth));
    
    .ButtonBar {
      margin: 0 auto;
    }
  }
}