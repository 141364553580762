@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600|Roboto:200,300,400,500);
@charset "UTF-8";
/* RESET https://dev.to/hankchizljaw/a-modern-css-reset-6p3 */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box; }

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0; }

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0; }

/* Set core body defaults */
body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5; }

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none; }

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto; }

/* Make images easier to work with */
img {
  width: 100%;
  display: block; }

/* Natural flow and rhythm in articles by default */
/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
  margin: 0; }

/* Remove outline on input elements in chrome */
input:focus, textarea:focus, select:focus {
  outline: none; }

/* /END RESET */
/* Custom fonts */
caption {
  display: block; }

body {
  overflow: hidden; }

/* Main Colour Variables scoped to :root */
:root {
  font-size: 16px;
  --primaryBg: #5591FF;
  --primaryFg: white;
  --primaryHover: #307AFF;
  --secondaryBg: #AFAFAF;
  --secondaryFg: white;
  --secondaryHover: #939393;
  --linkBg: transparent;
  --linkFg: var(--primaryBg);
  --linkHover: #EEF0F5;
  --errorBg: rgb(237, 159, 159);
  --errorFg: var(--primaryFg);
  --borderMutedColor: #ddd;
  --inputBorderColor: #eee;
  --inputRowLineColor: #ddd;
  --listItemHoverColor: rgba(0,0,0, 0.025);
  --shadow: 0px 1px 5px 0px rgba(0,0,0,0.25);
  --shadowMuted: 0px 1px 0px 0px rgba(0,0,0,0.1);
  --pageBg: white;
  --stickyHeaderBg: rgba(255,255,255,0.6);
  --stickyFooterBg: rgba(0,0,0,0.05);
  --backdropFill: rgba(0, 0, 0, 0.2);
  --textColor: black;
  --textIconColor: #666;
  --textMutedColor: #999;
  --textSubtleColor: #bbb;
  --textAccentedColor: var(--primaryBg);
  --textErrorColor: rgb(206, 88, 88);
  --iconFill: var(--secondaryBg);
  --iconMutedFill: #ccc;
  --iconDarkFill: black;
  --iconAccentedFill: var(--primaryBg);
  --iconWarningFill: var(--textErrorColor);
  --baseFont: "Helvetica Neue";
  --headerFont: semi-bold;
  --textFont: regular;
  --baseFontSize: 1.4rem;
  --h1FontSizeEm: 2.0em;
  --h2FontSizeEm: 1.8em;
  --h3FontSizeEm: 1.6em;
  --padHoriz: 0.5rem;
  --padHorizNeg: -0.5rem;
  --padVert: 0.5rem;
  --padVertNeg: -0.5rem;
  --gapHoriz: 1rem;
  --gapHorizNeg: -1rem;
  --gapVert: 0.5rem;
  --gapVertNeg: -0.5rem;
  --colGap: 1.5rem;
  --colGapNeg: 1.5rem;
  --borderRadius: 0.4rem;
  --borderRadiusSmall: 2px;
  --maxHeaderWidth: 70rem;
  --maxBodyWidth: 60rem;
  --maxContentWidth: 50rem;
  --maxFormWidth: 40rem;
  --maxContentWidthNarrow: 30rem;
  --headerHeight: 3rem;
  --footerHeight: 3.5rem;
  --formRowGap: 2rem;
  --imageTextBackgroundGradientHoriz: linear-gradient(90deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  --imageTextBackgroundGradientVert: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  --scrollbarWidth: 15px;
  /*
    Styling scope is written in PascalCase, such as components. Variants added with dash:
    .ThisComponent-VariantName

    Styling on classes in a component/scope is lowercase and short and should
    always be defined in a scope:

    .Component {
      .body {}
    }

  */
  font: Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font: var(--baseFont); }

.Button,
.ButtonClose,
.StateButton,
.NavStateButton,
.SwitchButton {
  --padHoriz: 1rem;
  --padVert: 0.75rem;
  --borderRadius: var(--borderRadiusSmall);
  display: -webkit-inline-flex;
  display: inline-flex;
  cursor: pointer;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font: Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font: var(--baseFont);
  font-weight: 400;
  text-align: center;
  border-radius: 0.4rem;
  border-radius: var(--borderRadius);
  padding: 0.5rem 0.5rem;
  padding: var(--padVert) var(--padHoriz);
  border: 0;
  line-height: 1em;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  transition: 0.1s ease-in; }
  .Button svg,
  .ButtonClose svg,
  .StateButton svg,
  .NavStateButton svg,
  .SwitchButton svg {
    /* Make sure icons fill their space but not more */
    width: 100%;
    height: 100%; }
  .Button, .Button:link, .Button:hover, .Button:visited,
  .ButtonClose,
  .ButtonClose:link,
  .ButtonClose:hover,
  .ButtonClose:visited,
  .StateButton,
  .StateButton:link,
  .StateButton:hover,
  .StateButton:visited,
  .NavStateButton,
  .NavStateButton:link,
  .NavStateButton:hover,
  .NavStateButton:visited,
  .SwitchButton,
  .SwitchButton:link,
  .SwitchButton:hover,
  .SwitchButton:visited {
    text-decoration: none; }
  .Button.link + i,
  i + .Button.link,
  .ButtonClose.link + i,
  i +
  .ButtonClose.link,
  .StateButton.link + i,
  i +
  .StateButton.link,
  .NavStateButton.link + i,
  i +
  .NavStateButton.link,
  .SwitchButton.link + i,
  i +
  .SwitchButton.link {
    margin-left: calc(0px - 0.5rem);
    margin-left: calc(0px - var(--padHoriz)); }

.Button.primary {
  background-color: #5591FF;
  background-color: var(--primaryBg);
  color: white;
  color: var(--primaryFg); }
  .Button.primary:hover {
    background-color: #307AFF;
    background-color: var(--primaryHover); }

.Button.secondary {
  background-color: #AFAFAF;
  background-color: var(--secondaryBg);
  color: white;
  color: var(--secondaryFg); }
  .Button.secondary:hover {
    background-color: #939393;
    background-color: var(--secondaryHover); }

.Button.link {
  background-color: transparent;
  background-color: var(--linkBg);
  color: #5591FF;
  color: var(--linkFg); }
  .Button.link:hover {
    background-color: #EEF0F5;
    background-color: var(--linkHover); }

.ButtonClose {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 0.5rem;
  padding-right: 1.5rem;
  grid-gap: 1rem;
  gap: 1rem;
  grid-gap: var(--gapHoriz);
  gap: var(--gapHoriz);
  background-color: transparent;
  background-color: var(--linkBg);
  color: #5591FF;
  color: var(--linkFg); }
  .ButtonClose:hover {
    background-color: #307AFF;
    background-color: var(--primaryHover);
    color: white;
    color: var(--primaryFg); }
    .ButtonClose:hover .icon {
      fill: white;
      fill: var(--primaryFg); }
  .ButtonClose .icon {
    fill: #5591FF;
    fill: var(--linkFg);
    height: 0.8rem; }

.ButtonBar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  grid-gap: var(--gapHoriz);
  gap: var(--gapHoriz); }
  .ButtonBar.inline {
    display: -webkit-inline-flex;
    display: inline-flex; }

.NavStateButton,
.StateButton {
  background-color: transparent;
  background-color: var(--linkBg);
  color: #5591FF;
  color: var(--linkFg);
  border-bottom: 3px solid transparent;
  border-radius: 0; }
  .NavStateButton:hover,
  .StateButton:hover {
    background-color: #EEF0F5;
    background-color: var(--linkHover);
    color: #5591FF;
    color: var(--linkFg); }
  .NavStateButton.isSelected,
  .StateButton.isSelected {
    border-bottom-color: #5591FF;
    border-bottom-color: var(--linkFg); }

.NavStateButton {
  text-decoration: none; }
  .NavStateButton:hover, .NavStateButton:active {
    text-decoration: none; }

.IconButton {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.5rem 0.5rem;
  padding: var(--padVert) var(--padHoriz);
  color: #ccc;
  color: var(--iconMutedFill);
  border: none;
  background: none;
  border-radius: 0.4rem;
  border-radius: var(--borderRadius);
  transition: 0.15s ease-out; }
  .IconButton:hover {
    color: #c0d4f9;
    color: var(--iconHover); }
  .IconButton:active {
    color: #8ab3ff;
    color: var(--iconActive); }
  .IconButton svg {
    width: 100%;
    height: 100%; }

.SwitchButton {
  --primaryBgLight: #65A7F4;
  --secondnaryBgLight: #65A7F4;
  --linkBgLight: #65A7F4;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  overflow: hidden; }
  .SwitchButton.notMounted .textDefault,
  .SwitchButton.notMounted .textSelected,
  .SwitchButton.notMounted .toggle,
  .SwitchButton.notMounted svg {
    transition: none; }
  .SwitchButton .textDefault {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    white-space: nowrap;
    max-width: 100%;
    padding-left: 30px;
    opacity: 1;
    transition: all 0.3s ease-in; }
  .SwitchButton .textSelected {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    white-space: nowrap;
    position: absolute;
    max-width: 0;
    opacity: 0;
    transition: all 0.3s ease-in;
    margin-right: 2em; }
  .SwitchButton .toggle,
  .SwitchButton svg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: auto;
    transition: all 0.2s ease-in; }
  .SwitchButton .toggle {
    width: 30px; }
  .SwitchButton.isSelected .textDefault {
    position: absolute;
    max-width: 0;
    padding-left: 0;
    opacity: 0; }
  .SwitchButton.isSelected .textSelected {
    position: relative;
    max-width: 100%;
    opacity: 1; }
  .SwitchButton.isSelected .toggle,
  .SwitchButton.isSelected svg {
    left: 100%;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  .SwitchButton.primary {
    background-color: #5591FF;
    background-color: var(--primaryBg);
    color: white;
    color: var(--primaryFg); }
    .SwitchButton.primary svg {
      color: var(--primaryBgLight); }
    .SwitchButton.primary .toggle {
      background-color: var(--primaryBgLight); }
    .SwitchButton.primary:hover {
      background-color: #307AFF;
      background-color: var(--primaryHover); }
  .SwitchButton.secondary {
    background-color: #AFAFAF;
    background-color: var(--secondaryBg);
    color: white;
    color: var(--secondaryFg); }
    .SwitchButton.secondary svg {
      color: var(--secondaryBgLight); }
    .SwitchButton.secondary .toggle {
      background-color: var(--secondaryBgLight); }
    .SwitchButton.secondary:hover {
      background-color: #939393;
      background-color: var(--secondaryHover); }
  .SwitchButton.link {
    background-color: transparent;
    background-color: var(--linkBg);
    color: #5591FF;
    color: var(--linkFg); }
    .SwitchButton.link svg {
      color: var(--linkBgLight); }
    .SwitchButton.link .toggle {
      background-color: var(--linkBgLight); }
    .SwitchButton.link:hover {
      background-color: #EEF0F5;
      background-color: var(--linkHover); }

.formlib-DraggableList {
  position: relative; }
  .formlib-DraggableList .formlib-Draggable {
    transition: all 0.1s ease-in; }
    .formlib-DraggableList .formlib-Draggable.source {
      opacity: 0.6; }
    .formlib-DraggableList .formlib-Draggable.insertBefore {
      margin-left: 3rem; }
    .formlib-DraggableList .formlib-Draggable.insertAfter {
      margin-right: calc(0.5rem + 3rem);
      margin-right: calc(var(--padHoriz) + 3rem); }
    .formlib-DraggableList .formlib-Draggable.hideSource {
      opacity: 0.2; }
  .formlib-DraggableList .debugMarkerLeft,
  .formlib-DraggableList .debugMarkerRight {
    position: absolute;
    top: 0;
    height: 1rem;
    width: 3px; }
  .formlib-DraggableList .debugMarkerLeft {
    background: red; }
  .formlib-DraggableList .debugMarkerRight {
    background: green; }
  .formlib-DraggableList .debugMarkerIndex {
    position: absolute;
    top: 1rem;
    left: 1rem;
    background-color: black;
    color: white; }
  .formlib-DraggableList .debugDragMarker {
    position: absolute;
    background-color: black;
    width: 3px;
    height: 100%;
    top: 0; }

.formlib-Draggable--Animation-enter {
  opacity: 0;
  width: 3rem;
  /* size of placeholder */ }

.formlib-Draggable--Animation-enter-active {
  transition: all 1.5s ease-out; }

.formlib-Draggable--Animation-enter-end {
  opacity: 1; }

.formlib-Draggable--Animation-leave {
  opacity: 1; }

.formlib-Draggable--Animation-leave-active {
  transition: all 1.1s ease-in; }

.formlib-Draggable--Animation-leave-end {
  opacity: 0;
  width: 0; }

.Dropdown-Wrapper {
  position: relative;
  line-height: 1; }

.Dropdown {
  --dropdownBg: var(--pageBg);
  --dropdownBorderColor: var(--borderMutedColor);
  --dropdownSelect: var(--linkHover);
  --dropdownHoverBg: var(--linkFg);
  --dropdownHoverFg: var(--dropdownBg);
  --dropdownPadVert: var(--padVert);
  --dropdownPadHoriz: var(--padHoriz);
  --dropdownSpacer: 0px;
  --dropdownBorderRadius: var(--borderRadiusSmall);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: absolute;
  background-color: var(--dropdownBg);
  border: 1px solid var(--dropdownBorderColor); }
  .Dropdown .item {
    border-width: 0;
    border-radius: 0;
    padding: var(--dropdownPadVert) var(--dropdownPadHoriz);
    background-color: var(--dropdownBg);
    cursor: pointer; }
    .Dropdown .item.isFocused {
      background-color: var(--dropdownSelect); }
    .Dropdown .item:hover {
      color: var(--dropdownHoverFg);
      background-color: var(--dropdownHoverBg); }
  .Dropdown button {
    text-align: left; }
  .Dropdown.placeBottomLeft {
    bottom: 0;
    left: 0;
    -webkit-transform: translate(0, calc(100% + var(--dropdownSpacer)));
            transform: translate(0, calc(100% + var(--dropdownSpacer))); }
  .Dropdown.placeBottomCenter {
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, calc(100% + var(--dropdownSpacer)));
            transform: translate(-50%, calc(100% + var(--dropdownSpacer))); }
  .Dropdown.placeBottomRight {
    bottom: 0;
    right: 0;
    -webkit-transform: translate(0, calc(100% + var(--dropdownSpacer)));
            transform: translate(0, calc(100% + var(--dropdownSpacer))); }
  .Dropdown.placeTopLeft {
    top: 0;
    left: 0;
    -webkit-transform: translate(0, calc(-100% - var(--dropdownSpacer)));
            transform: translate(0, calc(-100% - var(--dropdownSpacer)));
    border-top-left-radius: var(--dropdownBorderRadius);
    border-top-right-radius: var(--dropdownBorderRadius); }
  .Dropdown.placeTopCenter {
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, calc(-100% - var(--dropdownSpacer)));
            transform: translate(-50%, calc(-100% - var(--dropdownSpacer)));
    border-top-left-radius: var(--dropdownBorderRadius);
    border-top-right-radius: var(--dropdownBorderRadius); }
  .Dropdown.placeTopRight {
    top: 0;
    right: 0;
    -webkit-transform: translate(0, calc(-100% - var(--dropdownSpacer)));
            transform: translate(0, calc(-100% - var(--dropdownSpacer)));
    border-top-left-radius: var(--dropdownBorderRadius);
    border-top-right-radius: var(--dropdownBorderRadius); }
  .Dropdown.placeLeftCenter {
    top: 50%;
    left: 0;
    -webkit-transform: translate(calc(-100% - var(--dropdownSpacer)), -50%);
            transform: translate(calc(-100% - var(--dropdownSpacer)), -50%); }
  .Dropdown.placeRightCenter {
    top: 50%;
    right: 0;
    -webkit-transform: translate(calc(100% - var(--dropdownSpacer)), -50%);
            transform: translate(calc(100% - var(--dropdownSpacer)), -50%); }

/**
 * Animate rows and list rows
 */
.Dropdown--Animation-leave {
  opacity: 1; }

.Dropdown--Animation-leave-active {
  overflow: hidden;
  transition: height 0.1s ease-out, opacity 0.1s ease-in; }

.Dropdown--Animation-leave-end {
  height: 0;
  opacity: 0; }

.Dropdown--Animation-enter {
  height: 0;
  opacity: 0; }

.Dropdown--Animation-enter-active {
  transition: height 0.1s ease-out, opacity 0.1s ease-in; }

.Dropdown--Animation-enter-end {
  opacity: 1; }

.form-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.tab-page,
.page {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  max-height: -fill-available;
  overflow: hidden auto; }
  .tab-page > .header,
  .tab-page > .footer,
  .page > .header,
  .page > .footer {
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }
  .tab-page > .body,
  .page > .body {
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
    width: 100%; }

.PageAnimation-enter-active {
  transition: opacity 0.6s ease-out, -webkit-transform 0.5s ease-out;
  transition: opacity 0.6s ease-out, transform 0.5s ease-out;
  transition: opacity 0.6s ease-out, transform 0.5s ease-out, -webkit-transform 0.5s ease-out; }

.PageAnimation-leave-end, .PageAnimation-enter {
  opacity: 0;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.PageAnimation-leave, .PageAnimation-enter-end {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.PageAnimation-leave-active {
  transition: opacity 0.3s ease-in, -webkit-transform 0.3s ease-in;
  transition: opacity 0.3s ease-in, transform 0.3s ease-in;
  transition: opacity 0.3s ease-in, transform 0.3s ease-in, -webkit-transform 0.3s ease-in; }

.masonry {
  --gridGap: 0.5rem;
  display: grid;
  grid-gap: var(--gridGap);
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  white-space: nowrap; }
  .masonry.rendered {
    display: block;
    overflow-x: hidden;
    padding-right: 0; }
  .masonry .masonry-col {
    display: inline-block;
    vertical-align: top;
    margin-right: var(--gridGap); }
    .masonry .masonry-col:last-child {
      margin-right: 0; }
  .masonry .masonry-col > * {
    margin-bottom: var(--gridGap); }
    .masonry .masonry-col > *:last-child {
      margin-bottom: 0; }

.grid {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(15rem, 1fr));
  overflow-x: hidden; }

.media {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .media.horizontal {
    -webkit-flex-direction: row;
            flex-direction: row; }

.body {
  display: block;
  margin: 0 auto;
  padding: 0.5rem 0.5rem;
  padding: var(--padVert) var(--padHoriz); }

.section {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .section.has-columns {
    -webkit-flex-direction: row;
            flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
    grid-gap: var(--gapHoriz);
    gap: var(--gapHoriz); }
    .section.has-columns > * {
      -webkit-flex-grow: 1;
              flex-grow: 1;
      -webkit-flex-basis: 0;
              flex-basis: 0; }
    @media (max-width: 576px) {
      .section.has-columns {
        -webkit-flex-direction: column;
                flex-direction: column; }
        .section.has-columns .column {
          max-width: 100%;
          width: 100%; } }
  .section.sticky {
    position: -webkit-sticky;
    position: sticky; }

.footer {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 3.5rem;
  height: var(--footerHeight);
  transition: -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in; }
  .footer-sticky {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 1; }
    .footer-sticky .footer {
      background-color: white;
      /* TODO: Should be variable */
      pointer-events: all; }
  .footer.fixed {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: white;
    /* TODO: Should be variable */
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }
  .footer.hide {
    transition: -webkit-transform 0.4s ease-in;
    transition: transform 0.4s ease-in;
    transition: transform 0.4s ease-in, -webkit-transform 0.4s ease-in;
    -webkit-transform: translateY(100%) scaleY(0);
            transform: translateY(100%) scaleY(0); }

.header {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  transition: -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
  height: 3rem;
  height: var(--headerHeight); }
  .header-sticky {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 1; }
    .header-sticky .header {
      background-color: white;
      /* TODO: Should be variable */
      pointer-events: all; }
  .header.fixed {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    z-index: 1;
    background-color: white;
    /* TODO: Should be variable */
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }
  .header.hide {
    transition: -webkit-transform 0.5s ease-in;
    transition: transform 0.5s ease-in;
    transition: transform 0.5s ease-in, -webkit-transform 0.5s ease-in;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }

.trailing {
  margin-left: auto; }

.Filter {
  grid-gap: 1px;
  gap: 1px; }

.MessageCanvas {
  --canvasBg: var(--pageBg);
  --canvasFg: var(--iconMutedFill);
  --canvasPadVert: 2rem;
  --canvasPadHoriz: 2rem;
  --canvasArrowFill: var(--canvasFg);
  --canvasArrowSize: 2rem;
  --canvasArrowInnerSize: 1rem;
  --canvasArrowSpacer: 4px;
  display: -webkit-flex;
  display: flex;
  position: absolute;
  pointer-events: none;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  top: 0;
  left: 0;
  right: 0;
  height: 100%; }
  .MessageCanvas .body {
    display: -webkit-inline-flex;
    display: inline-flex;
    position: relative;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding: var(--canvasPadVert) var(--canvasPadHoriz);
    background-color: var(--canvasBg);
    border-radius: 0.4rem;
    border-radius: var(--borderRadius);
    color: var(--canvasFg);
    font-weight: 400; }
    .MessageCanvas .body h1, .MessageCanvas .body h2, .MessageCanvas .body h3, .MessageCanvas .body h4 {
      font-weight: 600; }
    .MessageCanvas .body *:last-child {
      margin-bottom: 0; }
  .MessageCanvas.arrowBottomCenter .body::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: var(--canvasArrowSize);
    height: var(--canvasArrowSize);
    -webkit-transform: translate(-50%, calc(100% + var(--canvasArrowSpacer)));
            transform: translate(-50%, calc(100% + var(--canvasArrowSpacer)));
    border-top: var(--canvasArrowInnerSize) solid var(--canvasArrowFill);
    border-right: var(--canvasArrowInnerSize) solid transparent;
    border-left: var(--canvasArrowInnerSize) solid transparent; }
  .MessageCanvas.arrowTopCenter .body::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: var(--canvasArrowSize);
    height: var(--canvasArrowSize);
    -webkit-transform: translate(-50%, calc(-100% - var(--canvasArrowSpacer)));
            transform: translate(-50%, calc(-100% - var(--canvasArrowSpacer)));
    border-bottom: var(--canvasArrowInnerSize) solid var(--canvasArrowFill);
    border-right: var(--canvasArrowInnerSize) solid transparent;
    border-left: var(--canvasArrowInnerSize) solid transparent; }
  .MessageCanvas.arrowLeftCenter .body::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: var(--canvasArrowSize);
    height: var(--canvasArrowSize);
    -webkit-transform: translate(calc(-100% - var(--canvasArrowSpacer)), -50%);
            transform: translate(calc(-100% - var(--canvasArrowSpacer)), -50%);
    border-right: var(--canvasArrowInnerSize) solid var(--canvasArrowFill);
    border-top: var(--canvasArrowInnerSize) solid transparent;
    border-bottom: var(--canvasArrowInnerSize) solid transparent; }
  .MessageCanvas.arrowRightCenter .body::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: var(--canvasArrowSize);
    height: var(--canvasArrowSize);
    -webkit-transform: translate(calc(100% - var(--canvasArrowSpacer)), -50%);
            transform: translate(calc(100% - var(--canvasArrowSpacer)), -50%);
    border-left: var(--canvasArrowInnerSize) solid var(--canvasArrowFill);
    border-top: var(--canvasArrowInnerSize) solid transparent;
    border-bottom: var(--canvasArrowInnerSize) solid transparent; }

:root {
  --transitionModalEnter: transform 0.4s ease-out, opacity 0.4s ease-in;
  --transitionModalLeave: transform 0.3s ease-in, opacity 0.2s ease-in;
  --transitionModalBackdropEnter: opacity 0.4s ease-in;
  --transitionModalBackdropLeave: opacity 0.4s ease-in;
  --modalHeaderHeight: 3.5rem;
  --modalFooterHeight: 3.5rem; }

.ModalSheet,
.Modal {
  position: fixed;
  display: -webkit-flex;
  display: flex;
  top: 0;
  width: 100vw;
  height: 100vh; }
  .ModalSheet > .content,
  .Modal > .content {
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-flex-direction: column;
            flex-direction: column;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    pointer-events: auto;
    background-color: white;
    background-color: var(--pageBg);
    background-clip: padding-box;
    border-radius: 0.4rem;
    border-radius: var(--borderRadius);
    outline: 0; }
    .ModalSheet > .content > .header,
    .Modal > .content > .header {
      border-bottom: 1px solid #ddd;
      border-bottom: 1px solid var(--borderMutedColor);
      padding: 0.5rem 0.5rem;
      padding: var(--padVert) var(--padHoriz);
      height: 3.5rem;
      height: var(--modalHeaderHeight);
      left: 0;
      right: 0; }
      .ModalSheet > .content > .header > .Toolbar,
      .ModalSheet > .content > .header > .ButtonBar,
      .Modal > .content > .header > .Toolbar,
      .Modal > .content > .header > .ButtonBar {
        max-width: 100%;
        margin-top: -0.5rem;
        margin-top: var(--padVertNeg); }
    .ModalSheet > .content > .body,
    .Modal > .content > .body {
      overflow: visible;
      width: 100%; }
    .ModalSheet > .content > .footer,
    .Modal > .content > .footer {
      height: 3.5rem;
      height: var(--modalFooterHeight); }
  .ModalSheet .backdrop,
  .Modal .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    background-color: var(--backdropFill); }

/*************************
** Modal Animations CSS **
**************************/
.Modal > .backdrop.fade {
  transition: opacity 0.4s ease-in;
  transition: var(--transitionModalBackdropLeave);
  opacity: 0; }

.Modal > .backdrop.show {
  transition: opacity 0.4s ease-in;
  transition: var(--transitionModalBackdropEnter);
  opacity: 1; }

.Modal > .content.fade {
  transition: opacity 0.2s ease-in, -webkit-transform 0.3s ease-in;
  transition: transform 0.3s ease-in, opacity 0.2s ease-in;
  transition: transform 0.3s ease-in, opacity 0.2s ease-in, -webkit-transform 0.3s ease-in;
  transition: var(--transitionModalLeave);
  opacity: 0.5;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.Modal > .content.show {
  transition: opacity 0.4s ease-in, -webkit-transform 0.4s ease-out;
  transition: transform 0.4s ease-out, opacity 0.4s ease-in;
  transition: transform 0.4s ease-out, opacity 0.4s ease-in, -webkit-transform 0.4s ease-out;
  transition: var(--transitionModalEnter);
  opacity: 1;
  -webkit-transform: unset;
          transform: unset; }

:root {
  --transitionModalSheetEnter: all 0.3s ease-in;
  --transitionModalSheetLeave: all 0.2s ease-in; }

.ModalSheet--Animation-enter-end, .ModalSheet--Animation-leave {
  opacity: 1; }

.ModalSheet--Animation-leave-active {
  transition: all 0.2s ease-in;
  transition: var(--transitionModalSheetLeave); }

.ModalSheet--Animation-enter-active {
  transition: all 0.3s ease-in;
  transition: var(--transitionModalSheetEnter); }

.ModalSheet--Animation-enter, .ModalSheet--Animation-leave-end {
  opacity: 0;
  height: 0; }

.NavFilter {
  grid-gap: 1px;
  gap: 1px; }

.formlib-UploadProgress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  background-color: var(--backdropFill);
  z-index: 1; }
  .formlib-UploadProgress .progress {
    position: absolute;
    top: 50%;
    left: 10%;
    right: 10%;
    height: 1rem;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: #ddd;
    background-color: var(--borderMutedColor);
    opacity: 0.9;
    overflow: hidden; }
    .formlib-UploadProgress .progress .bar {
      height: 100%;
      background-color: #5591FF;
      background-color: var(--iconAccentedFill);
      transition: width 0.3s ease-in-out;
      color: white; }
  .formlib-UploadProgress .body {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 10%;
    right: 10%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }

/*
  Sticky
*/
.sticky-vert {
  position: -webkit-sticky;
  position: sticky;
  top: 0; }
  .sticky-vert.bottom {
    top: auto;
    bottom: 0; }

.sticky-horiz {
  position: -webkit-sticky;
  position: sticky;
  left: 0; }
  .sticky-horiz.bottom {
    left: auto;
    right: 0; }

.Toolbar {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  max-width: 60rem;
  max-width: var(--maxBodyWidth);
  margin: 0 auto; }
  .Toolbar.has-shadow {
    box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.1);
    box-shadow: var(--shadowMuted);
    /* Shadow blocker */ }
    .Toolbar.has-shadow::before {
      content: '';
      position: absolute;
      z-index: -1;
      /* To avoid blocking content */
      top: 0;
      bottom: 0;
      left: -5px;
      right: -5px;
      background-color: white;
      background-color: var(--pageBg); }
  .Toolbar > .hidden {
    visibility: hidden; }
  .Toolbar > .trailing,
  .Toolbar > .leading {
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    min-width: 10rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    grid-gap: var(--gapHoriz);
    gap: var(--gapHoriz); }
  .Toolbar > .trailing {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin-left: auto; }
  .Toolbar > .body {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    /*  vert align */
    -webkit-justify-content: center;
            justify-content: center;
    /* horiz align */ }

.Toolbar.align-center .trailing,
.Toolbar.align-center .leading {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.Toolbar.align-center .leading {
  left: 0; }

.Toolbar.align-center .trailing {
  right: 0; }

/* Bootstrap overrides */
.invalid-feedback {
  display: block !important; }

/* DateField Widget */
.form-input-date button.unset {
  opacity: 1;
  margin-left: 0.5rem;
  color: red;
  cursor: pointer;
  -webkit-align-self: center;
          align-self: center;
  font-size: 0.75em; }
  .form-input-datetime.noValue .form-input-date button.unset {
    opacity: 0;
    margin-left: 0;
    width: 0; }

.form-input-date input {
  min-width: 5.5em; }

.form-input-date input:first-child {
  min-width: 6.5em; }

.form-input-date .Dropdown {
  --dropdownHoverBg: transparent;
  --dropdownHoverFg: inherit; }

.form-input-datetime {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
  grid-gap: var(--gapHoriz);
  gap: var(--gapHoriz);
  -webkit-align-items: center;
          align-items: center; }
  .form-input-datetime .form-input {
    width: 6.5em; }

.DateSelector .header .yearMonth {
  white-space: nowrap;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  text-align: center; }
  .DateSelector .header .yearMonth button {
    margin: 0;
    font-size: 1.5em; }

.DateSelector .footer {
  padding: 0.5rem;
  text-align: center;
  background-color: #f7f7f7;
  border-top: 1px solid #ebebeb; }

.DateSelector .Calendar thead {
  width: 100%;
  border-bottom: 1px solid #ddd;
  border-bottom: 1px solid var(--borderMutedColor); }
  .DateSelector .Calendar thead th {
    text-align: center;
    color: #aaa;
    padding-bottom: 0.5rem; }

.DateSelector .Calendar tbody tr:first-child td {
  padding-top: 0.5rem; }

.DateSelector .Calendar tbody td {
  text-align: center;
  color: #666; }
  .DateSelector .Calendar tbody td.text-muted button {
    color: #bbb !important; }
  .DateSelector .Calendar tbody td.today button {
    position: relative;
    border: 0;
    font-weight: 500;
    border-radius: 2px;
    border-radius: var(--borderRadiusSmall); }
    .DateSelector .Calendar tbody td.today button small {
      position: absolute;
      font-size: 8px;
      line-height: 12px;
      top: -2px;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      text-transform: uppercase;
      font-weight: 500; }
  .DateSelector .Calendar tbody td.selected button {
    background-color: #5591FF;
    background-color: var(--primaryBg);
    color: white;
    color: var(--primaryFg); }

.DateSelector .YearSelect {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
  grid-gap: var(--gapHoriz);
  gap: var(--gapHoriz); }
  .DateSelector .YearSelect ul {
    display: grid;
    grid: repeat(3, auto)/1fr 1fr 1fr;
    margin: 0;
    padding: 0; }
    .DateSelector .YearSelect ul li {
      padding: 0;
      margin: 0;
      list-style: none; }

.DateSelector .DateFieldItem:hover,
.DateSelector .DateFieldItem--selected {
  color: white !important;
  color: var(--primaryFg) !important;
  background-color: #5591FF;
  background-color: var(--primaryBg);
  border-radius: 2px;
  border-radius: var(--borderRadiusSmall); }

.DateSelector .nav-item {
  -webkit-flex: 0 1;
          flex: 0 1; }

.DateSelector .nav-link:hover {
  border-radius: 2px;
  border-radius: var(--borderRadiusSmall);
  color: white;
  color: var(--primaryFg);
  background-color: #5591FF;
  background-color: var(--primaryBg); }

.DateSelector .DateFieldMonth {
  -webkit-flex: 1 0;
          flex: 1 0;
  text-align: center; }

.DateSelector .DateField-YearSelect {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 1rem; }

.DateSelector .DateField-YearSelect-day {
  list-style: none;
  padding: 0.5rem;
  color: var(--primary);
  cursor: pointer; }

.DateSelector .DateField-YearSelect-minus,
.DateSelector .DateField-YearSelect-plus {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0.5rem; }
  .DateSelector .DateField-YearSelect-minus i,
  .DateSelector .DateField-YearSelect-plus i {
    -webkit-align-self: center;
            align-self: center;
    font-weight: bold;
    font-style: normal;
    color: var(--primary);
    cursor: pointer; }

.DateSelector .DateField-YearSelect-minus {
  left: 0; }

.DateSelector .DateField-YearSelect-plus {
  right: 0; }

/**
*  DateTimeWidget
*/
.input-group.form-input-datetime {
  display: block; }
  .input-group.form-input-datetime > .formlib-DateTime_date {
    display: inline-block;
    width: 7.5em;
    transition: all 0.15s linear; }
  .input-group.form-input-datetime--noValue > .formlib-DateTime_date {
    width: 13.5em !important;
    border-radius: 2px !important;
    border-radius: var(--borderRadiusSmall) !important; }
  .input-group.form-input-datetime > .formlib-DateTime_time {
    display: inline-block;
    width: 6em;
    transition: all 0.15s linear; }
  .input-group.form-input-datetime--noValue > .formlib-DateTime_time {
    width: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    border-width: 0 !important; }

/*
    ActionBar
*/
.ActionBar,
.formlib-ActionBar {
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
  width: 100%;
  border-top: 1px solid #eee;
  border-top: 1px solid var(--inputBorderColor); }
  .ActionBar .btn-success,
  .formlib-ActionBar .btn-success {
    margin-right: 1rem;
    color: var(--white); }

.formlib-StickyActionBar {
  position: fixed;
  left: 0;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.25);
  box-shadow: var(--shadow); }
  .formlib-StickyActionBar--hidden {
    display: none; }

/**
 * FileUploadWidget
 */
.DragNDroppable {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  cursor: pointer;
  transition: background-color 0.15s ease-in, color 0.15s ease-in; }
  .DragNDroppable.DragNDroppable--drag {
    background-color: rgba(0, 0, 0, 0.4);
    border-color: rgba(0, 0, 0, 0); }
  .DragNDroppable.DragNDroppable--drag-hover {
    background-color: rgba(0, 0, 0, 0.6);
    border-color: rgba(0, 0, 0, 0); }
  .DragNDroppable.DragNDroppable--drag .placeholder,
  .DragNDroppable.DragNDroppable--drag-hover .placeholder {
    color: white; }

.formlib-ErrorMsg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  background-color: var(--backdropFill); }
  .formlib-ErrorMsg .body {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 10%;
    right: 10%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .formlib-ErrorMsg .body p {
    font-size: 0.85em;
    color: white;
    margin-bottom: 0.6em; }

.formlib-FileUploadWidget .form-input-file {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: -1; }

.formlib-FileUploadWidget .placeholder {
  position: relative;
  margin: 0.5rem 1rem 0;
  font-size: 0.9em;
  color: #999;
  color: var(--textMutedColor);
  top: auto;
  left: auto;
  position: inherit;
  -webkit-transform: none;
          transform: none;
  width: 100%; }

/*
  ImageField
*/
.ImageField.card {
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  border: none;
  margin-bottom: 0;
  border-radius: 0; }
  .ImageField.card .card-img {
    border-radius: 0;
    overflow: hidden;
    object-fit: contain;
    max-height: 10rem;
    max-width: 30rem;
    width: auto;
    margin-right: auto !important; }
  .ImageField.card .card-title {
    font-weight: 100;
    font-size: 0.85em;
    margin: 0; }
  .ImageField.card .card-subtitle {
    margin: 0;
    margin-top: 0.2em; }
  .ImageField.card .card-text {
    font-size: 0.8em;
    margin: 0;
    margin-top: 0.2em; }
  .ImageField.card .card-body {
    padding: 0;
    height: 100%; }
  .ImageField.card .card-footer {
    padding: 0;
    background: none;
    border: none; }
  .ImageField.card .ImageField-Action {
    line-height: 0.8em;
    vertical-align: bottom; }

.form-input-object-field {
  display: block; }

.formlib-FormRows {
  width: 100%;
  margin: 0 auto; }
  .formlib-FormRows .form-row {
    max-width: 40rem;
    max-width: var(--maxFormWidth);
    margin: 0 auto 2rem;
    margin: 0 auto var(--formRowGap);
    -webkit-flex-grow: 1;
            flex-grow: 1; }
  .formlib-FormRows .form-input {
    font-weight: 200;
    font-size: 1em;
    padding: 0.1rem 0.5rem;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid #eee;
    border-bottom: 2px solid var(--inputBorderColor);
    width: 100%; }
    .formlib-FormRows .form-input.isNotValid {
      border-bottom: 2px solid rgb(206, 88, 88);
      border-bottom: 2px solid var(--textErrorColor); }
  .formlib-FormRows .form-input-textarea {
    font-size: 1.3em;
    line-height: 1.15em;
    min-height: 4em;
    background-image: linear-gradient(transparent, transparent calc(1.15em - 1px), #ddd 0px);
    background-image: linear-gradient(transparent, transparent calc(1.15em - 1px), var(--inputRowLineColor) 0px);
    background-size: 100% 1.15em;
    border-bottom: none; }
  .formlib-FormRows select.form-input {
    font-size: 1.3em; }
    .formlib-FormRows select.form-input option {
      font-size: 0.9em; }
  .formlib-FormRows .form-input-label {
    color: #999;
    color: var(--textMutedColor);
    font-weight: 400;
    font-size: 1em;
    margin-bottom: 0; }
  .formlib-FormRows .form-input-help {
    color: #999;
    color: var(--textMutedColor);
    font-weight: 200;
    font-size: 0.9em; }
  .formlib-FormRows .form-input-feedback {
    font-weight: 200;
    font-size: 0.9em; }
    .formlib-FormRows .form-input-feedback.isNotValid {
      color: rgb(206, 88, 88);
      color: var(--textErrorColor); }
  .formlib-FormRows .form-row-list > .form-input-label,
  .formlib-FormRows .form-row-object > .form-input-label {
    border-bottom: 1px solid #ddd;
    border-bottom: 1px solid var(--borderMutedColor);
    border-left: 5px solid #ddd;
    border-left: 5px solid var(--borderMutedColor);
    padding-left: 0.5em;
    display: block;
    font-size: 1.5em;
    font-weight: 200;
    margin: 1em 1rem 1em 0; }
  .formlib-FormRows .form-row-list > .form-input-feedback.isNotValid,
  .formlib-FormRows .form-row-object > .form-input-feedback.isNotValid {
    padding: 0.5rem 0.5rem;
    padding: var(--padVert) var(--padHoriz);
    border-radius: 2px;
    border-radius: var(--borderRadiusSmall);
    background-color: rgb(237, 159, 159);
    background-color: var(--errorBg);
    color: white;
    color: var(--errorFg);
    text-align: center; }
  .formlib-FormRows .form-row-object > .form-input-field > .form-input-object-field {
    border-left: 2px solid #ddd;
    border-left: 2px solid var(--borderMutedColor);
    padding-left: 1rem; }
  .formlib-FormRows .form-row-list .formlib-ListFieldRow {
    display: -webkit-flex;
    display: flex; }
    .formlib-FormRows .form-row-list .formlib-ListFieldRow .formlib-ListFieldRowDeleteBtn.btn-danger {
      background: none;
      color: rgb(206, 88, 88);
      color: var(--textErrorColor);
      height: 2.5em;
      border: none;
      -webkit-align-self: center;
              align-self: center; }
    .formlib-FormRows .form-row-list .formlib-ListFieldRow .formlib-ListFieldRowDeleteBtn:hover {
      background: rgb(237, 159, 159);
      background: var(--errorBg);
      color: white;
      color: var(--errorFg); }
    .formlib-FormRows .form-row-list .formlib-ListFieldRow > .form-row {
      -webkit-flex-grow: 1;
              flex-grow: 1; }
  .formlib-FormRows .form-input-label.checkbox {
    position: relative;
    padding-left: 2rem;
    line-height: 1em;
    text-align: left; }
    .formlib-FormRows .form-input-label.checkbox .form-input-checkbox {
      position: absolute;
      width: 1.2rem;
      height: 1.2rem;
      left: 0;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }

.formlib-DragItem {
  position: relative; }
  .formlib-DragItem .formlib-DragHandle {
    width: 1rem;
    height: 1rem;
    position: absolute;
    right: 1rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background: url(/static/media/drag_handle.c16e2443.svg);
    background-size: contain;
    cursor: -webkit-grab;
    cursor: grab; }

.form-row.IDateField .DateFieldSelector {
  width: 20rem; }

.form-row.IDateField .form-input-field > div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  grid-gap: var(--gapHoriz);
  gap: var(--gapHoriz); }
  .form-row.IDateField .form-input-field > div .form-input {
    width: 7em; }
  .form-row.IDateField .form-input-field > div > a {
    -webkit-flex-grow: 1;
            flex-grow: 1; }

/**
 * Animate error messages
 */
.formlib-ErrorMsg--Animation-leave {
  opacity: 1; }

.formlib-ErrorMsg--Animation-leave-active {
  overflow: hidden;
  transition: height 0.3s ease-out, opacity 0.2s ease-in; }

.formlib-ErrorMsg--Animation-leave-end {
  height: 0;
  opacity: 0; }

.formlib-ErrorMsg--Animation-enter {
  height: 0;
  opacity: 0; }

.formlib-ErrorMsg--Animation-enter-active {
  transition: height 0.2s ease-out, opacity 0.5s ease-in; }

.formlib-ErrorMsg--Animation-enter-end {
  opacity: 1; }

/**
 * Animate rows and list rows
 */
.formlib-ListFieldRow--Animation-leave,
.FormRow--Animation-leave {
  opacity: 1; }

.formlib-ListFieldRow--Animation-leave-active,
.FormRow--Animation-leave-active {
  overflow: hidden;
  transition: height 0.3s ease-out, opacity 0.2s ease-in; }

.formlib-ListFieldRow--Animation-leave-end,
.FormRow--Animation-leave-end {
  height: 0;
  opacity: 0; }

.formlib-ListFieldRow--Animation-enter,
.FormRow--Animation-enter {
  height: 0;
  opacity: 0; }

.formlib-ListFieldRow--Animation-enter-active,
.FormRow--Animation-enter-active {
  transition: height 0.2s ease-out, opacity 0.5s ease-in; }

.formlib-ListFieldRow--Animation-enter-end,
.FormRow--Animation-enter-end {
  opacity: 1; }

#icon_ok #Oval {
  fill: #74BA6F; }

#icon_ok #Combined-Shape {
  fill: #FFFFFF; }

.LoginWidget {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  justify-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  max-width: 25rem;
  text-align: left;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25); }
  .LoginWidget .header {
    padding: 0.5rem 1.5rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    font-size: 1.35em;
    color: #999;
    color: var(--textMutedColor);
    text-align: center; }
  .LoginWidget .body {
    width: 100%;
    padding: 1rem 1.5rem; }
  .LoginWidget .footer {
    padding: 1rem 1.5rem;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .LoginWidget .section {
    -webkit-align-items: center;
            align-items: center; }
  .LoginWidget #Login-Logo {
    position: absolute;
    left: 50%;
    top: -7.5rem;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .LoginWidget .row {
    -webkit-justify-content: center;
            justify-content: center; }
  .LoginWidget .card-footer {
    text-align: center; }
  .LoginWidget .LoginWidget-Form {
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 1rem; }
  .LoginWidget .LoginWidget-Separator {
    margin: 2rem 1rem;
    position: relative; }
    .LoginWidget .LoginWidget-Separator:before {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 0%;
      right: 0%;
      height: 0;
      border-bottom: 1px solid #ccc; }
    .LoginWidget .LoginWidget-Separator i {
      z-index: 0;
      padding: 0 1rem;
      background-color: #fff;
      color: #999; }
  .LoginWidget .LoginWidget-Social {
    margin-top: 1rem;
    margin-bottom: 0.75rem; }

.LoginWidget-SeparatorText {
  font-size: 1.25rem;
  color: #ccc;
  text-align: center;
  font-weight: 100;
  font-style: italic; }

.LoginWidget-Alternative {
  text-align: center; }

.thumbnail {
  position: relative;
  object-fit: contain; }
  .thumbnail .icon {
    position: absolute;
    top: 0px;
    right: 3px;
    width: 1.5rem;
    height: 1.5rem;
    fill: white;
    opacity: 0.8; }

/* Main Colour Variables scoped to :root */
:root {
  font-size: 16px;
  --primaryBg: #5591FF;
  --primaryFg: white;
  --primaryHover: #307AFF;
  --secondaryBg: #AFAFAF;
  --secondaryFg: white;
  --secondaryHover: #939393;
  --linkBg: transparent;
  --linkFg: var(--primaryBg);
  --linkHover: #EEF0F5;
  --errorBg: rgb(237, 159, 159);
  --errorFg: var(--primaryFg);
  --borderMutedColor: #ddd;
  --inputBorderColor: #eee;
  --inputRowLineColor: #ddd;
  --listItemHoverColor: rgba(0,0,0, 0.025);
  --shadow: 0px 1px 5px 0px rgba(0,0,0,0.25);
  --shadowMuted: 0px 1px 0px 0px rgba(0,0,0,0.1);
  --pageBg: white;
  --menuBg: rgba(255, 255, 255, 0.75);
  --backdropFill: rgba(0, 0, 0, 0.2);
  --textColor: black;
  --textIconColor: #666;
  --textMutedColor: #999;
  --textSubtleColor: #bbb;
  --textAccentedColor: var(--primaryBg);
  --textErrorColor: rgb(206, 88, 88);
  --iconFill: var(--secondaryBg);
  --iconInvertedFill: var(--secondaryFg);
  --iconHover: #c0d4f9;
  --iconActive: #8ab3ff;
  --iconMutedFill: #ccc;
  --iconDarkFill: black;
  --iconAccentedFill: var(--primaryBg);
  --iconWarningFill: var(--textErrorColor);
  --baseFont: Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  --headerFont: semi-bold;
  --textFont: regular;
  --baseFontSize: 1.4rem;
  --h1FontSizeEm: 2.0em;
  --h2FontSizeEm: 1.8em;
  --h3FontSizeEm: 1.6em;
  --padHoriz: 0.5rem;
  --padHorizNeg: -0.5rem;
  --padVert: 0.5rem;
  --padVertNeg: -0.5rem;
  --gapHoriz: 1rem;
  --gapHorizNeg: -1rem;
  --gapVert: 1rem;
  --gapVertNeg: -1rem;
  --colGap: 1.5rem;
  --colGapNeg: 1.5rem;
  --borderRadius: 0.4rem;
  --borderRadiusSmall: 2px;
  --maxHeaderWidth: 70rem;
  --maxBodyWidth: 60rem;
  --maxContentWidth: 50rem;
  --maxFormWidth: 40rem;
  --maxContentWidthNarrow: 30rem;
  --infernoUXHeaderHeight: 3rem;
  --infernoUXFooterHeight: 3.5rem;
  --imageTextBackgroundGradientHoriz: linear-gradient(90deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  --imageTextBackgroundGradientVert: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  /*
    Styling scope is written in PascalCase, such as components. Variants added with dash:
    .ThisComponent-VariantName

    Styling on classes in a component/scope is lowercase and short and should
    always be defined in a scope:

    .Component {
      .body {}
    }

  */
  font: Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font: var(--baseFont);
  /*
    App specific styling
  */
  --templateListPlaceholder: repeating-linear-gradient(0deg, var(--inputRowLineColor), var(--inputRowLineColor) 1.75em, var(--pageBg) 1.75em, var(--pageBg) 2em); }

body {
  font-family: Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-family: var(--baseFont);
  font-weight: 100; }

h1,
h2,
h3 {
  font-weight: 300;
  text-align: center;
  margin: 0.5em 0; }

h4 {
  font-weight: 400; }

p {
  margin-bottom: 1em; }

strong {
  font-weight: 500; }

/* CONTENT STYLING AND TYPOGRAPHY */
.Article-Header_2,
.Article-Header_3,
.Article-Header_4 {
  line-height: 1.2em;
  margin-bottom: 0.5em;
  font-weight: bold; }

.Article-Header_2 {
  font-size: 1.6em; }

.Article-Header_3 {
  font-size: 1.3em; }

.Article-Header_4 {
  font-size: 1.3em;
  color: #444;
  margin-top: 3em; }

.Article-Paragraph {
  font-size: 1em;
  line-height: 1.5em;
  margin-bottom: 1em; }

.Article-Quote {
  display: block;
  font-size: 2rem;
  margin: 1em 0;
  padding-left: 1em;
  background: #f9f9f9;
  border-left: 1rem solid #ccc;
  quotes: "\201C" "\201D" "\2018" "\2019"; }

.Article-Quote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0;
  margin-right: 0.1em;
  vertical-align: -0.45em; }

.Article-UnorderedList {
  font-size: 1em;
  line-height: 1.5em;
  margin: 1em 0; }

.Article-UnorderedList-Item {
  font-size: 1em;
  margin-bottom: 0.5em;
  list-style-type: circle;
  list-style-position: inside; }

.Mobiledoc b, .Mobiledoc strong {
  font-weight: 400; }

.LoginWidget #Login-Logo {
  max-width: 13rem;
  margin-left: 0.65rem; }

.LoginWidget .header,
.LoginWidget .footer {
  -webkit-justify-content: center;
          justify-content: center; }

.App > .content {
  height: 100vh; }

.Modal.MessageModal .content {
  margin-top: 3rem;
  max-width: 35rem;
  height: 20rem; }
  .Modal.MessageModal .content > .header {
    text-align: center;
    font-size: 1.25em;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .Modal.MessageModal .content > .body {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 0.5rem 0.5rem;
    padding: var(--padVert) var(--padHoriz);
    font-size: 1.1em;
    max-width: 30rem;
    -webkit-align-self: center;
            align-self: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: calc(20rem - 3.5rem - 3.5rem);
    height: calc(20rem - var(--modalFooterHeight) - var(--modalHeaderHeight));
    margin-left: auto;
    margin-right: auto; }
  .Modal.MessageModal .content > .footer .ButtonBar {
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 0.5rem;
    margin-bottom: var(--padVert);
    width: 100%; }
    .Modal.MessageModal .content > .footer .ButtonBar button {
      padding: 1rem 2.5rem; }

.Fullpage {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden; }

.modal .Toolbar .Dropdown-Wrapper > .Button.link,
.EditModal .Toolbar .Dropdown-Wrapper > .Button.link,
.EditPage .Toolbar .Dropdown-Wrapper > .Button.link {
  color: #666;
  color: var(--textIconColor); }

.ActionBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.95); }

.footer.ActionsToolbar {
  overflow: visible;
  width: 100%; }
  .footer.ActionsToolbar .Toolbar .status-btn {
    padding: 0;
    height: 2rem;
    width: 2rem; }

.IEditItem {
  background-color: white;
  background-color: var(--pageBg); }
  .IEditItem > .ButtonBar {
    max-width: 40rem;
    max-width: var(--maxFormWidth);
    margin: 1rem auto;
    padding: 1rem 0; }

.ListItem {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 1rem;
  transition: all 0.1s ease-in; }
  .ListItem:hover {
    background-color: rgba(0,0,0, 0.025);
    background-color: var(--listItemHoverColor); }
  .ListItem > .body {
    -webkit-flex-grow: 1;
            flex-grow: 1; }
    .ListItem > .body a {
      color: #999;
      color: var(--textMutedColor); }
      .ListItem > .body a:hover {
        color: #5591FF;
        color: var(--textAccentedColor);
        background: none; }
    .ListItem > .body h3 {
      font-size: 0.75em;
      color: #aaa;
      text-align: left; }
      .ListItem > .body h3 i {
        font-style: normal;
        font-weight: 700; }
  .ListItem .workflow .Icon-Clock {
    vertical-align: -3px;
    width: 1em;
    height: 1em;
    margin-right: 0.25rem; }
  .ListItem .workflow .status {
    text-align: right;
    font-size: 1.5em;
    font-weight: 600;
    text-transform: uppercase;
    color: #bbb;
    color: var(--textSubtleColor);
    margin: 0; }
  .ListItem .workflow .actions {
    font-size: 0.75em; }
    .ListItem .workflow .actions button,
    .ListItem .workflow .actions button.selected,
    .ListItem .workflow .actions button:hover {
      line-height: 1em;
      border-radius: 0.25rem;
      padding: 0.5em 0.5em 0.25em; }
    .ListItem .workflow .actions button.selected,
    .ListItem .workflow .actions button.selected:hover {
      border-bottom: 3px solid #5591FF;
      border-bottom: 3px solid var(--primaryBg); }

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  overflow: hidden; }

.ContactInfo ul {
  padding: 0; }

.ContactInfo li {
  list-style: none; }

.AvatarImageFieldWidget.formlib-FileUploadWidget {
  position: relative;
  display: block;
  border-radius: 50%; }
  .AvatarImageFieldWidget.formlib-FileUploadWidget .Onboarding-Avatar {
    margin: 0; }
  .AvatarImageFieldWidget.formlib-FileUploadWidget .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    grid-gap: var(--gapVert);
    gap: var(--gapVert);
    transform: translate(-50%, -75%); }
  .AvatarImageFieldWidget.formlib-FileUploadWidget button.change {
    opacity: 0.85; }
    .AvatarImageFieldWidget.formlib-FileUploadWidget button.change:hover {
      opacity: 1; }
  .AvatarImageFieldWidget.formlib-FileUploadWidget button.remove {
    color: white; }
    .AvatarImageFieldWidget.formlib-FileUploadWidget button.remove:hover {
      background-color: red; }
  .AvatarImageFieldWidget.formlib-FileUploadWidget .upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: 400; }
    .AvatarImageFieldWidget.formlib-FileUploadWidget .upload .IconButton {
      color: var(--iconInvertedFill); }
      .AvatarImageFieldWidget.formlib-FileUploadWidget .upload .IconButton:hover {
        color: var(--iconHover); }
      .AvatarImageFieldWidget.formlib-FileUploadWidget .upload .IconButton:active {
        color: var(--iconActive); }
    .AvatarImageFieldWidget.formlib-FileUploadWidget .upload .formlib-UploadProgress {
      overflow: hidden;
      border-radius: 50%; }

.ConnectedTitleFieldWidget {
  display: flex;
  flex-direction: row;
  grid-gap: var(--gapHoriz);
  gap: var(--gapHoriz);
  align-items: center; }
  .ConnectedTitleFieldWidget .form-input-help {
    flex-grow: 1; }
  .ConnectedTitleFieldWidget .SwitchButton {
    --colorSuccess: #74BA6F;
    --colorSuccessHover: #60a75b;
    --colorSuccessLight: #99d195;
    --borderRadius: 0.5rem;
    --padHoriz: 0.5rem;
    --padVert: 0.5rem;
    --primaryBg: #BBB;
    --primaryBgLight: #D1D1D1;
    margin-right: auto;
    width: 6.9rem;
    font-size: 0.8em;
    padding-left: var(--padHoriz);
    padding-right: var(--padHoriz);
    flex-grow: 0; }
    .ConnectedTitleFieldWidget .SwitchButton .toggle {
      width: 16px; }
    .ConnectedTitleFieldWidget .SwitchButton .textSelected {
      text-align: left; }
    .ConnectedTitleFieldWidget .SwitchButton.isSelected {
      background-color: var(--colorSuccess); }
      .ConnectedTitleFieldWidget .SwitchButton.isSelected svg {
        color: var(--colorSuccessLight); }
      .ConnectedTitleFieldWidget .SwitchButton.isSelected .toggle {
        background-color: var(--colorSuccessLight); }
      .ConnectedTitleFieldWidget .SwitchButton.isSelected:hover {
        background-color: var(--colorSuccessHover); }

.IConnectedTitleField label.form-input-label.checkbox {
  padding: 0; }

.EditModal.RecipeModal .ConnectedTitleFieldWidget .SwitchButton {
  --primaryBg: #5591FF;
  --primaryBgLight: #65A7F4;
  width: 6rem; }

.GalleryLayoutField .StateButton svg {
  color: #ccc;
  width: 3rem;
  height: 3rem; }

.GalleryLayoutField .StateButton:hover svg, .GalleryLayoutField .StateButton.isSelected {
  border-color: transparent; }
  .GalleryLayoutField .StateButton:hover svg svg, .GalleryLayoutField .StateButton.isSelected svg {
    color: var(--primaryBg); }

.formlib-AutoCompleteDropdown {
  display: none;
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
  margin-left: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  flex-direction: column-reverse; }
  .formlib-AutoCompleteDropdown.showTypeAhead {
    display: flex; }
  .formlib-AutoCompleteDropdown .formlib-AutoComplete-TypeAhead {
    pointer-events: none;
    /* This tag obscrues the intranet tag preventing it from being removed */
    padding: 0.1rem 0.5rem;
    position: absolute;
    top: 0;
    left: 0; }
  .formlib-AutoCompleteDropdown .formlib-AutoComplete-TypeAheadPrefix {
    visibility: hidden; }
  .formlib-AutoCompleteDropdown .formlib-AutoComplete-TypeAheadSuffix {
    color: #ccc; }

.formlib-AutocompleteItem {
  padding: 0.6em;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  text-align: left; }
  .formlib-AutocompleteItem:hover {
    background-color: #3aadde !important;
    color: white; }
    .formlib-AutocompleteItem:hover h3,
    .formlib-AutocompleteItem:hover h4 {
      color: white; }
  .formlib-AutocompleteItem:last-child {
    border-bottom: 0; }

.formlib-AutocompleteItem--Highlight {
  background-color: #2191c4;
  color: white; }

.formlib-AutosuggestItemThumbnail {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin: 0.2rem 0.5rem 0.2rem 0; }

.formlib-AutocompleteItem--Animation-leave {
  opacity: 1; }

.formlib-AutocompleteItem--Animation-leave-active {
  overflow: hidden;
  transition: height 0.3s ease-out, opacity 0.2s ease-in; }

.formlib-AutocompleteItem--Animation-leave-end {
  height: 0;
  opacity: 0; }

.formlib-AutocompleteItem--Animation-enter {
  height: 0;
  opacity: 0; }

.formlib-AutocompleteItem--Animation-enter-active {
  transition: height 0.2s ease-out, opacity 0.5s ease-in; }

.formlib-AutocompleteItem--Animation-enter-end {
  opacity: 1; }

#topSpots__Field input.form-input {
  width: 25em; }

#topSpots__Field .form-input-field {
  position: relative; }

#topSpots__Field .GeoPointField-static_map {
  position: absolute;
  width: 6em;
  right: 0;
  bottom: 0; }

.TimedPublishing {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem; }
  .TimedPublishing .Dropdown {
    --dropdownHoverBg: transparent;
    --dropdownHoverFg: inherit; }
  .TimedPublishing .status {
    color: var(--secondaryBg);
    font-size: 1em;
    text-align: right;
    font-weight: 600;
    text-transform: uppercase; }
  .TimedPublishing .Dropdown .header {
    font-size: 1.2em;
    margin-bottom: 1rem; }
  .TimedPublishing .Icon-Clock {
    vertical-align: -0.35em;
    color: var(--iconFill);
    fill: currentColor;
    stroke: currentColor; }
    .TimedPublishing .Icon-Clock.active {
      color: var(--iconAccentedFill); }

.GuideRow-Edit {
  display: flex;
  margin-left: -15px; }
  .GuideRow-Edit .btn {
    margin-left: 1rem; }
  .GuideRow-Edit .btn-outline-danger {
    border-color: white; }

.GuideRow.row {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border: 1px solid white;
  border-radius: 4px;
  cursor: pointer;
  margin-left: -30px; }
  .GuideRow.row:hover {
    background-color: var(--primaryBg); }
    .GuideRow.row:hover .status,
    .GuideRow.row:hover a {
      color: white; }
  .GuideRow.row .status {
    margin: 0; }
  .GuideRow.row .list-item-body a {
    cursor: text; }
    .GuideRow.row .list-item-body a:hover {
      text-decoration: none; }
  .GuideRow.row .list-item-body h3 {
    text-align: left;
    font-size: 0.65em;
    margin-bottom: -1em;
    line-height: 1; }

#content__Field .form-row {
  margin-bottom: 0.25rem; }

#content__Field .formlib-ListFieldActionBar {
  margin-top: 0.75rem; }

.Hero-Field.formlib-FileUploadWidget .buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1rem;
  left: 1rem;
  grid-gap: var(--gapVert);
  gap: var(--gapVert); }

.Hero-Field.formlib-FileUploadWidget button.change {
  opacity: 0.85; }
  .Hero-Field.formlib-FileUploadWidget button.change:hover {
    opacity: 1; }

.Hero-Field.formlib-FileUploadWidget button.remove {
  display: none;
  color: white; }
  .Hero-Field.formlib-FileUploadWidget button.remove:hover {
    background-color: red; }

.Hero-Field.formlib-FileUploadWidget .upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: 15rem;
  height: 10rem;
  color: var(--iconFill);
  font-weight: 400; }
  .Hero-Field.formlib-FileUploadWidget .upload .IconButton {
    color: var(--iconFill); }
    .Hero-Field.formlib-FileUploadWidget .upload .IconButton:hover {
      color: var(--iconHover); }
    .Hero-Field.formlib-FileUploadWidget .upload .IconButton:active {
      color: var(--iconActive); }


.ImageListField-InlineMediaLibrary {
  --modalFooterHeight: 10rem;
  height: calc(100vh - var(--modalFooterHeight)) !important;
  border-bottom: 1px solid var(--inputRowLineColor); }
  .ImageListField-InlineMediaLibrary .content {
    overflow-y: hidden; }

.MediaLibrary {
  position: relative;
  height: 100%;
  width: 100%; }
  .MediaLibrary .filter {
    position: absolute;
    z-index: 1;
    top: 0.3rem;
    right: 0.3rem;
    width: calc(20rem - 0.6rem); }
    .MediaLibrary .filter #search__Field {
      height: calc(1.7em + .75rem + 2px);
      padding: .375rem .75rem .375rem 1em;
      font-size: 1rem;
      font-weight: 300;
      line-height: 1.7;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: calc((1.7em + 0.75rem) / 2); }
  .MediaLibrary > .body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    padding-right: 0; }
    .MediaLibrary > .body .thumbnails {
      flex-grow: 1;
      height: 100%;
      overflow-y: scroll;
      position: relative;
      padding-bottom: var(--padVert);
      border-radius: 0;
      grid-gap: var(--gapVert) var(--gapHoriz);
      gap: var(--gapVert) var(--gapHoriz); }
    .MediaLibrary > .body .thumbnailDetails {
      width: 20rem;
      margin-top: 4rem;
      padding: var(--padVert) var(--padHoriz);
      overflow-y: auto; }
      .MediaLibrary > .body .thumbnailDetails h2 {
        font-weight: 300; }
      .MediaLibrary > .body .thumbnailDetails .Button,
      .MediaLibrary > .body .thumbnailDetails .info {
        margin-bottom: var(--formRowGap); }
  .MediaLibrary .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden; }
  .MediaLibrary .thumbnailDetails.no-selection {
    display: flex;
    flex-direction: column; }
    .MediaLibrary .thumbnailDetails.no-selection h2 {
      font-size: 1.3em; }
    .MediaLibrary .thumbnailDetails.no-selection .Button {
      align-self: center;
      margin-bottom: 1rem; }
    .MediaLibrary .thumbnailDetails.no-selection .info {
      text-align: center;
      font-size: 0.75em; }
  .MediaLibrary .list {
    position: relative;
    height: 100%; }
  .MediaLibrary .MessageCanvas.withOverlay {
    background-color: var(--backdropFill);
    z-index: 9; }
  .MediaLibrary .formlib-UploadProgress {
    --backdropFill: rgba(0,0,0,0.6); }
  .MediaLibrary .DragNDroppable {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.6s ease-in;
    border-radius: 0; }
    .MediaLibrary .DragNDroppable .text {
      display: none; }
    .MediaLibrary .DragNDroppable.DragNDroppable--drag {
      position: absolute;
      pointer-events: all;
      opacity: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.6); }
      .MediaLibrary .DragNDroppable.DragNDroppable--drag .text {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 400;
        color: white;
        font-size: 1.85em; }

.MediaLibrary .grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; }

.MediaLibrary .thumbnailDetails.no-selection {
  display: flex;
  flex-direction: column; }
  .MediaLibrary .thumbnailDetails.no-selection h2 {
    font-size: 1.3em; }
  .MediaLibrary .thumbnailDetails.no-selection .info {
    text-align: center;
    font-size: 0.75em; }

.MediaLibrary .list {
  position: relative;
  height: 100%; }

.MediaLibrary .MessageCanvas.withOverlay {
  background-color: var(--backdropFill);
  z-index: 9; }

.MediaLibrary .formlib-UploadProgress {
  --backdropFill: rgba(0,0,0,0.6); }

.MediaLibrary .DragNDroppable {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.6s ease-in;
  border-radius: 0; }
  .MediaLibrary .DragNDroppable .text {
    display: none; }
  .MediaLibrary .DragNDroppable.DragNDroppable--drag {
    position: absolute;
    pointer-events: all;
    opacity: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6); }
    .MediaLibrary .DragNDroppable.DragNDroppable--drag .text {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 400;
      color: white;
      font-size: 1.85em; }

.MediaSelectItem {
  position: relative;
  opacity: 0;
  transition: opacity 0.2s ease-in;
  width: 100%; }
  .MediaSelectItem .thumbnail {
    border-radius: 0; }
  .MediaSelectItem.loaded {
    opacity: 1; }
  .MediaSelectItem:hover {
    background-color: #ddd;
    cursor: pointer; }
  .MediaSelectItem > caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: var(--padVert) var(--padHoriz);
    font-size: 0.75rem;
    color: white;
    background: var(--backdropFill);
    text-align: center;
    line-height: 1.1em;
    font-weight: 400; }
  .MediaSelectItem .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 var(--padHoriz);
    font-size: 0.75rem;
    background: var(--imageTextBackgroundGradientHoriz); }
    .MediaSelectItem .footer caption {
      color: white;
      line-height: 1.1em;
      font-weight: 400; }

.MediaSelectItem.isType-Unsplash .footer img {
  border-radius: 50%;
  width: calc(1rem + 4px);
  height: calc(1rem + 4px);
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 3px;
  margin-right: 0.3rem; }

.MediaSelectItem.isType-Unsplash .footer a {
  color: white; }

.MediaSelectItem.isType-Unsplash .downloadOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.MediaSelectDetails {
  display: flex;
  flex-direction: column; }
  .MediaSelectDetails > .body {
    padding: 0.35rem;
    width: 100%; }
  .MediaSelectDetails > .footer {
    background: none;
    padding: 0.25rem;
    height: auto;
    justify-content: center; }
  .MediaSelectDetails .formlib-FormRows .form-row {
    margin-bottom: 0.5rem; }
  .MediaSelectDetails .formlib-FormRows .form-input-label {
    font-size: 0.6em;
    font-weight: 400; }
  .MediaSelectDetails .formlib-FormRows .form-input {
    border: none;
    padding: 0;
    line-height: 1.5;
    height: auto;
    font-size: 0.8em; }

.Modal.MediaLibrary {
  width: 100vw;
  height: 100vh; }
  .Modal.MediaLibrary > .content {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    width: calc(100% - 2rem);
    height: calc(100vh - 2rem);
    overflow: hidden;
    min-height: 0; }
    .Modal.MediaLibrary > .content > .header {
      padding-bottom: 0; }
    .Modal.MediaLibrary > .content > .body {
      flex-grow: 1;
      padding: 0;
      overflow: hidden; }
  .Modal.MediaLibrary .Toolbar {
    max-width: 100%; }

.MediaLibrary {
  position: relative;
  height: 100%;
  width: 100%; }
  .MediaLibrary .filter {
    position: absolute;
    z-index: 1;
    top: 0.3rem;
    right: 0.3rem;
    width: calc(20rem - 0.6rem); }
    .MediaLibrary .filter #search__Field {
      height: calc(1.7em + .75rem + 2px);
      padding: .375rem .75rem .375rem 1em;
      font-size: 1rem;
      font-weight: 300;
      line-height: 1.7;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: calc((1.7em + 0.75rem) / 2); }
  .MediaLibrary > .body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%; }
    .MediaLibrary > .body .thumbnails {
      flex-grow: 1;
      height: 100%;
      overflow-y: scroll;
      position: relative; }
    .MediaLibrary > .body .thumbnailDetails {
      width: 20rem;
      margin-top: 4rem;
      padding: var(--padVert) var(--padHoriz); }

.ModalMenu {
  margin: 0 auto;
  width: 100%;
  justify-content: center; }
  .isLive:not(.PullUp) .ModalMenu .NavStateButton {
    color: white !important; }
  .PullUp .ModalMenu {
    flex-grow: 1;
    flex-basis: auto;
    width: auto; }

.ImageField {
  height: 15rem; }

.ImageListField,
.ImageField {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  .ImageListField .image,
  .ImageField .image {
    position: relative;
    margin-right: auto;
    overflow: hidden; }
    .ImageListField .image img,
    .ImageField .image img {
      width: auto;
      max-width: 100%;
      max-height: 15rem; }
  .ImageListField .formlib-FileUploadWidget,
  .ImageField .formlib-FileUploadWidget {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; }
    .ImageListField .formlib-FileUploadWidget .DragNDroppable,
    .ImageField .formlib-FileUploadWidget .DragNDroppable {
      padding-bottom: 0; }
    .ImageListField .formlib-FileUploadWidget .Button.link,
    .ImageField .formlib-FileUploadWidget .Button.link {
      font-weight: 300; }
  .ImageListField .image .IconButton,
  .ImageField .image .IconButton {
    transition: 0.15s ease-out;
    opacity: 0.4;
    transform: scale(0.65);
    transform-origin: 75% 25%; }
  .ImageListField .image:hover .IconButton,
  .ImageField .image:hover .IconButton {
    opacity: 0.75; }
  .ImageListField .remove,
  .ImageField .remove {
    position: absolute;
    top: -1.5rem;
    right: -1.5rem;
    width: 6rem;
    height: 6rem;
    color: var(--iconDarkFill);
    border-radius: 0;
    border-bottom-left-radius: 50%;
    background: rgba(0, 0, 0, 0);
    transition: 0.15s ease-out; }
    .ImageListField .remove:hover,
    .ImageField .remove:hover {
      color: var(--iconWarningFill);
      background: rgba(0, 0, 0, 0.3);
      opacity: 1; }
    .ImageListField .remove svg,
    .ImageField .remove svg {
      width: 32px;
      height: 32px; }

.ImageField {
  height: 15rem; }

.ImageListField,
.ImageField {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  .ImageListField .image,
  .ImageField .image {
    position: relative;
    margin-right: auto;
    overflow: hidden; }
    .ImageListField .image img,
    .ImageField .image img {
      width: auto;
      max-width: 100%;
      max-height: 15rem; }
  .ImageListField .formlib-FileUploadWidget,
  .ImageField .formlib-FileUploadWidget {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; }
    .ImageListField .formlib-FileUploadWidget .DragNDroppable,
    .ImageField .formlib-FileUploadWidget .DragNDroppable {
      padding-bottom: 0; }
    .ImageListField .formlib-FileUploadWidget .Button.link,
    .ImageField .formlib-FileUploadWidget .Button.link {
      font-weight: 300; }
  .ImageListField .image .IconButton,
  .ImageField .image .IconButton {
    transition: 0.15s ease-out;
    opacity: 0.4;
    transform: scale(0.65);
    transform-origin: 75% 25%; }
  .ImageListField .image:hover .IconButton,
  .ImageField .image:hover .IconButton {
    opacity: 0.75; }
  .ImageListField .remove,
  .ImageField .remove {
    position: absolute;
    top: -1.5rem;
    right: -1.5rem;
    width: 6rem;
    height: 6rem;
    color: var(--iconDarkFill);
    border-radius: 0;
    border-bottom-left-radius: 50%;
    background: rgba(0, 0, 0, 0);
    transition: 0.15s ease-out; }
    .ImageListField .remove:hover,
    .ImageField .remove:hover {
      color: var(--iconWarningFill);
      background: rgba(0, 0, 0, 0.3);
      opacity: 1; }
    .ImageListField .remove svg,
    .ImageField .remove svg {
      width: 32px;
      height: 32px; }

.Modal.MediaLibrary {
  width: 100vw;
  height: 100vh; }
  .Modal.MediaLibrary > .content {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    width: calc(100% - 2rem);
    height: calc(100vh - 2rem);
    overflow: hidden;
    min-height: 0; }
    .Modal.MediaLibrary > .content > .header {
      padding-bottom: 0; }
    .Modal.MediaLibrary > .content > .body {
      flex-grow: 1;
      padding: 0;
      overflow: hidden; }
  .Modal.MediaLibrary .Toolbar {
    max-width: 100%; }

.MediaLibrary {
  position: relative;
  height: 100%;
  width: 100%; }
  .MediaLibrary .filter {
    position: absolute;
    z-index: 1;
    top: 0.3rem;
    right: 0.3rem;
    width: calc(20rem - 0.6rem); }
    .MediaLibrary .filter #search__Field {
      height: calc(1.7em + .75rem + 2px);
      padding: .375rem .75rem .375rem 1em;
      font-size: 1rem;
      font-weight: 300;
      line-height: 1.7;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: calc((1.7em + 0.75rem) / 2); }
  .MediaLibrary > .body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%; }
    .MediaLibrary > .body .thumbnails {
      flex-grow: 1;
      height: 100%;
      overflow-y: scroll;
      position: relative; }
    .MediaLibrary > .body .thumbnailDetails {
      width: 20rem;
      margin-top: 4rem;
      padding: var(--padVert) var(--padHoriz); }

.ModalMenu {
  margin: 0 auto;
  width: 100%;
  justify-content: center; }
  .isLive:not(.PullUp) .ModalMenu .NavStateButton {
    color: white !important; }
  .PullUp .ModalMenu {
    flex-grow: 1;
    flex-basis: auto;
    width: auto; }


.footer.ImageListFieldToolbar {
  height: 10rem; }

.ImageListField.DragNDroppable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 10rem;
  padding: 0 var(--padHoriz);
  border-radius: 0; }
  .ImageListField.DragNDroppable > .body {
    flex-grow: 1;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    overflow: auto hidden;
    height: 100%;
    transition: opacity 0.2s ease-in; }
    .ImageListField.DragNDroppable > .body > .slider {
      white-space: nowrap; }
      .ImageListField.DragNDroppable > .body > .slider > .image {
        display: inline-block;
        margin-right: var(--gapHoriz); }
        .ImageListField.DragNDroppable > .body > .slider > .image:last-child {
          margin-right: 0; }
        .ImageListField.DragNDroppable > .body > .slider > .image img {
          max-height: calc(10rem - var(--padVert) * 2); }
  .ImageListField.DragNDroppable > .footer {
    flex-shrink: 0;
    width: 15rem;
    transition: background-color 0.2s ease-in, opacity 0.2s ease-in; }
  .ImageListField.DragNDroppable .drop-message.MessageCanvas {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity 0.2s ease-in; }
    .ImageListField.DragNDroppable .drop-message.MessageCanvas .body {
      opacity: 1;
      background-color: transparent;
      color: white;
      font-size: 2em; }
  .ImageListField.DragNDroppable.DragNDroppable--drag > .body {
    opacity: 0.3;
    pointer-events: none; }
  .ImageListField.DragNDroppable.DragNDroppable--drag > .footer {
    background-color: transparent;
    opacity: 0.3;
    pointer-events: none; }
  .ImageListField.DragNDroppable.DragNDroppable--drag .drop-message.MessageCanvas {
    opacity: 0.6; }

.ImageListField-InlineMediaLibrary.ModalSheet {
  height: calc(100vh - 10rem); }
  .ImageListField-InlineMediaLibrary.ModalSheet > .content {
    min-height: 0;
    /* Allow animation */
    height: calc(100vh - 10rem);
    border-bottom: 2px solid var(--borderMutedColor); }
    .ImageListField-InlineMediaLibrary.ModalSheet > .content > .header {
      padding-bottom: 0;
      width: 100%;
      z-index: 2; }
    .ImageListField-InlineMediaLibrary.ModalSheet > .content .thumbnails {
      padding: var(--padVert) var(--padVert); }
  .ImageListField-InlineMediaLibrary.ModalSheet .formlib-UploadProgress,
  .ImageListField-InlineMediaLibrary.ModalSheet .DragNDroppable {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9; }

.Toolbar .btn {
  border-radius: 0 !important; }

.Toolbar .dropdown {
  margin: 0 0.5rem; }

.__mobiledoc-editor {
  min-height: 20vh;
  max-width: var(--maxContentWidth);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-top: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: var(--borderRadiusSmall);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  outline: none; }

@media (max-width: 576px) {
  .Toolbar.btn-group {
    overflow-x: scroll;
    max-width: 100%; }
  .Toolbar.btn-group > .btn {
    font-size: 0.75rem; } }

.__mobiledoc-card {
  display: block;
  margin-top: -1.8rem;
  margin-bottom: -1.5em;
  border: 1px dashed #e5e5e5; }

/* Typography */
.__mobiledoc-editor h1,
.Mobiledoc h1 {
  margin: 1em auto 0.5em; }

.__mobiledoc-editor h2,
.Mobiledoc h2 {
  font-size: 1.5rem;
  margin: 1em auto 0.5em; }

.__mobiledoc-editor h3,
.Mobiledoc h3 {
  font-size: 1.2rem;
  margin: 1em auto 0.5em; }

.__mobiledoc-editor p,
.Mobiledoc p {
  font-size: 1.1em;
  margin: 0 auto 1em;
  line-height: 1.85em;
  text-align: left; }

.__mobiledoc-editor blockquote,
.Mobiledoc blockquote {
  position: relative;
  font-size: 1.5em;
  max-width: 25rem;
  margin: 2em auto;
  text-align: left;
  padding-left: 0.5em;
  border-left: 3px solid var(--textSubtleColor);
  color: var(--textIconColor); }
  .__mobiledoc-editor blockquote::after,
  .Mobiledoc blockquote::after {
    content: '"';
    position: absolute;
    top: -0.1em;
    right: calc(100% + 0.175em);
    font-family: cursive;
    font-size: 5em;
    line-height: 1em;
    color: var(--primaryBg); }

.IBlogRelationField .form-input-field {
  display: flex;
  flex-direction: row;
  grid-gap: var(--gapHoriz);
  gap: var(--gapHoriz); }
  .IBlogRelationField .form-input-field .AutoCompleteDropdown {
    flex-grow: 1; }
  .IBlogRelationField .form-input-field .Button {
    flex-shrink: 0;
    color: var(--textErrorColor); }

.AutoCompleteDropdown {
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
  margin-left: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  flex-direction: column-reverse; }
  .AutoCompleteDropdown.showTypeAhead {
    display: flex; }
  .AutoCompleteDropdown .formlib-AutoComplete-TypeAhead {
    pointer-events: none;
    /* This tag obscrues the intranet tag preventing it from being removed */
    padding: 0.22em 0.5em 0.1rem;
    /* For some reason we need more padding than input. Border? */
    position: absolute;
    top: 0;
    left: 0; }
  .AutoCompleteDropdown .formlib-AutoComplete-TypeAheadPrefix {
    visibility: hidden; }
  .AutoCompleteDropdown .formlib-AutoComplete-TypeAheadSuffix {
    color: var(--textSubtleColor); }

.formlib-AutocompleteItem {
  padding: var(--padVert) var(--padHoriz);
  border-bottom: 1px solid var(--inputBorderColor);
  cursor: pointer;
  text-align: left; }
  .formlib-AutocompleteItem:hover {
    background-color: var(--primaryHover) !important;
    color: var(--primaryFg); }
    .formlib-AutocompleteItem:hover h3,
    .formlib-AutocompleteItem:hover h4 {
      color: var(--primaryFg); }
  .formlib-AutocompleteItem:last-child {
    border-bottom: 0; }

.formlib-AutocompleteItem--Highlight {
  background-color: var(--primaryBg);
  color: var(--primaryFg); }

.formlib-AutosuggestItemThumbnail {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin: var(--padVert) var(--padHoriz) var(--padVert) 0; }

.formlib-AutocompleteItem--Animation-leave {
  opacity: 1; }

.formlib-AutocompleteItem--Animation-leave-active {
  overflow: hidden;
  transition: height 0.3s ease-out, opacity 0.2s ease-in; }

.formlib-AutocompleteItem--Animation-leave-end {
  height: 0;
  opacity: 0; }

.formlib-AutocompleteItem--Animation-enter {
  height: 0;
  opacity: 0; }

.formlib-AutocompleteItem--Animation-enter-active {
  transition: height 0.2s ease-out, opacity 0.5s ease-in; }

.formlib-AutocompleteItem--Animation-enter-end {
  opacity: 1; }

.SearchWidget {
  position: relative; }
  .SearchWidget .input {
    padding-right: 2.5rem; }
  .SearchWidget .icon {
    width: auto;
    position: absolute;
    top: 0;
    right: 8px;
    height: 70%;
    margin-top: 0.3rem;
    color: #ddd;
    pointer-events: none;
    opacity: 0.4;
    fill: currentColor; }
  .search .SearchWidget .icon {
    opacity: 1; }


.WorkflowStatus {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  max-height: 100%; }
  .WorkflowStatus .Dropdown {
    --dropdownHoverBg: transparent;
    --dropdownHoverFg: inherit;
    min-width: 12rem; }
  .WorkflowStatus .status {
    color: var(--secondaryBg);
    font-size: 1.5em;
    text-align: right;
    font-weight: 600;
    text-transform: uppercase; }
  .WorkflowStatus .Button.status-btn {
    width: calc(var(--headerHeight) - 2 * var(--padVert) + 2 * var(--padHoriz));
    padding: 0.25rem; }
  .WorkflowStatus .Button {
    width: 100%;
    text-align: left; }
  .WorkflowStatus h3 {
    font-size: 0.85em;
    margin-bottom: 0; }
  .WorkflowStatus .Dropdown .header {
    font-size: 1.2em;
    margin-bottom: 1rem; }
  .WorkflowStatus .Icon-Arrow {
    color: var(--iconFill);
    fill: currentColor;
    stroke: currentColor;
    transform: rotate(-90deg); }
    .WorkflowStatus .Icon-Arrow.active {
      color: var(--iconAccentedFill); }

.Modal.ModalCloseWarning .content {
  margin-top: 3rem;
  max-width: 35rem;
  height: 20rem; }
  .Modal.ModalCloseWarning .content > .header {
    text-align: center;
    font-size: 1.25em; }
  .Modal.ModalCloseWarning .content > .body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: var(--padVert) var(--padHoriz);
    font-size: 1.1em;
    max-width: 30rem;
    align-self: center;
    justify-content: center;
    margin: 0 auto;
    height: calc(20rem - var(--influenceUxComponents-ModalFooterHeight) - var(--influenceUxComponents-ModalHeaderHeight)); }
  .Modal.ModalCloseWarning .content > .footer {
    padding: var(--padVert) var(--padHoriz); }

.Live-TimedPublishing-Banner {
  padding: 0.5rem;
  background-color: #f3dcce;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }
  .Live-TimedPublishing-Banner::before {
    content: '';
    width: 1.85em;
    height: 1.85em;
    transform: rotate(45deg) translate(-30%, 50%);
    position: absolute;
    bottom: -0.45em;
    left: 50%;
    border-bottom: 0.75em solid #f3dcce;
    border-right: .75em solid #f3dcce; }
  .Live-TimedPublishing-Banner h2 {
    font-size: 1.75em;
    margin-left: 1rem;
    margin-bottom: 0.2em;
    text-align: center;
    color: #c58660;
    font-weight: bold; }
  .Live-TimedPublishing-Banner h3 {
    font-size: 1em;
    margin-bottom: 0;
    text-align: center;
    z-index: 2; }
  .Live-TimedPublishing-Banner .Icon-Clock {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    width: 2em;
    height: 2em;
    margin-bottom: 0; }

.WorkflowStatus {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem; }
  .WorkflowStatus .Dropdown {
    --dropdownHoverBg: transparent;
    --dropdownHoverFg: inherit;
    min-width: 12rem; }
  .WorkflowStatus .status {
    color: var(--secondaryBg);
    font-size: 1.5em;
    text-align: right;
    font-weight: 600;
    text-transform: uppercase; }
  .WorkflowStatus .Button {
    width: 100%;
    text-align: left; }
  .WorkflowStatus h3 {
    font-size: 0.85em;
    margin-bottom: 0; }
  .WorkflowStatus .Dropdown .header {
    font-size: 1.2em;
    margin-bottom: 1rem; }
  .WorkflowStatus .Icon-Arrow {
    color: var(--iconFill);
    fill: currentColor;
    stroke: currentColor;
    transform: rotate(-90deg); }
    .WorkflowStatus .Icon-Arrow.active {
      color: var(--iconAccentedFill); }

.CardPanel.Modal > .content {
  width: calc(100vw - 2rem) !important;
  height: auto;
  min-height: 0;
  max-width: var(--maxContentWidth);
  overflow-y: auto;
  margin: 2.1rem auto auto;
  padding: 0;
  border: 1px solid var(--borderMutedColor);
  box-shadow: var(--shadow); }
  .CardPanel.Modal > .content > .header {
    border-bottom: none;
    padding: 1rem;
    margin-bottom: 1rem; }
    .CardPanel.Modal > .content > .header h2 {
      font-size: 1.4em;
      font-weight: 400;
      margin: 0;
      line-height: 1em;
      color: var(--textMutedColor); }
  .CardPanel.Modal > .content > .body {
    margin-top: 1.5rem;
    -moz-columns: 2;
         columns: 2;
    grid-column-gap: 4rem;
    -moz-column-gap: 4rem;
         column-gap: 4rem;
    -moz-column-fill: auto;
         column-fill: auto;
    padding: 0 1.5rem; }
    @media (max-width: 576px) {
      .CardPanel.Modal > .content > .body {
        -moz-columns: 1;
             columns: 1; } }

.CardPanel.Modal .CardPanel-Group {
  display: inline-block;
  text-align: left;
  margin-bottom: 0.5rem; }
  @media (max-width: 576px) {
    .CardPanel.Modal .CardPanel-Group > p {
      display: none; }
    .CardPanel.Modal .CardPanel-Group > h3 {
      display: none; } }
  .CardPanel.Modal .CardPanel-Group > h3 {
    font-size: 1em;
    margin-bottom: 1em;
    text-align: left;
    color: var(--iconAccentedFill);
    font-weight: 400; }
  .CardPanel.Modal .CardPanel-Group > p {
    margin-bottom: 1em;
    min-height: calc(1.25 * 3em); }
  .CardPanel.Modal .CardPanel-Group .media {
    flex-direction: row;
    cursor: pointer;
    margin: -0.25rem -0.25rem 1.25rem;
    padding: 0.25rem;
    border-radius: 4px;
    min-height: 3.2rem; }
    .CardPanel.Modal .CardPanel-Group .media > .body {
      width: 100%; }
    .CardPanel.Modal .CardPanel-Group .media p {
      margin-bottom: 0; }
    .CardPanel.Modal .CardPanel-Group .media:hover {
      background-color: var(--primaryHover);
      color: var(--primaryFg); }
  .CardPanel.Modal .CardPanel-Group h4 {
    display: flex;
    font-size: 1em;
    font-weight: 300;
    margin-bottom: 0.25em; }
  .CardPanel.Modal .CardPanel-Group p {
    font-size: 0.7em;
    line-height: 1.25em; }
  .CardPanel.Modal .CardPanel-Group .arrow {
    width: 1.44em;
    height: 1.44em;
    margin: 0 0 0 auto;
    vertical-align: -0.35em; }
  .CardPanel.Modal .CardPanel-Group svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    margin-top: var(--padVert);
    color: var(--iconAccentedFill);
    fill: currentColor;
    stroke: currentColor; }
    .CardPanel.Modal .CardPanel-Group svg .inverse {
      fill: var(--pageBg); }
    .CardPanel.Modal .CardPanel-Group svg .inverse-stroke {
      stroke: var(--pageBg); }
  .CardPanel.Modal .CardPanel-Group .media:hover svg {
    color: var(--pageBg); }
    .CardPanel.Modal .CardPanel-Group .media:hover svg .inverse {
      fill: var(--iconAccentedFill); }
    .CardPanel.Modal .CardPanel-Group .media:hover svg .inverse-stroke {
      stroke: var(--iconAccentedFill); }

.CardPanel.Modal .footer {
  padding: 0.5rem 1rem;
  text-align: left;
  background-color: #eee;
  display: flex;
  flex-direction: row;
  grid-gap: var(--colGap);
  gap: var(--colGap); }
  .CardPanel.Modal .footer > * {
    flex-grow: 1;
    flex-basis: 0; }
  @media (max-width: 576px) {
    .CardPanel.Modal .footer {
      display: none; } }
  .CardPanel.Modal .footer p {
    display: inline-block;
    font-size: 0.7em;
    line-height: 1.25em;
    margin: 0 0 0.5em; }

.list-item.card.media {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.5rem;
  border: 0 !important;
  opacity: 0;
  transition: opacity 0.5s ease-in;
  margin-bottom: 1rem; }
  .list-item.card.media.loaded {
    opacity: 1; }
  .list-item.card.media .masonry-item {
    position: relative;
    overflow: hidden;
    width: 100%; }
    .list-item.card.media .masonry-item .media-body {
      max-height: 4em; }
    .list-item.card.media .masonry-item caption {
      text-align: center;
      color: white;
      text-shadow: rgba(0, 0, 0, 0.6) 2px 2px 4px; }
    .list-item.card.media .masonry-item .thumbnail {
      -o-object-fit: contain !important;
         object-fit: contain !important;
      height: 100%;
      border-radius: 0;
      border-radius: 2px;
      margin: 0 auto;
      max-height: -webkit-fit-content;
      max-height: -moz-fit-content;
      max-height: fit-content; }

.MediaCard.isTemplate > .Gallery {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 56%;
  background-color: var(--inputRowLineColor); }
  .MediaCard.isTemplate > .Gallery:after {
    content: 'Media';
    font-size: 2em;
    font-weight: bold;
    opacity: 0.15;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.MediaCard.isTemplate > .body {
  pointer-events: none; }
  .MediaCard.isTemplate > .body caption {
    width: 20em;
    height: 2em;
    margin: 0.75rem 0;
    background-color: var(--inputRowLineColor); }

.MediaCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .MediaCard img {
    width: 100%;
    vertical-align: top; }
  .MediaCard .ImageField.card {
    flex-direction: row; }
  .MediaCard .card-img {
    width: 100%; }
  .MediaCard .body {
    width: 100%; }
  .MediaCard caption {
    font-size: 1em;
    margin-bottom: 1rem;
    text-align: center; }

/* Chrome for editing modal, should be refactored into a single place */
.MediaCardModal .ImageField.card {
  flex-direction: column; }

.MediaCardModal .card-img {
  margin-right: 0;
  height: 10rem;
  width: auto;
  margin-right: auto; }

.MediaCardModal .card-body {
  width: 100%; }

.MediaCardModal .card-footer {
  text-align: left; }

.MediaCardModal .ImageField-Action {
  display: inline-block;
  padding: 0.5rem; }
  .MediaCardModal .ImageField-Action:hover {
    background-color: rgba(255, 255, 255, 0.6); }

.VideoCard.isTemplate > .video-player {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 56%;
  background-color: var(--inputRowLineColor); }
  .VideoCard.isTemplate > .video-player:after {
    content: 'Video';
    font-size: 2em;
    font-weight: bold;
    opacity: 0.15;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.VideoCard.isTemplate > .body {
  pointer-events: none; }
  .VideoCard.isTemplate > .body caption {
    width: 20em;
    height: 2em;
    margin: 0.75rem 0;
    background-color: var(--inputRowLineColor); }

.VideoCard {
  position: relative; }
  .VideoCard .video-player {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.3%; }
    .VideoCard .video-player .poster-image {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      opacity: 0.95;
      transition: opacity 0.5s ease-in; }
      .VideoCard .video-player .poster-image img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover; }
    .VideoCard .video-player.video-playing .poster-image {
      pointer-events: none;
      opacity: 0; }
    .VideoCard .video-player .video-play {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%) scale(1);
      color: #fff;
      pointer-events: none; }
      .VideoCard .video-player .video-play #OvalShadow {
        opacity: 0;
        transition: opacity 0.1s ease-in; }
    .VideoCard .video-player:hover .video-play {
      transform: translate(-50%, -50%) scale(1.04); }
      .VideoCard .video-player:hover .video-play #OvalShadow {
        opacity: 0.4; }
    .VideoCard .video-player .video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }

.Icon-Clock,
.Icon-People {
  vertical-align: text-bottom; }

/* Edit Viwe */
.RecipeModal .RecipeCard .Gallery {
  max-width: 100% !important;
  margin-left: 0 !important; }

.RecipeModal #ingredients__Field .form-input-object-field {
  display: flex;
  justify-content: space-between; }
  .RecipeModal #ingredients__Field .form-input-object-field .form-row input {
    border-top: none;
    border-right: none;
    border-radius: 0; }
  .RecipeModal #ingredients__Field .form-input-object-field .form-row:nth-child(1) {
    max-width: 4rem; }

.RecipeModal #steps__Field .form-input-object-field .form-row:nth-child(2) input {
  max-width: 4rem; }

.RecipeCard.isTemplate > h2 {
  display: block;
  width: 15em;
  height: 1.5em;
  background-color: var(--inputRowLineColor); }

.RecipeCard.isTemplate > .Gallery {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 56%;
  background-color: var(--inputRowLineColor); }
  .RecipeCard.isTemplate > .Gallery:after {
    content: 'Recipe';
    font-size: 2em;
    font-weight: bold;
    opacity: 0.15;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.RecipeCard.isTemplate > .body {
  pointer-events: none; }
  .RecipeCard.isTemplate > .body caption {
    width: 20em;
    height: 2em;
    margin: 0.75rem 0;
    background-image: var(--templateListPlaceholder); }
  .RecipeCard.isTemplate > .body .ingredients ul {
    display: block;
    height: 10em;
    background-image: var(--templateListPlaceholder); }
  .RecipeCard.isTemplate > .body Button {
    background-color: var(--inputRowLineColor); }

.RecipeCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .RecipeCard caption {
    font-size: 1em;
    margin-bottom: 1rem; }
  .RecipeCard h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 1em; }
  .RecipeCard .body {
    width: 100%; }
  @media (max-width: 576px) {
    .RecipeCard .section {
      flex-direction: column-reverse; } }
  .RecipeCard .ingredients {
    display: flex;
    flex-grow: 1;
    flex-direction: column; }
    @media (max-width: 576px) {
      .RecipeCard .ingredients {
        flex-direction: column-reverse; } }
    .RecipeCard .ingredients h2 {
      margin: 0.5rem 0;
      font-size: 1em;
      font-weight: 500;
      text-align: left;
      color: var(--textAccentedColor); }
    .RecipeCard .ingredients ul {
      padding-left: 0;
      font-size: 0.9em;
      font-family: adobe caslon pro;
      margin-bottom: 0; }
    .RecipeCard .ingredients li {
      list-style: none;
      font-weight: 100;
      text-transform: lowercase;
      margin-bottom: 0.5em; }
  .RecipeCard .info h3 {
    flex-grow: 0;
    width: auto;
    text-align: left;
    font-size: 1em;
    color: var(--textAccentedColor);
    margin-bottom: 1rem;
    white-space: nowrap; }
    .RecipeCard .info h3 .Icon-People,
    .RecipeCard .info h3 .Icon-Clock {
      color: var(--iconAccentedFill);
      display: inline-block;
      width: 1.5rem;
      height: auto;
      margin-right: 0.25rem; }
  .RecipeCard .info i {
    position: relative;
    display: flex;
    align-content: center;
    margin-top: -0.5em;
    margin-bottom: 1em;
    color: var(--primaryBg); }
  .RecipeCard .steps {
    display: flex;
    flex-direction: column;
    justify-items: left;
    flex-grow: 1;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
    .RecipeCard .steps button {
      margin-bottom: 1rem; }
    .RecipeCard .steps > p {
      margin-bottom: 1em; }
    .RecipeCard .steps ol {
      margin: 0;
      padding-left: 1.1rem; }

.CookingTimer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  color: var(--primaryBg);
  font-family: Adobe Caslon Pro;
  font-size: 1.25em;
  line-height: 1em;
  padding: 0 1rem;
  border-radius: 1.8rem;
  height: 3.6rem;
  background-color: white;
  border: 2px solid var(--primaryBg); }
  .CookingTimer .Icon-Clock {
    color: var(--primaryBg);
    width: 2rem;
    margin-right: 1rem;
    vertical-align: sub; }
  .CookingTimer i {
    margin-top: 0.1em; }
  .CookingTimer .Icon-Pause {
    border-right: 5px solid var(--primaryBg);
    border-left: 5px solid var(--primaryBg);
    height: 1.4rem;
    width: 1rem;
    margin-left: 1.3rem;
    margin-right: 0.2rem; }
  .CookingTimer .Icon-Play {
    border: 0.9rem solid transparent;
    border-left-color: var(--primaryBg);
    transform: scale(1.3, 1);
    height: 0;
    width: 0;
    margin-left: 1.3rem;
    margin-right: -0.8rem; }
  .CookingTimer .Button {
    margin-right: -0.6rem;
    border-radius: 1.5rem;
    max-width: 100%;
    transition: all 0.2s ease-out; }
    .CookingTimer .Button.hidden {
      max-width: 0;
      opacity: 0;
      overflow: hidden;
      margin: 0 0.3rem 0 0;
      padding: 0; }

.Gallery {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end; }
  .Gallery .image,
  .Gallery img {
    cursor: pointer;
    -o-object-fit: cover;
       object-fit: cover;
    position: relative; }
    .Gallery .image i,
    .Gallery img i {
      content: attr(data-show-more);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: 'Times New Roman', Times, serif;
      font-weight: 400;
      font-size: 4em;
      color: white;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); }
  .Gallery .image {
    display: flex;
    overflow: hidden; }
    .Gallery .image img {
      height: 100%; }
    .Gallery .image i {
      pointer-events: none; }
  .Gallery.cover {
    align-items: unset; }
    .Gallery.cover img {
      -o-object-fit: cover;
         object-fit: cover; }
  .Gallery.one .image {
    max-height: 90vh;
    width: 100%; }
    .Gallery.one .image img {
      -o-object-fit: contain;
         object-fit: contain;
      max-height: 90vh; }
    .Gallery.one .image .placeholder {
      min-height: 20rem; }
  .Gallery.two .image:nth-child(1) {
    width: calc(50% - 1px);
    margin-right: 1px; }
  .Gallery.two .image:nth-child(2) {
    width: calc(50% - 1px);
    margin-left: 1px; }
  .Gallery.two .placeholder {
    min-height: 20rem; }
  .Gallery.one_two {
    grid-gap: 2px;
    gap: 2px; }
    .Gallery.one_two .image:nth-child(1) {
      width: 100%; }
      .Gallery.one_two .image:nth-child(1) .placeholder {
        min-height: 16rem;
        max-height: 35vh; }
    .Gallery.one_two .image:nth-child(2),
    .Gallery.one_two .image:nth-child(3) {
      width: calc(50% - 1px);
      max-height: 25vh; }
      .Gallery.one_two .image:nth-child(2) .placeholder,
      .Gallery.one_two .image:nth-child(3) .placeholder {
        min-height: 10rem;
        max-height: 35vh; }
  .Gallery.one_three .image:nth-child(1) {
    width: calc(100% - 1px);
    margin-bottom: 2px; }
    .Gallery.one_three .image:nth-child(1) .placeholder {
      min-height: 16rem;
      max-height: 35vh; }
  .Gallery.one_three .image:nth-child(2),
  .Gallery.one_three .image:nth-child(3),
  .Gallery.one_three .image:nth-child(4) {
    width: calc(33.33% - 1px);
    max-height: 25vh; }
    .Gallery.one_three .image:nth-child(2) .placeholder,
    .Gallery.one_three .image:nth-child(3) .placeholder,
    .Gallery.one_three .image:nth-child(4) .placeholder {
      min-height: 10rem;
      max-height: 35vh; }
  .Gallery.one_three .image:nth-child(3) {
    width: calc(33.33% - 2px);
    margin: 0 2px; }
  .Gallery.one_two_horizontal {
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 50% 50%;
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    width: calc(100% + 4rem);
    margin-left: -2rem;
    max-width: 100vw; }
    .Gallery.one_two_horizontal .image:nth-child(1) {
      grid-row: 1 / -1;
      max-height: 100%; }
      .Gallery.one_two_horizontal .image:nth-child(1) img {
        max-height: 100%; }
      .Gallery.one_two_horizontal .image:nth-child(1) .placeholder {
        min-height: 26rem; }
  .Gallery.one_four_horizontal {
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 40% 30% 30%;
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    width: calc(100% + 8rem);
    margin-left: -4rem;
    max-width: 100vw; }
    .Gallery.one_four_horizontal .image:nth-child(1) {
      grid-row: 1 / -1;
      max-height: 100%; }
      .Gallery.one_four_horizontal .image:nth-child(1) .placeholder {
        min-height: 26rem; }
      .Gallery.one_four_horizontal .image:nth-child(1) img {
        max-height: 100%; }


.Lightbox.RecipeCard .body {
  width: var(--maxBodyWidth);
  max-width: 100%;
  margin: 0 auto; }

.Lightbox.RecipeCard .section {
  grid-gap: 1rem;
  gap: 1rem; }

.Lightbox.RecipeCard .ingredients {
  max-width: 20rem; }
  @media (max-width: 576px) {
    .Lightbox.RecipeCard .ingredients {
      max-width: 100%; } }
  .Lightbox.RecipeCard .ingredients .Gallery {
    margin-bottom: 1rem;
    max-width: 100%; }
  .Lightbox.RecipeCard .ingredients .info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: var(--gapHoriz);
    gap: var(--gapHoriz); }
  .Lightbox.RecipeCard .ingredients ul {
    display: flex;
    flex-direction: column;
    margin: .5rem auto 1rem;
    min-width: 50%; }
  .Lightbox.RecipeCard .ingredients li {
    font-size: 1.2em; }

.Lightbox.RecipeCard .steps h2 {
  margin-top: 1em; }

.Lightbox.RecipeCard .steps li p {
  max-width: 25rem; }

.Lightbox.RecipeCard .cookingTimer {
  margin: 0.5rem auto 1rem -1rem; }
  .Lightbox.RecipeCard .cookingTimer button {
    font-size: 0.75em;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding: 0.5rem; }
  .Lightbox.RecipeCard .cookingTimer .Icon-Clock {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    margin-right: 0.5rem; }

.Icon-Clock,
.Icon-People {
  vertical-align: text-bottom; }

.CityGuideModal #gps__Field .form-input-object-field {
  display: flex;
  justify-content: space-between; }
  .CityGuideModal #gps__Field .form-input-object-field .form-row input {
    border-top: none;
    border-right: none;
    border-radius: 0;
    max-width: 4rem; }

.CityGuideCard.isTemplate > h2 {
  display: block;
  width: 15em;
  height: 1.5em;
  background-color: var(--inputRowLineColor); }

.CityGuideCard.isTemplate > .Gallery {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 56%;
  background-color: var(--inputRowLineColor); }
  .CityGuideCard.isTemplate > .Gallery:after {
    content: 'Destination';
    font-size: 2em;
    font-weight: bold;
    opacity: 0.15;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.CityGuideCard.isTemplate > .body {
  pointer-events: none; }
  .CityGuideCard.isTemplate > .body caption {
    width: 20em;
    height: 2em;
    margin: 0.75rem 0;
    background-image: var(--templateListPlaceholder); }
  .CityGuideCard.isTemplate > .body .CityGuideCard-Info p {
    display: block;
    height: 6em;
    background-image: var(--templateListPlaceholder); }
  .CityGuideCard.isTemplate > .body .CityGuideCard-Info ul {
    display: block;
    height: 10em;
    background-image: var(--templateListPlaceholder); }
  .CityGuideCard.isTemplate > .body Button {
    background-color: var(--inputRowLineColor); }

.CityGuideCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 40rem;
  margin: 0 auto; }
  .CityGuideCard > h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 1em; }
  .CityGuideCard img {
    width: 100%; }
  .CityGuideCard .body {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .CityGuideCard .body > .section {
      flex-grow: 1;
      flex-direction: row; }
    .CityGuideCard .body > .column {
      flex-grow: 1; }
  .Lightbox .CityGuideCard .CityGuideCard-Media, .Lightbox
  .CityGuideCard .CityGuideCard-Info {
    padding: 0; }
  .CityGuideCard .CityGuideCard-Media ul,
  .CityGuideCard .CityGuideCard-Info ul {
    padding-left: 0;
    font-size: 1em;
    font-family: adobe caslon pro;
    margin: 0; }
  .CityGuideCard .CityGuideCard-Media li,
  .CityGuideCard .CityGuideCard-Info li {
    list-style: none;
    font-weight: 100;
    margin-bottom: 0.75em;
    line-height: 1.3em; }
  .CityGuideCard .CityGuideCard-Media h3,
  .CityGuideCard .CityGuideCard-Info h3 {
    font-size: 1.2rem;
    margin-top: 1em;
    color: var(--textAccentedColor); }
  .CityGuideCard .CityGuideCard-Media p,
  .CityGuideCard .CityGuideCard-Info p {
    margin: 0; }
  .CityGuideCard .CityGuideCard-Media .Button,
  .CityGuideCard .CityGuideCard-Info .Button {
    margin-top: auto;
    margin-bottom: 0; }
  .CityGuideCard ul.CityGuideCard-TopSpots {
    margin-bottom: var(--colGap); }
    .CityGuideCard ul.CityGuideCard-TopSpots li {
      list-style-type: decimal;
      list-style-position: inside; }

.CityGuideLightbox .CityGuideCard-Steps {
  max-height: none; }
  .CityGuideLightbox .CityGuideCard-Steps h3 {
    font-family: Adobe Caslon Pro;
    margin-bottom: 1rem;
    color: var(--cyan); }

.Related-Info {
  padding: 1rem; }
  .Related-Info h3 {
    font-size: 1em; }


.ListCard.isTemplate > h2 {
  display: block;
  width: 15em;
  height: 1.5em;
  background-color: var(--inputRowLineColor); }

.ListCard.isTemplate > .Gallery {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 56%;
  background-color: var(--inputRowLineColor); }
  .ListCard.isTemplate > .Gallery:after {
    content: 'List';
    font-size: 2em;
    font-weight: bold;
    opacity: 0.15;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.ListCard.isTemplate > .body {
  pointer-events: none; }
  .ListCard.isTemplate > .body p {
    display: block;
    width: 20em;
    height: 2em;
    margin: 0.75rem 0;
    background-color: var(--inputRowLineColor); }
  .ListCard.isTemplate > .body ul.ListCard-TheList {
    display: block;
    width: 100%;
    height: 10em;
    background-color: var(--inputRowLineColor); }
  .ListCard.isTemplate > .body Button {
    background-color: var(--inputRowLineColor); }

.ListCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem auto; }
  .ListCard h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 1em; }
  .ListCard img {
    width: 100%; }
  .ListCard .body {
    width: 100%; }
  .ListCard .Button {
    margin: 0.5rem auto 0 calc(50% - var(--maxContentWidthNarrow) / 2); }
  .ListCard .ListCard-TheList {
    position: relative;
    max-width: var(--maxContentWidthNarrow);
    margin: 0 auto; }
    .ListCard .ListCard-TheList li {
      list-style-type: decimal;
      list-style-position: inside;
      font-weight: 100;
      margin-bottom: 0.75em;
      line-height: 1.3em; }

.FadeOut:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2rem;
  z-index: 1;
  opacity: 1;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover; }

.Icon-Clock,
.Icon-People {
  vertical-align: text-bottom; }

.ListCardModal #gps__Field .form-input-object-field {
  display: flex;
  justify-content: space-between; }
  .ListCardModal #gps__Field .form-input-object-field .form-row input {
    border-top: none;
    border-right: none;
    border-radius: 0;
    max-width: 4rem; }

.ListCardLightbox .media-body {
  width: 100%; }

.ListCardLightbox .ListCard-TheListWrapper {
  display: flex; }

.ListCardLightbox .ListCard-TheList {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  counter-reset: list;
  padding-left: 0;
  padding-right: 0;
  margin: 2.5rem auto; }
  .ListCardLightbox .ListCard-TheList li::before {
    counter-increment: list;
    content: counter(list) ".";
    position: absolute;
    left: 0;
    top: 0;
    font-size: 2rem;
    line-height: 1em;
    font-weight: 700;
    color: #bdbdbd; }
  .ListCardLightbox .ListCard-TheList li {
    position: relative;
    padding-left: 4.5rem;
    padding-right: 4.5rem;
    margin: 0 auto 2rem 0; }
  .ListCardLightbox .ListCard-TheList .body {
    width: auto;
    padding: 0; }
  .ListCardLightbox .ListCard-TheList img {
    max-height: 30rem;
    -o-object-fit: cover;
       object-fit: cover; }
  .ListCardLightbox .ListCard-TheList .thumbnail {
    max-height: 120px;
    max-width: 120px; }


#location__Row input.form-input {
  width: calc(100% - 6em); }

#location__Row .form-input-field {
  position: relative; }

#location__Row .GeoPointField-static_map {
  position: absolute;
  width: 5em;
  right: 0;
  top: -0.25em; }

.PlaceCard.isTemplate > h2 {
  display: block;
  width: 15em;
  height: 1.5em;
  background-color: var(--inputRowLineColor); }

.PlaceCard.isTemplate > .Gallery {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 56%;
  background-color: var(--inputRowLineColor); }
  .PlaceCard.isTemplate > .Gallery:after {
    content: 'Recomendation';
    font-size: 2em;
    font-weight: bold;
    opacity: 0.15;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.PlaceCard.isTemplate > .body {
  pointer-events: none; }
  .PlaceCard.isTemplate > .body caption {
    width: 20em;
    height: 2em;
    margin: 0.75rem 0;
    background-color: var(--inputRowLineColor); }
  .PlaceCard.isTemplate > .body .PlaceCard-Info p {
    display: block;
    height: 6em;
    background-image: var(--templateListPlaceholder); }
  .PlaceCard.isTemplate > .body .PlaceCard-Info ul {
    display: block;
    height: 10em;
    background-image: var(--templateListPlaceholder); }
  .PlaceCard.isTemplate > .body .PlaceCard-static_map {
    width: 100%;
    padding-top: 56%;
    background-color: var(--inputRowLineColor); }
  .PlaceCard.isTemplate > .body Button {
    background-color: var(--inputRowLineColor); }

.PlaceCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto; }
  .PlaceCard > h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 1em; }
  .PlaceCard > h3 {
    font-size: 1em;
    margin: -1em auto 1em;
    color: #999; }
  .PlaceCard img {
    width: 100%; }
  .PlaceCard .body {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .PlaceCard .body > .section {
      flex-grow: 1;
      flex-direction: row; }
  .PlaceCard .PlaceCard-Info ul {
    padding-left: 0;
    font-size: 1em;
    font-family: adobe caslon pro;
    margin: 0; }
  .PlaceCard .PlaceCard-Info li {
    list-style: none;
    font-weight: 100;
    margin-bottom: 0.75em;
    line-height: 1.3em; }
  .PlaceCard .PlaceCard-Info h3 {
    font-size: 1.2rem;
    margin-top: 1em;
    color: var(--textAccentedColor); }
  .PlaceCard .PlaceCard-Info p {
    margin: 0; }
  .PlaceCard .PlaceCard-Info button.Button {
    margin-top: auto;
    margin-bottom: 0;
    width: 100%; }
  .PlaceCard .PlaceCard-static_map {
    margin-bottom: var(--colGap); }
  .PlaceCard .PlaceCard-FunFacts li {
    list-style-type: decimal;
    list-style-position: inside; }

.PlaceCardLightbox .PlaceCard-Steps {
  max-height: none; }
  .PlaceCardLightbox .PlaceCard-Steps h3 {
    font-family: Adobe Caslon Pro;
    margin-bottom: 1rem;
    color: var(--cyan); }

.formlib-AutoCompleteDropdown {
  display: none;
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
  margin-left: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  flex-direction: column-reverse; }
  .formlib-AutoCompleteDropdown.showTypeAhead {
    display: flex; }
  .formlib-AutoCompleteDropdown .formlib-AutoComplete-TypeAhead {
    pointer-events: none;
    /* This tag obscrues the intranet tag preventing it from being removed */
    padding: 0.1rem 0.5rem;
    position: absolute;
    top: 0;
    left: 0; }
  .formlib-AutoCompleteDropdown .formlib-AutoComplete-TypeAheadPrefix {
    visibility: hidden; }
  .formlib-AutoCompleteDropdown .formlib-AutoComplete-TypeAheadSuffix {
    color: #ccc; }

.formlib-AutocompleteItem {
  padding: 0.6em;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  text-align: left; }
  .formlib-AutocompleteItem:hover {
    background-color: #3aadde !important;
    color: white; }
    .formlib-AutocompleteItem:hover h3,
    .formlib-AutocompleteItem:hover h4 {
      color: white; }
  .formlib-AutocompleteItem:last-child {
    border-bottom: 0; }

.formlib-AutocompleteItem--Highlight {
  background-color: #2191c4;
  color: white; }

.formlib-AutosuggestItemThumbnail {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin: 0.2rem 0.5rem 0.2rem 0; }

.formlib-AutocompleteItem--Animation-leave {
  opacity: 1; }

.formlib-AutocompleteItem--Animation-leave-active {
  overflow: hidden;
  transition: height 0.3s ease-out, opacity 0.2s ease-in; }

.formlib-AutocompleteItem--Animation-leave-end {
  height: 0;
  opacity: 0; }

.formlib-AutocompleteItem--Animation-enter {
  height: 0;
  opacity: 0; }

.formlib-AutocompleteItem--Animation-enter-active {
  transition: height 0.2s ease-out, opacity 0.5s ease-in; }

.formlib-AutocompleteItem--Animation-enter-end {
  opacity: 1; }

#topSpots__Field input.form-input {
  width: 25em; }

#topSpots__Field .form-input-field {
  position: relative; }

#topSpots__Field .GeoPointField-static_map {
  position: absolute;
  width: 6em;
  right: 0;
  bottom: 0; }


.MobiledocToolbar .ButtonClose {
  margin: 0 var(--padHoriz); }

.MobiledocToolbar .Button {
  padding: 2px; }
  .MobiledocToolbar .Button img {
    width: 2rem; }

.MobiledocToolbar .Dropdown-Wrapper .Button,
.MobiledocToolbar .Button.dropdown-toggle {
  padding: var(--padVert) var(--padHoriz); }

.EditPage.BlogPost > .body {
  padding-top: 1.5rem; }

.EditPage.BlogPost .formlib-FormRows #connectedTitle__Row {
  margin-top: -1.5rem;
  margin-bottom: 1.5rem; }
  .EditPage.BlogPost .formlib-FormRows #connectedTitle__Row .form-input-help {
    line-height: 1.2em;
    font-size: 0.8em; }

.EditPage.BlogPost .form-row.IMobiledocField {
  max-width: var(--maxContentWidth); }

.EditPage.BlogPost .IMobiledocField .MobiledocToolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - var(--scrollbarWidth));
  max-width: none;
  max-width: initial;
  height: var(--headerHeight);
  z-index: 99; }

.EditPage.BlogPost label#title,
.EditPage.BlogPost label#body {
  display: none; }

.EditPage.BlogPost #title__Field {
  font-size: 1.5em; }

.Live-TimedPublishing-Banner {
  padding: 0.5rem;
  background-color: #f3dcce;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }
  .Live-TimedPublishing-Banner::before {
    content: '';
    width: 1.85em;
    height: 1.85em;
    transform: rotate(45deg) translate(-30%, 50%);
    position: absolute;
    bottom: -0.45em;
    left: 50%;
    border-bottom: 0.75em solid #f3dcce;
    border-right: .75em solid #f3dcce; }
  .Live-TimedPublishing-Banner h2 {
    font-size: 1.75em;
    margin-left: 1rem;
    margin-bottom: 0.2em;
    text-align: center;
    color: #c58660;
    font-weight: bold; }
  .Live-TimedPublishing-Banner h3 {
    font-size: 1em;
    margin-bottom: 0;
    text-align: center;
    z-index: 2; }
  .Live-TimedPublishing-Banner .Icon-Clock {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    width: 2em;
    height: 2em;
    margin-bottom: 0; }

.formlib-ListFieldActionBar .btn {
  margin-right: 1rem; }

.IGuide {
  padding-top: 3.5rem; }
  .IGuide .FilterContainer {
    position: fixed;
    top: calc(2.5rem + 1px);
    max-width: 50rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 99; }
  .IGuide .btn-outline-primary {
    border-color: white; }
  .IGuide #content__Field #title__Row {
    margin-bottom: 0; }
  .IGuide #content__Field #title__Field {
    text-align: left;
    font-size: 1.2rem;
    font-weight: 400; }
  .IGuide #content__Field #shortDescription__Field {
    font-size: 1em;
    border: none;
    background-attachment: local;
    background-image: linear-gradient(to right, white 0px, transparent 0px), linear-gradient(to left, white 0px, transparent 20px), repeating-linear-gradient(white, white 31px, #ddd 31px, #ddd 32px, white 32px);
    line-height: 28px;
    padding: 8px 20px 8px 0px; }
  .IGuide .IEditItem > div > .formlib-FormRows > .form-input-list-field > .form-input-field > #content__Field > .formlib-DragItem:before {
    content: " ";
    position: absolute;
    right: calc(-3rem + 0.5rem);
    width: 2px;
    height: 95%;
    background-color: var(--primary-20); }
  .IGuide .IEditItem > div > .formlib-FormRows > .form-input-list-field > .form-input-field > #content__Field > .formlib-DragItem > .formlib-DragHandle {
    right: -3rem;
    background-color: white; }
  .IGuide .IEditItem > div > .formlib-FormRows > .form-input-list-field > .form-input-field > #content__Field > .formlib-DragItem .formlib-DragItem .formlib-DragHandle {
    left: -1.55rem;
    top: 46%;
    background-color: white; }
  .IGuide #content__Field .formlib-FormRows .form-input-list-field {
    padding-left: 1rem;
    border-left: 2px solid var(--primaryBg); }
    .IGuide #content__Field .formlib-FormRows .form-input-list-field .formlib-ListFieldActionBar {
      margin-top: 1rem; }
    .IGuide #content__Field .formlib-FormRows .form-input-list-field .formlib-ListFieldRowDeleteBtnWrapper {
      position: absolute;
      left: 100%; }

.Guide.ViewPage .Hero,
.GuideLightbox .Hero {
  position: relative;
  overflow: hidden;
  height: 20rem;
  max-width: 60rem;
  margin: 0 auto;
  background-size: cover;
  background-position: center; }
  .Guide.ViewPage .Hero .AvatarContainer,
  .GuideLightbox .Hero .AvatarContainer {
    position: absolute;
    padding: 1rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -2.5rem; }
    .Guide.ViewPage .Hero .AvatarContainer .Avatar,
    .GuideLightbox .Hero .AvatarContainer .Avatar {
      display: block;
      width: 100%;
      height: 100%;
      max-width: 7rem;
      border-radius: 50%;
      transform-origin: center top;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
      border: 2px solid white;
      background-color: rgba(0, 0, 0, 0.05); }
  .Guide.ViewPage .Hero .UserNameContainer,
  .GuideLightbox .Hero .UserNameContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 2rem;
    font-size: 0.75em;
    transform: translate(-50%, -50%);
    color: white; }
  .Guide.ViewPage .Hero .HeaderContainer,
  .GuideLightbox .Hero .HeaderContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.75rem 0.5rem 0.5rem;
    background-color: rgba(255, 255, 255, 0.85); }
    .Guide.ViewPage .Hero .HeaderContainer h3,
    .GuideLightbox .Hero .HeaderContainer h3 {
      font-family: 'Adobe Caslon Pro', serif;
      font-size: 0.7em;
      letter-spacing: 0.25em;
      margin-bottom: 0;
      color: var(--primaryBg);
      text-align: center; }
    .Guide.ViewPage .Hero .HeaderContainer h2,
    .GuideLightbox .Hero .HeaderContainer h2 {
      margin: 0;
      font-family: 'Adobe Caslon Pro', serif;
      font-weight: normal;
      text-align: center; }

.Guide.ViewPage .Guide-Content,
.GuideLightbox .Guide-Content {
  max-width: 40rem !important;
  margin-top: 2rem; }
  .Guide.ViewPage .Guide-Content .section-header,
  .GuideLightbox .Guide-Content .section-header {
    font-family: 'Adobe Caslon Pro', serif;
    font-size: 1.1em;
    font-weight: normal;
    color: #ccc;
    border-bottom: 1px solid #ddd;
    padding: 0.5rem;
    margin: 0.5rem 0 1rem;
    text-align: center; }
  .Guide.ViewPage .Guide-Content .Guide-TOC,
  .GuideLightbox .Guide-Content .Guide-TOC {
    max-width: 30rem;
    margin: 2rem auto; }
    .Guide.ViewPage .Guide-Content .Guide-TOC .Guide-Chapter,
    .GuideLightbox .Guide-Content .Guide-TOC .Guide-Chapter {
      margin: 2rem 0 .5rem; }
      .Guide.ViewPage .Guide-Content .Guide-TOC .Guide-Chapter h3,
      .GuideLightbox .Guide-Content .Guide-TOC .Guide-Chapter h3 {
        font-family: 'Adobe Caslon Pro', serif;
        font-weight: 600;
        font-size: 1em;
        text-align: left;
        margin: 0 .5rem .5em; }
      .Guide.ViewPage .Guide-Content .Guide-TOC .Guide-Chapter p,
      .GuideLightbox .Guide-Content .Guide-TOC .Guide-Chapter p {
        font-family: 'Adobe Caslon Pro', serif;
        font-size: 0.9em;
        line-height: 1.85em;
        margin: 0 .5rem .5em; }
    .Guide.ViewPage .Guide-Content .Guide-TOC .Guide-Page,
    .GuideLightbox .Guide-Content .Guide-TOC .Guide-Page {
      position: relative;
      padding: 0.5rem 0.5rem 0.35rem;
      border-radius: 8px;
      margin-bottom: 0.5rem; }
      .Guide.ViewPage .Guide-Content .Guide-TOC .Guide-Page h3,
      .GuideLightbox .Guide-Content .Guide-TOC .Guide-Page h3 {
        font-family: 'Adobe Caslon Pro', serif;
        font-weight: normal;
        font-size: 1em;
        text-align: left;
        margin: 0; }
      .Guide.ViewPage .Guide-Content .Guide-TOC .Guide-Page h4,
      .GuideLightbox .Guide-Content .Guide-TOC .Guide-Page h4 {
        position: absolute;
        font-family: 'Adobe Caslon Pro', serif;
        font-weight: normal;
        font-size: 0.75em;
        color: var(--secondary);
        text-align: left;
        margin: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%); }
      .Guide.ViewPage .Guide-Content .Guide-TOC .Guide-Page.public,
      .GuideLightbox .Guide-Content .Guide-TOC .Guide-Page.public {
        cursor: pointer; }
        .Guide.ViewPage .Guide-Content .Guide-TOC .Guide-Page.public::after,
        .GuideLightbox .Guide-Content .Guide-TOC .Guide-Page.public::after {
          position: absolute;
          right: 0.5rem;
          top: 50%;
          transform: translateY(-50%);
          content: '';
          display: block;
          width: 1px;
          height: 1px;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-left: 8px solid var(--primaryBg); }
        .Guide.ViewPage .Guide-Content .Guide-TOC .Guide-Page.public:hover,
        .GuideLightbox .Guide-Content .Guide-TOC .Guide-Page.public:hover {
          background-color: #eee; }
    .Guide.ViewPage .Guide-Content .Guide-TOC .btn,
    .GuideLightbox .Guide-Content .Guide-TOC .btn {
      display: block;
      margin: 3.5rem auto 3rem; }

.GuidePage h1 {
  margin: 2rem 0 1.5rem; }

.GuidePage .Actions h2 {
  font-family: 'Adobe Caslon Pro', serif;
  font-weight: 600;
  font-size: 0.8em;
  margin: 0 1rem; }

.GuidePage .GuidePage-Next {
  margin: 2rem auto;
  max-width: 40rem;
  /* same as .mobiledoc container*/ }
  .GuidePage .GuidePage-Next .col:first-child {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    flex-grow: 0;
    margin-left: auto;
    min-width: 12.5rem;
    max-width: 25rem;
    border-top: 2px solid var(--primary-20); }
  .GuidePage .GuidePage-Next h3 {
    margin: 0;
    text-align: left;
    font-size: 0.9em; }
  .GuidePage .GuidePage-Next h4 {
    margin: 0;
    text-align: left;
    font-size: 0.7em; }
  .GuidePage .GuidePage-Next .btn {
    display: block;
    margin: 0 0 0 -5px; }

.fb-btn {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 8px 0 32px;
  height: 28px;
  background-color: #1877f2;
  max-width: 320px;
  min-width: 0;
  min-width: initial;
  width: auto;
  border-radius: 3px;
  overflow: hidden;
  opacity: 0.9;
  cursor: pointer; }
  .fb-btn:hover {
    opacity: 1 !important; }
  .fb-btn .block {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .fb-btn .fb-btn-text {
    color: white;
    font-family: Helvetica, Arial, sans-serif;
    letter-spacing: .25px;
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    line-height: 1.28;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-overflow: clip;
    overflow: hidden;
    margin: 0 auto; }
  .fb-btn::after {
    content: '';
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfjBBYGJDeRLlUUAAABp0lEQVRIx6WVu04bQRSG/x1jbZCR00RacAJYooksd/RI7qBJHiAlD5F2lQYhkSLFNrwFFVIaUsRRJEspSYnExcRFCguDE1F8FPaavczYXvinmZk955vLmT3HQxaV1dJ7NVRTTVJXXZ3qSCe6t9iSbQERfWzqExFk7dNDn5AB03RDiO8CBLSZR+3kPh7dm5zP5Q5wTjMLCAq4jxBBEuDzw2F4zyfe4lPmJWtspg7iPwJCh3uPrcytJxXGgMB58zu5IKcjEowAkcP9BE0FQIRE2fFs4MNMQJ+yUUtV2fV70tvVdRy1lKpquQ8Alcm6XadNZNSQS7eT3rLTpmFU02x5zi81jxstzXTDCRgYC90bt/xMPU8w6s1xhFhruZnecwF/jDrPAvwy+loAsJqbOfaoqKeK1Xx2FO70yuhWhwX2kNahhkK85r/1mbp+oVhDVpCRdKW9J61/oOu4Lizws/AOvrOQTKp1LgoBuqxm0/oGl3MDztiwFZZ6Jje7AB3euEpbiZB/UwFDPlJy10YhVvg8ztJZwF/2WZ9eXOO2yDZfEuNv7PGOFzbbB6Kd0fTJtKEiAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE5LTA0LTIyVDEzOjM2OjU1LTA3OjAwi4nE0AAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOS0wNC0yMlQxMzozNjo1NS0wNzowMPrUfGwAAAAASUVORK5CYII=);
    position: absolute;
    left: 8px;
    top: 6px;
    width: 16px;
    height: 16px;
    background-size: contain; }
  .fb-btn.fb-btn-large {
    padding: 0 12px 0 44px;
    height: 40px;
    max-width: 400px;
    border-radius: 4px;
    opacity: 0.85; }
    .fb-btn.fb-btn-large .fb-btn-text {
      font-size: 16px; }
    .fb-btn.fb-btn-large::after {
      content: '';
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfjBBIJCwnGbaC4AAACd0lEQVRYw9WYT2sTURTFz4wTosIIJmAXhSoFg2SrC6GopCBko610k537rhVxJRWx+QB+Ae1KoQhxo0UoVKpIBEVDI8Ys3OnCCEYKkpDj4nXIzGTezJ3MzMIzq8l79/7ev3vnvhhEtrJEfS6iijJKKMAG0MdPdLCH59jBMNKaYU+OK9xgjzr1uMEV5sJ86JsM1tilRF3WaMQFVNgUOXfUZEUOMLkey7mjdZoSgM3GVO5JskE7CjDH1tTuSbLFuTCAndC9Qtg6gJlgcbwL5doLwxXJddwShN0Ib7GDb/gFA3nYOI4ZrPr61HF7MtAqotFt8kzAuZtUxb9Ehujc39BEzaSaTug5XWoC9w+0UR+kmhuQEySFHo/FAnRVjjIBAEuYj9zch/gdK0/P4+o4XS8JDLZ877M4h9mDAQZrGZvqFFkhCXksb4Re475gUS21B4sC9+RRD+CTyGZR7UFVtKb7nrfTIpsqYAIox9o8pbyoV1kBSlMAZCopQCEzQEEB7MwAtgIMtB0M16NveaK1HyjAj4TjPKVt+f5fAI7gRBSgndH4gY4CbMVwFw/wQgF28ScB4KS2pY9dla4H2MaVwC7u2t7Qtuj00jmmwKNEi6TT44OhEDTZjky90R9Jrz6r6kjNYIR66uO/h9F4BqDFr6nO4AsP+UvHBQ5TAwx5Kag2XUsNcCe4+LX4OhXAtrv49ZoV+SExoMWZsAtIke8TAd6xGHWFKvDN1IBXk+VlUKVpcS3gREUBBrzPvOQSqJ7z7MQCfOTZYE86AJjjde6JAG2u6m/7eoC6lizzGftawF82eFl/y48GOHO5wLu+357yJhd4ONrayPrvnH8PRfVZmihfvgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0wNC0xOFQxNjoxMTowOS0wNzowMAR/UMMAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMDQtMThUMTY6MTE6MDktMDc6MDB1Iuh/AAAAAElFTkSuQmCC);
      position: absolute;
      left: 12px;
      top: 8px;
      width: 24px;
      height: 24px;
      background-size: contain; }
  .fb-btn.fb-btn-small {
    padding: 0 8px 0 24px;
    height: 20px;
    max-width: 200px;
    border-radius: 3px;
    opacity: 1; }
    .fb-btn.fb-btn-small .fb-btn-text {
      font-size: 11px;
      padding-top: 2px;
      padding-left: 2px;
      max-width: 150px; }
    .fb-btn.fb-btn-small::after {
      content: '';
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfjBBYGJDeRLlUUAAABR0lEQVQ4y5WUvUoDQRSFv2zSpRNUdpOAGAXfwSa1WTAo9lpEC2PhG+Q5FkHQ1iKNIJa+hApaJSoRYxHSJCYeizXuz2x+PAvL3pnz3Zm9w52UiChHhTJFHOCVZ65p8BJxKHhseRoqrqE82YErsLvqapK6cuNATSNN00i1MODOsPuIOwbshM28aV+ryigrJ7Qx2wc8w/6uxVAxAnkC5RMqcyQSgaFyFtukiesOgBXu/f/8U5qKRRlTTwDssWHMlC2KCcAXANmEmaKFw3/kpNSLZEoZlgU6oahn0Z6Rcy0StS0eZgDrkegxwy1boYF66F2iBGxGgBtU0MA4OP/A6sb4QAWLJpdz1+iCJkJ5deZa4VN5YQEtqmiO/Ae0ghat6nvqCiMdxlt0Rx8TgY52zZ5Gts7UN4C+zrWcdAmMoRM1fr+PdaVTLUUdPzATB+2c4R9jAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE5LTA0LTIyVDEzOjM2OjU1LTA3OjAwi4nE0AAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOS0wNC0yMlQxMzozNjo1NS0wNzowMPrUfGwAAAAASUVORK5CYII=);
      position: absolute;
      left: 8px;
      top: 4px;
      width: 12px;
      height: 12px;
      background-size: contain; }

.LiveItem-Invitation.body {
  padding-bottom: 10vh;
  justify-content: flex-start; }
  .LiveItem-Invitation.body .section.copy {
    flex-direction: column;
    max-width: 30rem;
    margin: 0 auto; }
  .LiveItem-Invitation.body .Invitation-Message {
    display: block;
    margin: 2rem 0 1rem;
    font-weight: 200; }
  .LiveItem-Invitation.body #FB-Button,
  .LiveItem-Invitation.body #FB-Login-Btn {
    margin: 0 auto 0.5rem; }
  .LiveItem-Invitation.body .LoginWidget-Social {
    padding: 1rem 0 0.5rem;
    margin: 0 auto;
    max-width: 35rem;
    justify-content: center; }
    @media (min-width: 576px) {
      .LiveItem-Invitation.body .LoginWidget-Social {
        _padding-top: 4rem; } }

.WorkflowStatus {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem; }
  .WorkflowStatus .Dropdown {
    --dropdownHoverBg: transparent;
    --dropdownHoverFg: inherit;
    min-width: 12rem; }
  .WorkflowStatus .status {
    color: var(--secondaryBg);
    font-size: 1.5em;
    text-align: right;
    font-weight: 600;
    text-transform: uppercase; }
  .WorkflowStatus .Button {
    width: 100%;
    text-align: left; }
  .WorkflowStatus h3 {
    font-size: 0.85em;
    margin-bottom: 0; }
  .WorkflowStatus .Dropdown .header {
    font-size: 1.2em;
    margin-bottom: 1rem; }
  .WorkflowStatus .Icon-Arrow {
    color: var(--iconFill);
    fill: currentColor;
    stroke: currentColor;
    transform: rotate(-90deg); }
    .WorkflowStatus .Icon-Arrow.active {
      color: var(--iconAccentedFill); }

.EditForm-Invitation .EditForm-InvitationLink {
  margin-bottom: 3rem; }
  .EditForm-Invitation .EditForm-InvitationLink h3 {
    font-weight: 400; }
  .EditForm-Invitation .EditForm-InvitationLink p {
    margin-bottom: 0; }



.ListItemUser > .body {
  position: relative;
  padding-left: 3.5rem; }

.ListItemUser .avatar {
  position: absolute;
  top: 50%;
  left: 0;
  width: 2.5rem;
  height: auto;
  border-radius: 50%;
  transform: translateY(-50%); }

.WorkflowStatus {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem; }
  .WorkflowStatus .Dropdown {
    --dropdownHoverBg: transparent;
    --dropdownHoverFg: inherit;
    min-width: 12rem; }
  .WorkflowStatus .status {
    color: var(--secondaryBg);
    font-size: 1.5em;
    text-align: right;
    font-weight: 600;
    text-transform: uppercase; }
  .WorkflowStatus .Button {
    width: 100%;
    text-align: left; }
  .WorkflowStatus h3 {
    font-size: 0.85em;
    margin-bottom: 0; }
  .WorkflowStatus .Dropdown .header {
    font-size: 1.2em;
    margin-bottom: 1rem; }
  .WorkflowStatus .Icon-Arrow {
    color: var(--iconFill);
    fill: currentColor;
    stroke: currentColor;
    transform: rotate(-90deg); }
    .WorkflowStatus .Icon-Arrow.active {
      color: var(--iconAccentedFill); }


.EditForm-User #avatarUrl__Row .ImageField.card {
  max-height: 10rem;
  max-width: 10rem; }

.EditForm-User .form-header {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  height: 3rem;
  z-index: 9;
  border-bottom: 1px solid #eee;
  background-color: rgba(255, 255, 255, 0.95); }
  .EditForm-User .form-header.btn-group > .btn {
    flex-grow: 0; }

.EditForm-User .AdminAccountForm {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .EditForm-User .AdminAccountForm h2 {
    flex-grow: 1;
    text-align: left; }
  .EditForm-User .AdminAccountForm .Button {
    flex-grow: 0;
    height: 2.5em; }

.FullName {
  font-size: 1.7em;
  display: block;
  margin: 0 auto; }

.AvatarWrapper {
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  width: 100%; }

.ListFilter {
  width: 100%;
  margin: 0.5em auto;
  padding: 0.5em;
  font-size: 1em; }

.placeholder {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.EditForm-Invitation .EditForm-InvitationLink {
  margin-bottom: 3rem; }
  .EditForm-Invitation .EditForm-InvitationLink h3 {
    font-weight: 400; }
  .EditForm-Invitation .EditForm-InvitationLink p {
    margin-bottom: 0; }

.LiveItem-Invitation.body {
  padding-bottom: 10vh;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }
  .LiveItem-Invitation.body .section.copy {
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 30rem;
    margin: 0 auto; }
  .LiveItem-Invitation.body .Invitation-Message {
    display: block;
    margin: 2rem 0 1rem;
    font-weight: 200; }
  .LiveItem-Invitation.body #FB-Button,
  .LiveItem-Invitation.body #FB-Login-Btn {
    margin: 0 auto 0.5rem; }
  .LiveItem-Invitation.body .LoginWidget-Social {
    padding: 1rem 0 0.5rem;
    margin: 0 auto;
    max-width: 35rem;
    -webkit-justify-content: center;
            justify-content: center; }
    @media (min-width: 576px) {
      .LiveItem-Invitation.body .LoginWidget-Social {
        _padding-top: 4rem; } }

.CreatePage,
.EditPage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--pageBg);
  z-index: 9; }
  .CreatePage > .header,
  .EditPage > .header {
    box-shadow: var(--shadow); }
    .CreatePage > .header .Toolbar,
    .EditPage > .header .Toolbar {
      width: 100%; }
  .CreatePage > .body,
  .EditPage > .body {
    max-width: var(--maxContentWidth);
    overflow: hidden visible;
    min-height: 100%; }
  .CreatePage > .footer,
  .EditPage > .footer {
    box-shadow: var(--shadow); }
    .CreatePage > .footer .Toolbar,
    .EditPage > .footer .Toolbar {
      max-width: var(--maxContentWidth); }
      .CreatePage > .footer .Toolbar .leading,
      .EditPage > .footer .Toolbar .leading {
        grid-gap: 1.5em;
        gap: 1.5em; }
    .CreatePage > .footer .Dropdown,
    .EditPage > .footer .Dropdown {
      box-shadow: var(--shadow); }

/***************************
 ** Form specific styling **
 ***************************/
.ViewPage,
.EditModal.Modal,
.IEditItem {
  min-height: 100vh;
  width: 100%; }
  .ViewPage .form-nav,
  .EditModal.Modal .form-nav,
  .IEditItem .form-nav {
    max-width: var(--maxContentWidth);
    margin-left: auto;
    margin-right: auto;
    -webkit-justify-content: center;
            justify-content: center; }
  .ViewPage h1,
  .ViewPage .Mobiledoc,
  .ViewPage .form-field,
  .ViewPage .form-check,
  .ViewPage .__mobiledoc-editor,
  .EditModal.Modal h1,
  .EditModal.Modal .Mobiledoc,
  .EditModal.Modal .form-field,
  .EditModal.Modal .form-check,
  .EditModal.Modal .__mobiledoc-editor,
  .IEditItem h1,
  .IEditItem .Mobiledoc,
  .IEditItem .form-field,
  .IEditItem .form-check,
  .IEditItem .__mobiledoc-editor {
    display: block;
    max-width: var(--maxContentWidth);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem; }
  .ViewPage .form-input-object-field > .form-input-field > .form-input-object-field,
  .EditModal.Modal .form-input-object-field > .form-input-field > .form-input-object-field,
  .IEditItem .form-input-object-field > .form-input-field > .form-input-object-field {
    border-left: 4px solid var(--primary-20);
    padding-left: 1rem; }
  .ViewPage > .formlib-FormRows > .form-row-object > .form-input-field > .form-input-object-field,
  .EditModal.Modal > .formlib-FormRows > .form-row-object > .form-input-field > .form-input-object-field,
  .IEditItem > .formlib-FormRows > .form-row-object > .form-input-field > .form-input-object-field {
    border-width: 0;
    padding-left: 0; }

/**
 * Rich text widget styling
 * TODO: Move to proper packages
 **/
.RichText-Widget-left {
  float: left;
  margin: 0 1em 0.6em 0;
  width: 40%;
  min-width: 15rem; }

.RichText-Widget-right {
  float: right;
  margin: 0 0 0.6em 1em;
  width: 40%;
  min-width: 15rem; }

.RichText-Widget-center {
  display: block;
  text-align: center;
  margin: 2em 0; }

.RichText-Widget-inline {
  display: inline-block; }

.RichText-ImageContainer {
  position: relative; }

.RichText-Image {
  width: 100%; }

.RichText-PodcastContainer {
  position: relative; }

.RichText-Podcast {
  height: 21rem; }

.RichText-YoutubeContainer {
  position: relative; }

.RichText-Youtube {
  height: 21rem; }

.RichText-MediaContainer {
  position: relative; }

.RichText-ResultTable {
  width: 100%;
  counter-reset: resultTableNr;
  margin: 2rem 0 4rem; }

.RichText-ResultTableRow {
  border-bottom: 1px solid #ccc; }

.RichText-ResultTableCell {
  min-height: 1em;
  padding: 1rem 0.5rem 0.5rem; }

.RichText-ResultTableCell-Name {
  width: 70%;
  padding-left: 4rem; }

.RichText-ResultTableCell-Name:before {
  position: absolute;
  left: 0rem;
  width: 2.7rem;
  counter-increment: resultTableNr;
  content: counter(resultTableNr) "."; }

.RichText-ResultTableCell-Result {
  width: 30%;
  min-width: 5em;
  text-align: right; }

.Medium-placeholder {
  position: absolute;
  pointer-events: none;
  text-align: left;
  padding: 0.5rem 2rem;
  font-size: 1.5em; }

.Medium-rich {
  -webkit-user-modify: read-write;
  -webkit-user-select: text;
  user-select: text;
  text-align: left;
  padding: 0.5rem 2rem;
  font-size: 1.5em; }

.RichEditor-StickyFormattingToolbar {
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  z-index: 1; }

.RichEditor-StickyFormattingToolbar--hidden {
  display: none; }

@media (max-width: 500px) {
  .RichEditor-FormattingToolbarContainer {
    font-size: 12px; } }

.RichEditor-FormattingToolbar {
  margin-bottom: 1em; }

.RichEditor-FormattingButton {
  padding: 0.8em 1em;
  margin-right: 1px;
  background: gray;
  display: inline-block;
  color: white;
  cursor: pointer;
  min-width: 3em;
  text-align: center; }

.RichEditor-FormattingButton:hover {
  background-color: darkgray; }

.RichEditor-InsertActionButton {
  padding: 0.8em 1em;
  margin-right: 1px;
  background: #555;
  display: inline-block;
  color: white;
  cursor: pointer;
  min-width: 3em;
  text-align: center; }

.RichEditor-InsertActionButton:hover {
  background-color: darkgray; }

.RichEditor-WidgetButton {
  padding: 0.8em 1em;
  margin-right: 1px;
  background: #333;
  display: inline-block;
  color: white;
  cursor: pointer;
  min-width: 3em;
  text-align: center; }

.RichEditor-WidgetButton:hover {
  background-color: #222; }

.RichText-Widget {
  position: relative; }

.RichText-WidgetButtonToolbar {
  position: absolute;
  left: 0.5rem;
  top: 0.5rem; }

.RichText-WidgetFormattingButton {
  display: inline-block;
  width: 2em;
  height: 2em;
  padding: 0.2em;
  margin-left: 0.5em;
  border: 1px solid #fff;
  border-radius: 0.2em;
  background-color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  opacity: 0.3;
  transition: opacity 0.3s ease-in-out; }

.RichText-WidgetFormattingButton:hover {
  opacity: 1;
  background-color: white; }

.RichText-Widget:hover .RichText-WidgetFormattingButton {
  opacity: 1; }

.RichText-DeleteButton {
  position: absolute;
  bottom: 0.5em;
  right: 0.5em; }

.RichText-EditButton {
  position: absolute;
  top: 0.5em;
  right: 0.5em; }

.RichText-PodcastContainer {
  height: 20rem; }


.PullUpMenu.active .MainMenu {
  margin-left: -3rem;
  border-bottom: 1px solid #eee; }

.MainMenuContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  border-bottom: 1px solid #ddd;
  max-width: var(--maxHeaderWidth);
  margin: 0 auto; }

.AdminMenu,
.ModalMenu,
.EditLiveMenu,
.MainMenu {
  --linkHover: #dfe7fa; }
  .AdminMenu .NavStateButton,
  .ModalMenu .NavStateButton,
  .EditLiveMenu .NavStateButton,
  .MainMenu .NavStateButton {
    color: black;
    font-weight: 300; }
    .AdminMenu .NavStateButton:hover,
    .ModalMenu .NavStateButton:hover,
    .EditLiveMenu .NavStateButton:hover,
    .MainMenu .NavStateButton:hover {
      background-color: var(--linkHover); }

.EditMenu {
  display: none; }
  @media (max-width: 576px) {
    .EditMenu {
      display: block; } }

.MainMenu {
  display: -webkit-flex;
  display: flex;
  max-width: var(--maxHeaderWidth);
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 auto; }
  .MainMenu .MainMenu-Slot {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .MainMenu .MainMenu-Slot:first-child > span {
      margin-right: auto; }
    .MainMenu .MainMenu-Slot:last-child > span {
      margin-left: auto; }
  @media (max-width: 576px) {
    .MainMenu .MainMenu-Slot.EditSlot {
      opacity: 0; } }
  .MainMenu .MainMenu-Slot.NavSlot {
    width: 0;
    z-index: 9;
    font-size: 1.25em; }
  .MainMenu .MainMenu-Slot.SearchSlot {
    /* This is to center searchbox when nav menu is hidden, but it is not responsive */
    padding: 0 var(--padHoriz);
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    height: var(--headerHeight); }
  .MainMenu .SearchContainer {
    width: 12rem;
    padding: 0 5px;
    transition: width 0.3s, margin 0.3s; }
    @media (max-width: 576px) {
      .MainMenu .SearchContainer {
        width: 3.5rem; } }
    .MainMenu .SearchContainer #search__Row {
      -webkit-flex: 0 0;
              flex: 0 0; }
      .MainMenu .SearchContainer #search__Row .form-input-label {
        display: none; }
      .MainMenu .SearchContainer #search__Row #search__Field {
        border-radius: 2em;
        padding: var(--padVert) calc(var(--padHoriz) * 2);
        border: 1px solid var(--borderMutedColor);
        outline: none; }
  .MainMenu.search .SearchContainer {
    width: 100vw; }
    .MainMenu.search .SearchContainer #search__Field {
      width: calc(100vw - 10px);
      background-color: rgba(255, 255, 255, 0.8); }

.TopMenuContainer {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  box-shadow: var(--shadow);
  background: var(--pageBg); }
  .TopMenuContainer .AdminMenu {
    -webkit-flex-grow: 0;
            flex-grow: 0;
    margin-right: 0.25rem; }
  .TopMenuContainer .LogoutMenu {
    -webkit-flex-grow: 0;
            flex-grow: 0;
    margin-left: auto; }
    .TopMenuContainer .LogoutMenu .Button:hover {
      background-color: var(--primaryBg);
      color: var(--primaryFg); }
  .TopMenuContainer .EditLiveMenu {
    display: none;
    position: relative; }
    @media (max-width: 576px) {
      .TopMenuContainer .EditLiveMenu {
        display: block; } }

.dark-overlay .PullUpMenu:not(.active) #search__Field {
  color: #fff; }
  .dark-overlay .PullUpMenu:not(.active) #search__Field::-webkit-input-placeholder {
    border: 1px solid #fff; }
  .dark-overlay .PullUpMenu:not(.active) #search__Field::placeholder {
    border: 1px solid #fff; }

.SearchHeader {
  font-size: 1.25rem;
  margin: 1rem auto 3rem;
  max-width: 40rem; }

.SearchContainer .formlib-FormRows {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.SearchContainer #search__Field {
  color: var(--textColor);
  max-width: 40rem;
  opacity: 0.75;
  background: transparent;
  border: 1px solid #eaeaea;
  border-radius: 2em;
  transition: margin 0.3s, width 0.3s, opacity 0.5s;
  width: 12rem; }
  @media (max-width: 576px) {
    .SearchContainer #search__Field {
      width: 3rem;
      border: none; } }
  .SearchContainer #search__Field::-webkit-input-placeholder {
    color: #ddd; }
  .SearchContainer #search__Field::placeholder {
    color: #ddd; }
    @media (max-width: 576px) {
      .SearchContainer #search__Field::-webkit-input-placeholder {
        opacity: 0; }
      .SearchContainer #search__Field::placeholder {
        opacity: 0; } }
  .SearchContainer #search__Field.form-input {
    font-weight: 300; }
  .SearchContainer #search__Field:focus {
    opacity: 1; }
    @media (min-width: 576px) {
      .SearchContainer #search__Field:focus {
        width: 15rem; } }

.SearchContainer .sticky-row {
  background: none;
  bottom: auto;
  z-index: 9;
  border-top: none; }
  .SearchContainer .sticky-row .form-nav,
  .SearchContainer .sticky-row form {
    display: none;
    background: rgba(255, 255, 255, 0.8); }

.SearchContainer .form-nav,
.SearchContainer form {
  padding: 8px 0 0;
  background: white; }

.SearchContainer .form-nav {
  -webkit-justify-content: center;
          justify-content: center; }

.SearchContainer .form-row {
  margin-bottom: 5px; }
  .PullUp .SearchContainer .form-row {
    margin-bottom: 0; }

.NavBar {
  /* ----------------------------- */ }
  .NavBar-enter {
    opacity: 0; }
  .NavBar-enter-active {
    transition: opacity 0.5s ease-in; }
  .NavBar-enter-end {
    opacity: 1; }
  .NavBar-leave {
    opacity: 1; }
  .NavBar-leave-active {
    transition: opacity 0.3s ease-in; }
  .NavBar-leave-end {
    opacity: 0; }

.BlogPublicSwitch.SwitchButton {
  --colorSuccess: #74BA6F;
  --colorSuccessHover: #60a75b;
  --colorSuccessLight: #99d195;
  --borderRadius: 0.5rem;
  margin-left: auto;
  margin-right: calc(var(--padHoriz) / 2);
  margin-top: -6px;
  width: 11.5rem;
  font-size: 1.1em; }
  .BlogPublicSwitch.SwitchButton .textDefault {
    padding-left: 43px; }
  .BlogPublicSwitch.SwitchButton.isSelected {
    background-color: var(--colorSuccess); }
    .BlogPublicSwitch.SwitchButton.isSelected svg {
      color: var(--colorSuccessLight); }
    .BlogPublicSwitch.SwitchButton.isSelected .toggle {
      background-color: var(--colorSuccessLight); }
    .BlogPublicSwitch.SwitchButton.isSelected:hover {
      background-color: var(--colorSuccessHover); }

/* New stuff trying to fix sizing when empty */
.SectionPage .List-Container {
  z-index: 0;
  padding-bottom: var(--footerHeight); }

.SectionPage .masonry-item-container.media {
  padding: 0;
  margin: 0 0 var(--gridGap); }

.FormFilter {
  margin: 0 auto; }

.ListPageSection {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative;
  max-width: unset !important;
  width: 100%;
  z-index: 2; }
  .cover .ListPageSection {
    max-width: 100%; }
  .ListPageSection > .toolbar {
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    bottom: 0; }
  .ListPageSection .Hero {
    background-size: cover;
    background-position: center;
    transition: opacity 0.5s ease-in;
    opacity: 0.15; }
    .isLive .ListPageSection .Hero {
      opacity: 1; }
  .ListPageSection .UserNameContainer {
    padding-right: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    margin-top: 5.7rem;
    color: white;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
    min-width: 20rem;
    text-align: center;
    text-overflow: ellipsis;
    opacity: 1;
    transition: opacity 0.3s ease-in; }
    .PullUp .ListPageSection .UserNameContainer {
      opacity: 0; }
    .isLive .ListPageSection .UserNameContainer {
      color: white; }

.LivePage.cover .CollapseMe {
  overflow: visible;
  z-index: 9; }

.LivePage.cover .ListPageSection-BlogPost,
.LivePage.cover .ListPageSection-MediaCard {
  margin-top: calc(-66.6vh + 7rem); }

.LivePage.cover.Profile .ListPageSection-BlogPost {
  margin-top: calc(-66.6vh + 2.5rem); }

.LivePage.cover .Hero {
  height: 100vh;
  width: 100vw;
  background-position: center; }
  @media (min-width: var(--maxHeaderWidth)) {
    .LivePage.cover .Hero {
      margin-left: calc(-50vw + 35rem); } }

.LivePage.cover .AvatarContainer {
  padding: 1rem;
  top: auto;
  left: 0;
  bottom: 3.5rem;
  -webkit-transform: translate(0, 50%);
          transform: translate(0, 50%); }
  @media (min-width: var(--maxHeaderWidth)) {
    .LivePage.cover .AvatarContainer {
      left: calc(-50vw + 35rem); } }
  .LivePage.cover .AvatarContainer .AvatarWrapper {
    width: 5rem;
    height: 5rem; }

.LivePage.cover .UserNameContainer {
  top: auto;
  left: 7rem;
  bottom: calc(1rem + 2.5rem);
  -webkit-transform: translate(0, 50%);
          transform: translate(0, 50%);
  text-align: left;
  margin: 0;
  font-size: 0.7em; }
  .PullUp .LivePage.cover .UserNameContainer {
    opacity: 0; }
  @media (min-width: var(--maxHeaderWidth)) {
    .LivePage.cover .UserNameContainer {
      left: calc(-50vw + 35rem + 7rem); } }

.LivePage.cover .MainMenuContainer {
  display: none; }

@media (min-width: var(--maxHeaderWidth)) {
  .LivePage.cover .PullUpMenu-Background {
    left: calc(-50vw + 35rem);
    width: 100vw; } }

.ListPage.container-fluid {
  margin-left: 0; }

.thumbnail {
  border-radius: 4px; }

.toolbar {
  position: fixed;
  z-index: 9;
  bottom: 0;
  left: 0;
  background: #f7f7f7;
  right: 0;
  text-align: center;
  padding: 0.5rem; }
  .toolbar .action {
    margin: 0 1.5rem; }

.ListPageSection-User,
.ListPageSection-Invitation,
.ListPageSection-Cards,
.ListPageSection-BlogPost {
  display: block;
  position: relative;
  transition: margin-top 0.2s;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  min-height: calc(100vh - 3rem);
  max-width: var(--maxHeaderWidth);
  padding-bottom: 3.5rem; }
  .isLive .ListPageSection-User, .isLive
  .ListPageSection-Invitation, .isLive
  .ListPageSection-Cards, .isLive
  .ListPageSection-BlogPost {
    margin-top: 1.5rem; }
  .ListPageSection-User .live-list-item,
  .ListPageSection-Invitation .live-list-item,
  .ListPageSection-Cards .live-list-item,
  .ListPageSection-BlogPost .live-list-item {
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center; }
    .ListPageSection-User .live-list-item:hover,
    .ListPageSection-Invitation .live-list-item:hover,
    .ListPageSection-Cards .live-list-item:hover,
    .ListPageSection-BlogPost .live-list-item:hover {
      background-color: transparent; }
    .ListPageSection-User .live-list-item h2,
    .ListPageSection-Invitation .live-list-item h2,
    .ListPageSection-Cards .live-list-item h2,
    .ListPageSection-BlogPost .live-list-item h2 {
      font-size: 1.75em; }
    .ListPageSection-User .live-list-item h3,
    .ListPageSection-Invitation .live-list-item h3,
    .ListPageSection-Cards .live-list-item h3,
    .ListPageSection-BlogPost .live-list-item h3 {
      font-size: 1em; }

.ListPageSection-BlogPost .MessageCanvas {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  height: 15rem; }

.Card-RelatedGrid,
.ListPageSection-MediaCard .List-Container {
  --gridGap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  width: 100%;
  padding: var(--padVert) var(--padHoriz);
  margin-right: auto;
  margin-left: auto;
  transition: margin-top 0.3s; }
  .Card-RelatedGrid .list-item,
  .ListPageSection-MediaCard .List-Container .list-item {
    overflow: hidden;
    border-radius: 2px;
    padding: 0;
    margin: 0 0 0.5rem; }
    .Card-RelatedGrid .list-item .thumbnail,
    .ListPageSection-MediaCard .List-Container .list-item .thumbnail {
      object-fit: cover;
      height: 100%;
      border-radius: 0;
      border-radius: 2px; }

.CollapseMe {
  transition: margin 0.6s, opacity 0.2s;
  opacity: 1; }
  .CollapseMe .MainMenu {
    opacity: 1;
    transition: opacity 0.2s; }
  .PullUp .CollapseMe .MainMenu {
    opacity: 0; }

.AvatarContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transition: margin 0.6s;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .AvatarContainer .formlib-FileUploadWidget.Avatar-Field {
    margin: 0 auto;
    overflow: hidden; }
    .AvatarContainer .formlib-FileUploadWidget.Avatar-Field .Onboarding-Avatar {
      margin: 0; }
    .AvatarContainer .formlib-FileUploadWidget.Avatar-Field .buttons {
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .AvatarContainer .formlib-FileUploadWidget.Avatar-Field button.remove {
      display: none; }

.AvatarWrapper {
  width: 15rem;
  height: 15rem;
  margin: 0 auto; }
  .AvatarWrapper.hidden {
    visibility: hidden; }

.Avatar {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-transform-origin: center top;
          transform-origin: center top;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.05); }

.MainMenu-sticky {
  display: none !important;
  position: fixed; }
  .PullUp .MainMenu-sticky {
    display: block;
    left: 50%;
    top: 0;
    margin-left: -50%;
    border-bottom: none; }

.FilterContainer {
  padding: 0 var(--padHoriz);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: var(--padVert); }
  .FilterContainer form {
    width: 100%; }
  .FilterContainer .formlib-FormRows {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-align-items: center;
            align-items: center;
    max-width: var(--maxHeaderWidth); }
    .FilterContainer .formlib-FormRows .form-row {
      margin: 0; }
  .FilterContainer #publishWorkflow__Row {
    margin-right: auto; }
  .FilterContainer #search__Row {
    max-width: 20rem; }
    .FilterContainer #search__Row #search {
      display: none; }
    .FilterContainer #search__Row #search__Field {
      font-size: 1em;
      padding: 0.2rem var(--padHoriz);
      border: 1px solid var(--borderMutedColor);
      border-radius: 2em;
      background-color: var(--pageBg); }
  .FilterContainer .sticky-row {
    background: none;
    bottom: auto;
    z-index: 9;
    border-top: none; }
    .FilterContainer .sticky-row .form-nav,
    .FilterContainer .sticky-row form {
      background: rgba(255, 255, 255, 0.8); }
  .FilterContainer .form-nav,
  .FilterContainer form {
    padding: 8px 0 0;
    background: white; }
  .FilterContainer .form-nav {
    -webkit-justify-content: center;
            justify-content: center; }
  .FilterContainer .form-row {
    margin-bottom: 8px; }

.PullUpMenu {
  display: -webkit-flex;
  display: flex;
  position: fixed;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  z-index: 10;
  top: 0;
  left: 0;
  width: calc(100% - var(--scrollbarWidth) - 2px);
  padding: 0 calc(50vw - var(--maxHeaderWidth) / 2);
  transition: opacity 0.15s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.15s;
  transition: transform 0.3s, opacity 0.15s, -webkit-transform 0.3s;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  opacity: 0;
  background: var(--menuBg);
  box-shadow: var(--shadow); }
  .PullUpMenu .PullUpMenu-Background {
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% + 2px);
    height: 100%;
    background: white;
    z-index: -1; }
  .isLive .PullUpMenu {
    background: rgba(255, 255, 255, 0.85); }
  .PullUpMenu.active {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  .PullUpMenu .MainMenu {
    position: relative;
    margin: 0; }
  .PullUpMenu .AvatarWrapper {
    width: 3rem;
    height: 3rem;
    margin: 0 0 0 0.2rem;
    -webkit-align-self: center;
            align-self: center;
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }
  .PullUpMenu .Avatar {
    width: 100%;
    height: 100%; }
    .PullUpMenu .Avatar-animating {
      transition: opacity 0.6s, -webkit-transform 0.4s;
      transition: transform 0.4s, opacity 0.6s;
      transition: transform 0.4s, opacity 0.6s, -webkit-transform 0.4s; }

.CollapseMe {
  overflow: hidden; }
  .CollapseMe .Hero {
    height: 23.75rem;
    transition: margin 0.6s, opacity 0.4s ease-in; }
    .isLive .CollapseMe .Hero {
      opacity: 1; }

.EditLiveMenu {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0; }
  .MainMenu .EditLiveMenu {
    position: absolute; }
  .PullUp .EditLiveMenu {
    margin-left: 3.5rem; }

.isLive:not(.PullUp) .MainMenu .NavStateButton {
  color: var(--menuBg); }

.isLive .PullUp .MainMenu .NavStateButton {
  color: black; }

.PullUp .MainMenu {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-basis: auto;
          flex-basis: auto;
  width: auto; }

.action-wrapper .placeholder {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: absolute;
  top: -.75rem;
  left: 50%;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
  background: #ededed;
  border-radius: 8px;
  text-align: center;
  width: calc(100vw - 4rem);
  padding: 2rem 2rem 1rem;
  max-width: 30rem;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 10rem;
  color: var(--gray); }
  .action-wrapper .placeholder:after {
    content: " ";
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%);
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #ededed; }

.SectionPage {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-align-items: center;
          align-items: center;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: var(--infernoUXHeaderHeight); }
  .SectionPage > .section:first-child {
    margin-top: -3rem; }
  .SectionPage > * {
    width: 100%;
    max-width: var(--maxHeaderWidth);
    margin: 0 auto; }
  .SectionPage .filter-container {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1; }
  .SectionPage .actionbar-container {
    position: fixed;
    z-index: 2;
    background-color: var(--stickyFooterBg);
    max-width: unset;
    width: calc(100% - var(--scrollbarWidth)); }
    .SectionPage .actionbar-container .ButtonBar {
      margin: 0 auto; }

/*
  StickyRow
*/
.sticky-row-wrapper {
  width: 100%; }
  .sticky-row-wrapper .sticky-row {
    position: absolute;
    z-index: 9; }
  .sticky-row-wrapper .row--hidden {
    visibility: hidden !important; }
  .sticky-row-wrapper .sticky-row--hidden {
    display: none !important; }

.AdminMenu,
.MainMenu {
  --linkHover: #dfe7fa; }
  .AdminMenu .NavStateButton,
  .MainMenu .NavStateButton {
    color: black;
    font-weight: 300; }
    .AdminMenu .NavStateButton:hover,
    .MainMenu .NavStateButton:hover {
      background-color: var(--linkHover); }

.AdminMenu {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  z-index: 9;
  height: var(--headerHeight);
  width: auto; }
  .AdminMenu .AvatarWrapper {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    width: auto;
    height: auto; }
  .AdminMenu .Avatar {
    border-radius: 50%;
    height: calc(var(--headerHeight) - var(--padVert));
    margin: calc(var(--padVert) / 2);
    width: auto; }

.MainMenu .NavSlot {
  width: 0;
  z-index: 9;
  font-size: 1.25em; }

/**
 * Animations
 */
.NavBar {
  /* ----------------------------- */ }
  .NavBar-enter {
    opacity: 0; }
  .NavBar-enter-active {
    transition: opacity 0.5s ease-in; }
  .NavBar-enter-end {
    opacity: 1; }
  .NavBar-leave {
    opacity: 1; }
  .NavBar-leave-active {
    transition: opacity 0.3s ease-in; }
  .NavBar-leave-end {
    opacity: 0; }

.AdminPageTemplate {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100vh;
  overflow: hidden scroll; }
  .AdminPageTemplate > .body {
    width: 100%;
    max-width: var(--maxContentWidth);
    margin: 0 auto;
    padding: var(--padVert) var(--padHoriz);
    -webkit-flex-grow: 1;
            flex-grow: 1; }
  .AdminPageTemplate .actionbar-container {
    position: -webkit-sticky;
    position: sticky;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    background-color: var(--stickyFooterBg); }
    .AdminPageTemplate .actionbar-container .ButtonBar {
      margin: 0 auto; }
  .AdminPageTemplate .filter-container {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%; }
    .AdminPageTemplate .filter-container .formlib-FormRows {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row-reverse;
              flex-direction: row-reverse;
      -webkit-align-items: center;
              align-items: center;
      max-width: var(--maxBodyWidth); }
    .AdminPageTemplate .filter-container .form-row {
      margin-bottom: 0; }
    .AdminPageTemplate .filter-container #search__Row {
      min-width: 15rem;
      -webkit-flex: 0 0;
              flex: 0 0; }
      .AdminPageTemplate .filter-container #search__Row .form-input-label {
        display: none; }
      .AdminPageTemplate .filter-container #search__Row #search__Field {
        border-radius: 2em;
        padding: var(--padVert) calc(var(--padHoriz) * 2);
        border: 1px solid var(--borderMutedColor); }
    .AdminPageTemplate .filter-container .form-nav,
    .AdminPageTemplate .filter-container form {
      padding: 8px 0 0;
      background: var(--stickyHeaderBg); }
    .AdminPageTemplate .filter-container .form-nav {
      -webkit-justify-content: center;
              justify-content: center; }
    .AdminPageTemplate .filter-container .form-group {
      margin-bottom: 8px; }

.ListPage-blog,
.ListPage-user,
.ListPage-invitation {
  transition: margin-top 0.2s; }
  .ListPage-blog > .body,
  .ListPage-user > .body,
  .ListPage-invitation > .body {
    max-width: var(--maxBodyWidth); }
  .ListPage-blog > .actionbar-container,
  .ListPage-user > .actionbar-container,
  .ListPage-invitation > .actionbar-container {
    box-shadow: var(--shadow);
    padding: var(--padVert) var(--padHoriz);
    text-align: center;
    background-color: var(--stickyFooterBg); }
  .ListPage-blog .MessageCanvas,
  .ListPage-user .MessageCanvas,
  .ListPage-invitation .MessageCanvas {
    z-index: -1; }
  .ListPage-blog .ListItem,
  .ListPage-user .ListItem,
  .ListPage-invitation .ListItem {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    padding: 1rem;
    transition: all 0.1s ease-in; }
    .ListPage-blog .ListItem:hover,
    .ListPage-user .ListItem:hover,
    .ListPage-invitation .ListItem:hover {
      background-color: var(--listItemHoverColor); }
    .ListPage-blog .ListItem > .body,
    .ListPage-user .ListItem > .body,
    .ListPage-invitation .ListItem > .body {
      -webkit-flex-grow: 1;
              flex-grow: 1; }
      .ListPage-blog .ListItem > .body a,
      .ListPage-user .ListItem > .body a,
      .ListPage-invitation .ListItem > .body a {
        color: var(--textColor); }
        .ListPage-blog .ListItem > .body a:hover,
        .ListPage-user .ListItem > .body a:hover,
        .ListPage-invitation .ListItem > .body a:hover {
          color: var(--textAccentedColor);
          background: none; }
      .ListPage-blog .ListItem > .body h3,
      .ListPage-user .ListItem > .body h3,
      .ListPage-invitation .ListItem > .body h3 {
        font-size: 0.75em;
        font-weight: 400;
        color: #aaa; }
        .ListPage-blog .ListItem > .body h3 i,
        .ListPage-user .ListItem > .body h3 i,
        .ListPage-invitation .ListItem > .body h3 i {
          font-style: normal;
          font-weight: 700; }
    .ListPage-blog .ListItem .workflow .Icon-Clock,
    .ListPage-user .ListItem .workflow .Icon-Clock,
    .ListPage-invitation .ListItem .workflow .Icon-Clock {
      vertical-align: -3px;
      width: 1em;
      height: 1em;
      margin-right: 0.25rem; }
    .ListPage-blog .ListItem .workflow .status,
    .ListPage-user .ListItem .workflow .status,
    .ListPage-invitation .ListItem .workflow .status {
      text-align: right;
      font-size: 1.5em;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--textSubtleColor);
      margin: 0; }
    .ListPage-blog .ListItem .workflow .actions,
    .ListPage-user .ListItem .workflow .actions,
    .ListPage-invitation .ListItem .workflow .actions {
      font-size: 0.75em; }
      .ListPage-blog .ListItem .workflow .actions button,
      .ListPage-blog .ListItem .workflow .actions button.selected,
      .ListPage-blog .ListItem .workflow .actions button:hover,
      .ListPage-user .ListItem .workflow .actions button,
      .ListPage-user .ListItem .workflow .actions button.selected,
      .ListPage-user .ListItem .workflow .actions button:hover,
      .ListPage-invitation .ListItem .workflow .actions button,
      .ListPage-invitation .ListItem .workflow .actions button.selected,
      .ListPage-invitation .ListItem .workflow .actions button:hover {
        line-height: 1em;
        border-radius: 0.25rem;
        padding: 0.5em 0.5em 0.25em; }
      .ListPage-blog .ListItem .workflow .actions button.selected,
      .ListPage-blog .ListItem .workflow .actions button.selected:hover,
      .ListPage-user .ListItem .workflow .actions button.selected,
      .ListPage-user .ListItem .workflow .actions button.selected:hover,
      .ListPage-invitation .ListItem .workflow .actions button.selected,
      .ListPage-invitation .ListItem .workflow .actions button.selected:hover {
        border-bottom: 3px solid var(--primaryBg); }

.LivePage-Feed {
  max-width: var(--maxContentWidth);
  margin-left: auto;
  margin-right: auto; }
  .LivePage-Feed .InterPost {
    position: relative;
    width: 100%;
    height: 0;
    border-bottom: dashed 2px #f0f0f0;
    margin: 5rem 0; }
    .LivePage-Feed .InterPost::after {
      content: '';
      position: absolute;
      top: -1px;
      left: 50%;
      -webkit-transform: translate(-50%, 0%);
              transform: translate(-50%, 0%);
      width: 3rem;
      height: 1.5rem;
      border-radius: 1.5rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background: radial-gradient(circle, white 0%, #fafafa 65%, #fafafa 100%);
      background: white;
      border: 3px dashed whitesmoke;
      border-top: none; }
    .LivePage-Feed .InterPost .placeholder {
      position: relative;
      top: 0;
      left: 0;
      -webkit-transform: none;
              transform: none; }

.IPublicForm {
  padding-left: var(--padHoriz);
  padding-right: var(--padHoriz); }
  .IPublicForm .Gallery.one_two_horizontal, .IPublicForm .Gallery.one_four_horizontal {
    margin-left: -4rem; }
  @media (max-width: 576px) {
    .IPublicForm .Gallery {
      margin-left: -var(--padHoriz);
      margin-right: -var(--padHoriz); }
      .IPublicForm .Gallery.one_two_horizontal, .IPublicForm .Gallery.one_four_horizontal {
        margin-left: 0; } }

.ListPageSection-MediaCard {
  min-height: calc(100vh - 3rem); }
  .ListPageSection-MediaCard .masonry {
    text-align: center;
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }
  .ListPageSection-MediaCard .masonry-col {
    max-width: 320px; }

.LivePage.Profile .Filter {
  -webkit-justify-content: center;
          justify-content: center; }

.LivePage.Profile .profile-post {
  padding-top: 5rem; }

.LivePage.Profile .ListPage {
  margin-bottom: 0; }

.LivePage.Profile .ListPage-BlogPost {
  margin-top: 0;
  min-height: calc(100vh - 3rem); }

.LivePage.Profile .IEditItem {
  padding: 0; }

.LivePage.Profile .Profile-Destinations,
.LivePage.Profile .Profile-Recipes,
.LivePage.Profile .Profile-Guides {
  max-width: 40rem;
  margin: 2rem auto 0; }
  .LivePage.Profile .Profile-Destinations h2,
  .LivePage.Profile .Profile-Recipes h2,
  .LivePage.Profile .Profile-Guides h2 {
    font-size: 1.2em; }

.PageNavbar {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--white);
  margin-left: -15px;
  text-align: center;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25); }
  .PageNavbar .Actions {
    max-width: 80rem;
    width: 100%;
    height: 3.25rem;
    margin: 0 auto; }
  .PageNavbar .btn.CloseButton {
    -webkit-flex-grow: 0;
            flex-grow: 0;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    width: 6rem;
    margin-right: auto; }
  .PageNavbar .spacer {
    width: 6rem;
    margin-left: auto; }

.ViewPage > .content {
  padding-top: 3.25rem; }

.Gallery {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end; }
  .Gallery.form-row {
    flex-direction: row;
    max-width: var(--maxContentWidth);
    margin: 0 auto var(--formRowGap); }
    .Gallery.form-row .imgPlaceholder {
      background-color: var(--iconMutedFill);
      height: auto;
      width: 100%; }
  .Gallery .image,
  .Gallery img {
    cursor: pointer;
    -o-object-fit: cover;
       object-fit: cover;
    position: relative; }
    .Gallery .image i,
    .Gallery img i {
      content: attr(data-show-more);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: 'Times New Roman', Times, serif;
      font-weight: 400;
      font-size: 4em;
      color: white;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); }
  .Gallery .image {
    display: flex;
    overflow: hidden;
    max-height: 50vh; }
    .Gallery .image img {
      height: 100%; }
    .Gallery .image i {
      pointer-events: none; }
  .Gallery.cover {
    align-items: unset; }
    .Gallery.cover img {
      -o-object-fit: cover;
         object-fit: cover; }
  .Gallery.one .image {
    max-height: 90vh;
    width: 100%; }
    .Gallery.one .image img {
      -o-object-fit: contain;
         object-fit: contain;
      max-height: 90vh; }
  .Gallery.two .image .imgPlaceholder {
    min-height: 40vh; }
  .Gallery.two .image:nth-child(1),
  .Gallery.two .image:nth-child(2) {
    width: calc(50% - 1px); }
  .Gallery.one_two .image:nth-child(1) {
    width: 100%; }
    .Gallery.one_two .image:nth-child(1) .imgPlaceholder {
      max-height: 35vh; }
  .Gallery.one_two .image:nth-child(2),
  .Gallery.one_two .image:nth-child(3) {
    width: calc(50% - 1px);
    max-height: 25vh; }
  .Gallery.one_three .image:nth-child(1) {
    width: 100%; }
  .Gallery.one_three .image:nth-child(2),
  .Gallery.one_three .image:nth-child(3),
  .Gallery.one_three .image:nth-child(4) {
    width: calc(33.33% - 2px);
    max-height: 25vh; }
  .Gallery.one_two_horizontal {
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 55% 45%;
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    width: calc(100% + 8rem);
    max-width: 100vw; }
    .Lightbox .Gallery.one_two_horizontal {
      width: 100%; }
    .Gallery.one_two_horizontal .image:nth-child(1) {
      grid-row: 1 / -1;
      max-height: 100%; }
      .Gallery.one_two_horizontal .image:nth-child(1) img {
        max-height: 100%; }
  .Gallery.one_four_horizontal {
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 40% 30% 30%;
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    width: calc(100% + 8rem);
    max-width: 100vw; }
    .Lightbox .Gallery.one_four_horizontal {
      width: 100%; }
    .Gallery.one_four_horizontal .image:nth-child(1) {
      grid-row: 1 / -1;
      max-height: 100%; }
      .Gallery.one_four_horizontal .image:nth-child(1) .imgPlaceholder {
        min-height: 40vh; }
      .Gallery.one_four_horizontal .image:nth-child(1) img {
        max-height: 100%; }

.Card {
  max-width: var(--maxContentWidth); }
  .Card caption {
    font-size: 0.85em;
    padding: var(--padVert) var(--padHoriz);
    text-align: left;
    margin: 1em auto 2em; }
  .Card h2 {
    margin-top: 0.25em;
    align-self: center;
    text-align: center; }
    @media (max-width: 576px) {
      .Card h2 {
        text-align: center; } }
  .Card h3 {
    width: 100%;
    font-size: 1.2em;
    margin-bottom: 1em;
    text-align: center; }

.EditModal,
.Modal .Lightbox,
.content .Lightbox {
  overflow: hidden;
  height: 100vh;
  max-width: unset;
  border-radius: 0;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden; }
  .EditModal > .header,
  .Modal .Lightbox > .header,
  .content .Lightbox > .header {
    position: sticky;
    padding: var(--padVert) var(--padHoriz);
    top: 0;
    z-index: 9;
    background: var(--menuBg); }
  .EditModal .page,
  .Modal .Lightbox .page,
  .content .Lightbox .page {
    border-radius: 0; }
  .EditModal .card-form-container,
  .Modal .Lightbox .card-form-container,
  .content .Lightbox .card-form-container {
    /*  use this container to make sure we can scroll forms
        that contain a image drop bar so it disappears */
    min-height: calc(100vh + 7rem); }
  .EditModal form > .footer.fixed,
  .Modal .Lightbox form > .footer.fixed,
  .content .Lightbox form > .footer.fixed {
    width: 100%; }
  .EditModal form > .footer-sticky,
  .Modal .Lightbox form > .footer-sticky,
  .content .Lightbox form > .footer-sticky {
    padding-top: 4px; }
  .EditModal form > .footer,
  .EditModal form > .footer-sticky > .footer,
  .Modal .Lightbox form > .footer,
  .Modal .Lightbox form > .footer-sticky > .footer,
  .content .Lightbox form > .footer,
  .content .Lightbox form > .footer-sticky > .footer {
    box-shadow: var(--shadow);
    z-index: 9; }
    .EditModal form > .footer .formlib-FormRows,
    .EditModal form > .footer-sticky > .footer .formlib-FormRows,
    .Modal .Lightbox form > .footer .formlib-FormRows,
    .Modal .Lightbox form > .footer-sticky > .footer .formlib-FormRows,
    .content .Lightbox form > .footer .formlib-FormRows,
    .content .Lightbox form > .footer-sticky > .footer .formlib-FormRows {
      max-width: 100%;
      margin: 0 auto; }
      .EditModal form > .footer .formlib-FormRows > .form-row.form-row-list,
      .EditModal form > .footer-sticky > .footer .formlib-FormRows > .form-row.form-row-list,
      .Modal .Lightbox form > .footer .formlib-FormRows > .form-row.form-row-list,
      .Modal .Lightbox form > .footer-sticky > .footer .formlib-FormRows > .form-row.form-row-list,
      .content .Lightbox form > .footer .formlib-FormRows > .form-row.form-row-list,
      .content .Lightbox form > .footer-sticky > .footer .formlib-FormRows > .form-row.form-row-list {
        margin-bottom: 0; }
    .EditModal form > .footer label#images,
    .EditModal form > .footer-sticky > .footer label#images,
    .Modal .Lightbox form > .footer label#images,
    .Modal .Lightbox form > .footer-sticky > .footer label#images,
    .content .Lightbox form > .footer label#images,
    .content .Lightbox form > .footer-sticky > .footer label#images {
      display: none; }
  .EditModal form > .body .formlib-FormRows,
  .Modal .Lightbox form > .body .formlib-FormRows,
  .content .Lightbox form > .body .formlib-FormRows {
    max-width: var(--maxFormWidth);
    margin: 0 auto; }
  .EditModal .EditPreview,
  .Modal .Lightbox .EditPreview,
  .content .Lightbox .EditPreview {
    margin-bottom: calc(-1rem + 3px); }
  .EditModal .modal-header,
  .Modal .Lightbox .modal-header,
  .content .Lightbox .modal-header {
    position: fixed;
    justify-content: center;
    top: 0;
    left: 0rem;
    height: 3rem;
    z-index: 99;
    width: 100%;
    background: rgba(255, 255, 255, 0.8); }
  .EditModal .modal-title,
  .Modal .Lightbox .modal-title,
  .content .Lightbox .modal-title {
    align-self: center;
    margin-right: auto; }
  .EditModal .modal-content .modal-body,
  .Modal .Lightbox .modal-content .modal-body,
  .content .Lightbox .modal-content .modal-body {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    top: 0;
    padding: 3rem 0;
    overflow-x: hidden; }
    .EditModal .modal-content .modal-body > h2, .EditModal .modal-content .modal-body > h3,
    .Modal .Lightbox .modal-content .modal-body > h2,
    .Modal .Lightbox .modal-content .modal-body > h3,
    .content .Lightbox .modal-content .modal-body > h2,
    .content .Lightbox .modal-content .modal-body > h3 {
      text-align: center; }
  .EditModal .media,
  .Modal .Lightbox .media,
  .content .Lightbox .media {
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto; }
  .EditModal .modal-footer,
  .Modal .Lightbox .modal-footer,
  .content .Lightbox .modal-footer {
    position: fixed;
    bottom: 3rem;
    left: 0;
    right: 0;
    transform: translateY(100%);
    height: 3rem;
    max-height: calc(100vh - 3rem);
    overflow-y: scroll;
    left: 0;
    border-radius: 0;
    transition: all 0.3s ease-in;
    background: rgba(255, 255, 255, 0.8); }
    .EditModal .modal-footer.expand,
    .Modal .Lightbox .modal-footer.expand,
    .content .Lightbox .modal-footer.expand {
      height: 100%;
      transform: translateY(0); }
    .EditModal .modal-footer p,
    .Modal .Lightbox .modal-footer p,
    .content .Lightbox .modal-footer p {
      margin-right: auto; }
  .EditModal.MediaLightbox .image,
  .Modal .Lightbox.MediaLightbox .image,
  .content .Lightbox.MediaLightbox .image {
    display: block;
    margin-bottom: 1rem; }
  .EditModal .image:last-child,
  .Modal .Lightbox .image:last-child,
  .content .Lightbox .image:last-child {
    margin-bottom: 0; }
  .EditModal .image-list,
  .Modal .Lightbox .image-list,
  .content .Lightbox .image-list {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .EditModal .lightbox-image,
  .Modal .Lightbox .lightbox-image,
  .content .Lightbox .lightbox-image {
    max-width: 100%;
    max-height: calc(100vh - 6rem);
    width: auto; }
  .EditModal .formlib-FormRows .form-row,
  .Modal .Lightbox .formlib-FormRows .form-row,
  .content .Lightbox .formlib-FormRows .form-row {
    max-width: var(--maxFormWidth); }
  .EditModal .formlib-FormRows #connectedTitle__Row,
  .Modal .Lightbox .formlib-FormRows #connectedTitle__Row,
  .content .Lightbox .formlib-FormRows #connectedTitle__Row {
    margin-top: -1.5rem; }
    .EditModal .formlib-FormRows #connectedTitle__Row .form-input-help,
    .Modal .Lightbox .formlib-FormRows #connectedTitle__Row .form-input-help,
    .content .Lightbox .formlib-FormRows #connectedTitle__Row .form-input-help {
      line-height: 1.2em;
      font-size: 0.8em; }

.MediaCardModal > form > .body,
.PlaceCardModal > form > .body,
.CityGuideModal > form > .body,
.RecipeModal > form > .body,
.ListCardModal > form > .body {
  padding-bottom: 10rem; }

.CardEditButton {
  position: absolute;
  top: 0.5rem;
  right: 0; }

.MediaCard h2.hidden,
.PlaceCard h2.hidden,
.CityGuideCard h2.hidden,
.RecipeCard h2.hidden,
.ListCard h2.hidden {
  display: none; }

.MediaCard h2.hideableInEditor,
.PlaceCard h2.hideableInEditor,
.CityGuideCard h2.hideableInEditor,
.RecipeCard h2.hideableInEditor,
.ListCard h2.hideableInEditor {
  max-height: 5rem;
  transition: all 0.3s ease-out;
  overflow: hidden; }
  .hideCardTitle .MediaCard h2.hideableInEditor, .hideCardTitle
  .PlaceCard h2.hideableInEditor, .hideCardTitle
  .CityGuideCard h2.hideableInEditor, .hideCardTitle
  .RecipeCard h2.hideableInEditor, .hideCardTitle
  .ListCard h2.hideableInEditor {
    max-height: 0;
    margin-top: 0;
    margin-bottom: 0;
    transition: all 0.3s ease-in; }

.EditPreview > * {
  margin: var(--padVert) auto; }

.HeroContainer {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #222423;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  margin-top: -6rem; }
  .HeroContainer .HeroBody {
    padding-top: 6rem;
    text-align: center; }
    .HeroContainer .HeroBody #Page-Logo {
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(calc(-50% + 8px));
              transform: translateX(calc(-50% + 8px)); }
    .HeroContainer .HeroBody h2 {
      font-size: 2.5em;
      -webkit-transform: rotate(2deg);
              transform: rotate(2deg);
      font-weight: 100;
      margin-bottom: 0.2rem;
      height: 3.4rem; }
    .HeroContainer .HeroBody p {
      font-size: 1.4em;
      font-weight: 100;
      margin-bottom: -0.2rem; }
    .HeroContainer .HeroBody .btn {
      font-size: 1.4em;
      font-weight: 100;
      padding: 0;
      background: transparent;
      border: none;
      margin: 1em 0; }
    .HeroContainer .HeroBody .btn:hover {
      background: transparent;
      border: none; }
    @media (max-width: 576px) {
      .HeroContainer .HeroBody h2 {
        font-size: 2em; }
      .HeroContainer .HeroBody p {
        font-size: 1em; } }
  .HeroContainer .HeroBody-Stripe {
    display: inline-block;
    font-family: 'Open Sans', Roboto, sans-serif;
    text-align: center;
    padding: 0.1rem 0.4rem 0.3rem;
    transition: box-shadow 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
    transition: transform 0.2s ease-out, box-shadow 0.2s ease-out, -webkit-transform 0.2s ease-out;
    /* Image specific */
    background-color: #cca64659;
    color: #fff; }
  .HeroContainer .btn:hover .HeroBody-Stripe {
    box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.32);
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
    background-color: #deaa2659; }

.PageFooter {
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: black;
  width: 100%;
  margin-top: auto; }

.PageFooterBody {
  font-weight: 100;
  color: #faddb4;
  font-size: 0.75em;
  padding: 0.5em;
  line-height: 1.25em; }
  @media (max-width: 576px) {
    .PageFooterBody {
      padding: 0.5em;
      font-size: 0.7em; } }

.ErrorPage .Button {
  margin-top: 2rem; }

.StartPage #Inline-Logo {
  width: 7rem; }

.StartPage .LogoutMenu {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99; }

.StartPage .Hero-Influencer {
  position: relative; }

.StartPage .HeroContainer {
  background-color: black;
  width: 100%; }

.StartPage #Page-Logo {
  width: 16rem;
  margin-bottom: 1rem;
  z-index: 999; }
  @media (max-width: 576px) {
    .StartPage #Page-Logo {
      width: 12rem; } }

.StartPage .HeroBody-Stripe {
  padding-right: 2rem;
  padding-left: 2rem;
  transition: all 1.2s ease-in-out; }
  .StartPage .HeroBody-Stripe.Tagline_0 {
    background-color: #F3BD9B59;
    color: #fff; }
  .StartPage .HeroBody-Stripe.Tagline_0 {
    background-color: #7890B259;
    color: #fff; }
  .StartPage .HeroBody-Stripe.Tagline_2 {
    background-color: #0A3E6159;
    color: #fff; }
  @media (max-width: 576px) {
    .StartPage .HeroBody-Stripe {
      -webkit-transform: scale(0.8) translateY(-85%);
              transform: scale(0.8) translateY(-85%); } }

.StartPage .HeroOverlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)); }

.StartPage .HeroContainer .Hero-Influencer.HeroBody {
  z-index: 9;
  position: absolute;
  bottom: 12%;
  margin-top: 0; }

.StartPage-HeroImagesContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: -webkit-flex;
  display: flex;
  margin-right: -12px;
  max-width: 150vw; }

.StartPage-HeroImageCol {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: -12px;
  margin-right: 12px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  max-width: calc(100vw/2); }
  .StartPage-HeroImageCol .StartPage-HeroImage {
    position: relative;
    margin-bottom: 12px;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    min-height: 20%; }
    .StartPage-HeroImageCol .StartPage-HeroImage img {
      display: block; }
      .StartPage-HeroImageCol .StartPage-HeroImage img.visible {
        height: 100%;
        position: absolute;
        object-fit: cover; }
      .StartPage-HeroImageCol .StartPage-HeroImage img.hidden {
        width: auto;
        height: 100%;
        visibility: hidden; }

/*
 Animations
 */
.FrontTopLeft--Animation-leave,
.FrontTop--Animation-leave,
.FrontTopRight--Animation-leave,
.FrontBottomLeft--Animation-leave,
.FrontBottom--Animation-leave,
.FrontBottomRight--Animation-leave {
  opacity: 1; }

.FrontTopLeft--Animation-leave-active,
.FrontTop--Animation-leave-active,
.FrontTopRight--Animation-leave-active,
.FrontBottomLeft--Animation-leave-active,
.FrontBottom--Animation-leave-active,
.FrontBottomRight--Animation-leave-active {
  transition: all 1.1s ease-in; }

.FrontTopLeft--Animation-enter-active,
.FrontTop--Animation-enter-active,
.FrontTopRight--Animation-enter-active,
.FrontBottomLeft--Animation-enter-active,
.FrontBottom--Animation-enter-active,
.FrontBottomRight--Animation-enter-active {
  transition: all 1.5s ease-out; }

.FrontTopLeft--Animation-enter-end,
.FrontTop--Animation-enter-end,
.FrontTopRight--Animation-enter-end,
.FrontBottomLeft--Animation-enter-end,
.FrontBottom--Animation-enter-end,
.FrontBottomRight--Animation-enter-end {
  opacity: 1; }

.FrontTopLeft--Animation-enter, .FrontTopLeft--Animation-leave-end {
  -webkit-transform: translate(-60%, -60%);
          transform: translate(-60%, -60%);
  opacity: 0; }

.FrontTop--Animation-enter, .FrontTop--Animation-leave-end {
  -webkit-transform: translate(0, -60%);
          transform: translate(0, -60%);
  opacity: 0; }

.FrontTopRight--Animation-enter, .FrontTopRight--Animation-leave-end {
  -webkit-transform: translate(60%, -60%);
          transform: translate(60%, -60%);
  opacity: 0; }

.FrontBottomLeft--Animation-enter, .FrontBottomLeft--Animation-leave-end {
  -webkit-transform: translate(-60%, 60%);
          transform: translate(-60%, 60%);
  opacity: 0; }

.FrontBottom--Animation-enter, .FrontBottom--Animation-leave-end {
  -webkit-transform: translate(0%, 60%);
          transform: translate(0%, 60%);
  opacity: 0; }

.FrontBottomRight--Animation-enter, .FrontBottomRight--Animation-leave-end {
  -webkit-transform: translate(60%, 60%);
          transform: translate(60%, 60%);
  opacity: 0; }

.TypeWriter--Animation-leave {
  opacity: 1; }

.TypeWriter--Animation-leave-active {
  transition: all 1.1s ease-in; }

.TypeWriter--Animation-enter, .TypeWriter--Animation-leave-end {
  opacity: 1; }

.TypeWriter--Animation-enter-active {
  transition: all 1.5s ease-out; }

.TypeWriter--Animation-enter-end {
  opacity: 1; }

.Onboarding,
.ContentPage.Onboarding {
  background-color: var(--pageBg);
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }
  .Onboarding #Page-Logo,
  .ContentPage.Onboarding #Page-Logo {
    position: -webkit-sticky;
    position: sticky;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1rem var(--padHoriz) var(--padVert);
    background-color: var(--menuBg);
    opacity: 1;
    transition: 0.2s ease-in;
    text-align: center; }
    .Onboarding #Page-Logo .logo,
    .ContentPage.Onboarding #Page-Logo .logo {
      display: inline-block;
      margin-left: 12px;
      max-width: 13rem;
      width: 100%; }
  .Onboarding .Onboarding-Content,
  .ContentPage.Onboarding .Onboarding-Content {
    width: 100%;
    max-width: var(--maxFormWidth);
    margin: 0 auto;
    justify-self: center; }
    .Onboarding .Onboarding-Content .body,
    .ContentPage.Onboarding .Onboarding-Content .body {
      padding-bottom: var(--footerHeight); }
    .Onboarding .Onboarding-Content .section.has-columns,
    .ContentPage.Onboarding .Onboarding-Content .section.has-columns {
      grid-gap: calc(4 * var(--gapHoriz));
      gap: calc(4 * var(--gapHoriz)); }
  .Onboarding h1,
  .ContentPage.Onboarding h1 {
    text-align: center; }
  .Onboarding h2,
  .ContentPage.Onboarding h2 {
    margin: 2.5rem 0 2rem;
    text-align: center;
    width: 100%; }
  .Onboarding h3,
  .ContentPage.Onboarding h3 {
    text-align: center;
    font-size: 1.5em;
    width: 100%; }
  .Onboarding h4,
  .ContentPage.Onboarding h4 {
    font-size: 1em; }
  .Onboarding .Onboarding-Link-Button,
  .ContentPage.Onboarding .Onboarding-Link-Button {
    margin: 1rem auto; }
  .Onboarding .Onboarding-ActionContainer,
  .ContentPage.Onboarding .Onboarding-ActionContainer {
    display: -webkit-flex;
    display: flex;
    padding: 2rem 0 0.5rem;
    margin: 0 auto;
    max-width: 35rem; }
    @media (min-width: 576px) {
      .Onboarding .Onboarding-ActionContainer,
      .ContentPage.Onboarding .Onboarding-ActionContainer {
        padding-top: 4rem; } }
  .Onboarding .Onboarding-Action-Button,
  .ContentPage.Onboarding .Onboarding-Action-Button {
    min-width: 15rem; }
  .Onboarding .AvatarImageFieldWidget,
  .ContentPage.Onboarding .AvatarImageFieldWidget {
    margin: 3em 0 2em; }
  .Onboarding .Onboarding-Avatar,
  .ContentPage.Onboarding .Onboarding-Avatar {
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    width: auto; }
  .Onboarding .Onboarding-AcceptTerms,
  .ContentPage.Onboarding .Onboarding-AcceptTerms {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0.5rem;
    text-align: center; }
    @media (min-width: 576px) {
      .Onboarding .Onboarding-AcceptTerms,
      .ContentPage.Onboarding .Onboarding-AcceptTerms {
        -webkit-flex-direction: row;
                flex-direction: row;
        border-top: 1px solid #eee; }
        .Onboarding .Onboarding-AcceptTerms .form-row,
        .ContentPage.Onboarding .Onboarding-AcceptTerms .form-row {
          margin-bottom: 0.5rem; } }
    .Onboarding .Onboarding-AcceptTerms .form-row,
    .ContentPage.Onboarding .Onboarding-AcceptTerms .form-row {
      position: relative; }
    .Onboarding .Onboarding-AcceptTerms .form-input-checkbox,
    .ContentPage.Onboarding .Onboarding-AcceptTerms .form-input-checkbox {
      position: absolute;
      top: 50%;
      left: 0;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      margin: 0; }
    .Onboarding .Onboarding-AcceptTerms .form-input-label,
    .ContentPage.Onboarding .Onboarding-AcceptTerms .form-input-label {
      margin-left: 1.5rem;
      vertical-align: 1px; }

.OnboardingPageNav {
  /* ----------------------------- */ }
  .OnboardingPageNav-enter .Onboarding-Content {
    opacity: 0;
    -webkit-transform: translateX(50%);
            transform: translateX(50%); }
  .OnboardingPageNav-enter-active .Onboarding-Content {
    transition: opacity 0.4s ease-out, -webkit-transform 0.5s ease-in;
    transition: opacity 0.4s ease-out, transform 0.5s ease-in;
    transition: opacity 0.4s ease-out, transform 0.5s ease-in, -webkit-transform 0.5s ease-in; }
  .OnboardingPageNav-enter-end .Onboarding-Content {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .OnboardingPageNav-leave .Onboarding-Content {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .OnboardingPageNav-leave-active {
    position: absolute;
    top: 0;
    width: 100%; }
    .OnboardingPageNav-leave-active .Onboarding-Content {
      transition: opacity .4s ease-out, -webkit-transform 0.5s ease-in;
      transition: opacity .4s ease-out, transform 0.5s ease-in;
      transition: opacity .4s ease-out, transform 0.5s ease-in, -webkit-transform 0.5s ease-in; }
  .OnboardingPageNav-leave-end .Onboarding-Content {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }

.Onboarding > .body,
.ContentPage.Onboarding > .body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 50rem;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .Onboarding > .body .column,
  .ContentPage.Onboarding > .body .column {
    max-width: 35rem;
    margin-left: auto;
    margin-right: auto; }

.Onboarding .keep-together,
.ContentPage.Onboarding .keep-together {
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
  display: block; }

.Onboarding .Onboarding-ActionBar,
.ContentPage.Onboarding .Onboarding-ActionBar {
  background-color: var(--pageBg);
  width: 100%;
  text-align: center; }
  .Onboarding .Onboarding-ActionBar.sticky-vert,
  .ContentPage.Onboarding .Onboarding-ActionBar.sticky-vert {
    margin-bottom: 0;
    border-top: 1px solid #eee; }
  .Onboarding .Onboarding-ActionBar .formlib-FormRows,
  .ContentPage.Onboarding .Onboarding-ActionBar .formlib-FormRows {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0 var(--padHoriz);
    grid-gap: 2rem;
    gap: 2rem;
    text-align: center;
    width: 100%; }
    .Onboarding .Onboarding-ActionBar .formlib-FormRows .form-row,
    .ContentPage.Onboarding .Onboarding-ActionBar .formlib-FormRows .form-row {
      margin-bottom: 0; }
  .Onboarding .Onboarding-ActionBar button,
  .ContentPage.Onboarding .Onboarding-ActionBar button {
    margin: var(--padVert) var(--padHoriz);
    padding: 1rem;
    min-width: 15rem; }
  .Onboarding .Onboarding-ActionBar .form-row,
  .ContentPage.Onboarding .Onboarding-ActionBar .form-row {
    -webkit-flex-grow: 0;
            flex-grow: 0; }
    .Onboarding .Onboarding-ActionBar .form-row label,
    .ContentPage.Onboarding .Onboarding-ActionBar .form-row label {
      margin-bottom: 0; }
  @media (min-width: 576px) {
    .Onboarding .Onboarding-ActionBar,
    .ContentPage.Onboarding .Onboarding-ActionBar {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .Onboarding .Onboarding-ActionBar .form-row,
      .ContentPage.Onboarding .Onboarding-ActionBar .form-row {
        margin-bottom: 0.5rem; } }

.Onboarding .Onboarding-Hero,
.ContentPage.Onboarding .Onboarding-Hero {
  position: absolute;
  display: block;
  width: 100%;
  height: 22rem;
  top: 0;
  left: 0;
  background-color: #eee;
  border: none; }

.Onboarding .Onboarding-Hero-Field,
.ContentPage.Onboarding .Onboarding-Hero-Field {
  position: absolute;
  display: block;
  width: 100%;
  height: 22rem;
  top: 0;
  left: 0; }
  .Onboarding .Onboarding-Hero-Field .upload,
  .ContentPage.Onboarding .Onboarding-Hero-Field .upload {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: absolute;
    top: 0rem;
    right: 0rem;
    width: 15rem;
    height: 10rem;
    color: var(--iconFill);
    font-weight: 400; }
    .Onboarding .Onboarding-Hero-Field .upload .IconButton,
    .ContentPage.Onboarding .Onboarding-Hero-Field .upload .IconButton {
      color: var(--iconInvertedFill); }
      .Onboarding .Onboarding-Hero-Field .upload .IconButton:hover,
      .ContentPage.Onboarding .Onboarding-Hero-Field .upload .IconButton:hover {
        color: var(--iconHover); }
      .Onboarding .Onboarding-Hero-Field .upload .IconButton:active,
      .ContentPage.Onboarding .Onboarding-Hero-Field .upload .IconButton:active {
        color: var(--iconActive); }

.Onboarding .HeroContainer,
.ContentPage.Onboarding .HeroContainer {
  position: relative;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  margin-top: 0;
  width: 100%;
  height: 23.75rem;
  max-width: var(--maxHeaderWidth); }
  .Onboarding .HeroContainer .Hero,
  .ContentPage.Onboarding .HeroContainer .Hero {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center; }
  .Onboarding .HeroContainer .Hero-Field,
  .ContentPage.Onboarding .HeroContainer .Hero-Field {
    height: 100%; }

.Onboarding .Hero-Field.formlib-FileUploadWidget .upload,
.ContentPage.Onboarding .Hero-Field.formlib-FileUploadWidget .upload {
  color: white; }
  .Onboarding .Hero-Field.formlib-FileUploadWidget .upload .IconButton,
  .ContentPage.Onboarding .Hero-Field.formlib-FileUploadWidget .upload .IconButton {
    color: white; }
    .Onboarding .Hero-Field.formlib-FileUploadWidget .upload .IconButton:hover,
    .ContentPage.Onboarding .Hero-Field.formlib-FileUploadWidget .upload .IconButton:hover {
      color: var(--iconHover); }
    .Onboarding .Hero-Field.formlib-FileUploadWidget .upload .IconButton:active,
    .ContentPage.Onboarding .Hero-Field.formlib-FileUploadWidget .upload .IconButton:active {
      color: var(--iconActive); }

.Onboarding .Presentation,
.ContentPage.Onboarding .Presentation {
  padding: 0 var(--padHoriz); }
  .Onboarding .Presentation .select-template,
  .ContentPage.Onboarding .Presentation .select-template {
    padding: 1rem 1rem; }
  .Onboarding .Presentation label#title,
  .ContentPage.Onboarding .Presentation label#title {
    display: none; }
  .Onboarding .Presentation h3,
  .ContentPage.Onboarding .Presentation h3 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    text-align: left;
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 1rem; }
    .Onboarding .Presentation h3 button,
    .ContentPage.Onboarding .Presentation h3 button {
      margin-left: auto;
      font-size: 1em; }
    .Onboarding .Presentation h3:hover,
    .ContentPage.Onboarding .Presentation h3:hover {
      background-color: var(--listItemHoverColor); }
  .Onboarding .Presentation ul,
  .ContentPage.Onboarding .Presentation ul {
    padding: 0;
    margin: 0; }
    .Onboarding .Presentation ul li,
    .ContentPage.Onboarding .Presentation ul li {
      list-style: none;
      font-size: 0.85em;
      line-height: 1.75em; }

.Onboarding.EditModal .IEditItem {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  padding: calc(var(--headerHeight) + 2rem) 0 var(--footerHeight);
  min-height: calc(100% - var(--footerHeight)); }

.Onboarding.EditModal .formlib-FormRows {
  margin-top: 0; }

.Onboarding.EditModal .form-input-label#title,
.Onboarding.EditModal .form-input-label#body {
  display: none; }

.Onboarding.EditModal .form-input#title__Field {
  font-size: 1.5em; }

.Onboarding.EditModal .NavBar {
  position: fixed;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: var(--headerHeight);
  max-width: var(--maxHeaderWidth);
  padding: 0 var(--padHoriz);
  z-index: 9;
  border-bottom: 1px solid var(--inputBorderColor); }

.Onboarding.EditModal .MobiledocToolbar {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 9;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: calc(var(--headerHeight) - 1px);
  max-width: var(--maxContentWidth);
  padding: 0 var(--padHoriz);
  background-color: var(--pageBg); }
  .Onboarding.EditModal .MobiledocToolbar .leading,
  .Onboarding.EditModal .MobiledocToolbar .trailing {
    display: none; }

.Onboarding.EditModal .ActionsToolbar {
  position: fixed;
  bottom: 0; }
  .Onboarding.EditModal .ActionsToolbar .Toolbar {
    border-top: 1px solid var(--inputBorderColor);
    background-color: var(--pageBg); }

.Onboarding.EditModal .ButtonBar.SaveActions {
  padding: var(--padVert) var(--padHoriz); }
  .Onboarding.EditModal .ButtonBar.SaveActions button:first-child {
    margin-right: 1rem; }

.ContentPage {
  display: -webkit-flex;
  display: flex;
  position: relative;
  height: 100vh;
  overflow-y: auto;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 6rem;
  width: 100%;
  margin: 0 auto; }
  @media (max-width: 576px) {
    .ContentPage {
      padding-top: 3.5rem; } }
  .ContentPage > form > .body
.ContentPage > .body {
    max-width: var(--maxContentWidth);
    margin: 1rem auto;
    padding: 0 var(--padHoriz); }
  .ContentPage .section {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: var(--maxContentWidth);
    margin: 0 auto; }
    .ContentPage .section > * {
      -webkit-flex-grow: 1;
              flex-grow: 1;
      -webkit-flex-basis: 0;
              flex-basis: 0; }
    @media (min-width: 576px) {
      .ContentPage .section {
        -webkit-flex-direction: row;
                flex-direction: row;
        grid-gap: 3rem;
        gap: 3rem; } }
  .ContentPage #Page-Logo {
    position: absolute;
    z-index: 9;
    left: 50%;
    -webkit-transform: translateX(calc(-50% + 8px));
            transform: translateX(calc(-50% + 8px));
    width: 8rem;
    top: 1rem;
    margin: 0;
    opacity: 1;
    transition: 0.2s ease-in; }
  .ContentPage h2 {
    margin: 2.5rem 0 2rem;
    text-align: center;
    width: 100%; }
  .ContentPage h3 {
    margin: 2.5rem 0 2rem;
    font-size: 1.85em;
    text-align: center;
    width: 100%; }
  .ContentPage .container-fluid {
    max-width: 50rem; }
    .ContentPage .container-fluid .col {
      max-width: 35rem;
      margin-left: auto;
      margin-right: auto; }
  .ContentPage .Link-Button {
    margin: 1rem auto; }

.PrivacyPolicy > .content {
  max-width: var(--maxContentWidthNarrow); }

.App {
  min-width: 300px;
  max-width: 100%;
  overflow-x: hidden;
  background: white; }

a,
a:hover,
a:link,
a:active {
  color: var(--linkFg);
  text-decoration: none;
  background-color: transparent;
  background-color: initial; }

.Modal a,
.Modal a:hover,
.Modal a:link,
.Modal a:active {
  color: var(--linkFg);
  text-decoration: none;
  background-color: transparent;
  background-color: initial; }

.Modal .content .body {
  /* Text at end of image lists */ }
  .Modal .content .body > caption {
    max-width: var(--maxContentWidth);
    width: 100%;
    margin-left: auto;
    margin-right: auto; }

.placeholder {
  width: 100%;
  position: relative; }

.placeholder-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #aaa; }

.placeholder-text h2 {
  text-align: center;
  width: 100%; }

.placeholder-cta {
  position: absolute;
  right: 1rem;
  bottom: 1rem; }

.placeholder-cta,
.placeholder-cta a {
  font-size: 2rem;
  color: #aaa; }

.placeholder-cta a:hover,
.placeholder-cta a:hover .placeholder-cta-arrow {
  font-size: 2rem;
  color: #e83e8c;
  stroke: #e83e8c;
  fill: #e83e8c; }

.placeholder-cta-arrow {
  stroke: #aaa;
  fill: #aaa; }

.content {
  background-color: #fff; }

.login {
  overflow-x: hidden; }

.menu-profile {
  text-align: center !important; }

.menu-profile h4 {
  font-weight: lighter; }

.menu-profile .avatar {
  border-radius: 50%;
  padding: 1rem; }

.menu-profile .logout {
  margin-bottom: 1rem;
  display: block; }

.App > .content {
  /* Typography */ }
  .App > .content h1 {
    width: 100%;
    margin: 1rem auto 2rem;
    font-weight: 200;
    color: var(--dark); }

.login .content {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 0.5;
  pointer-events: none; }

.shortcut {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  background: #fff;
  z-index: 1;
  border-left: 1px solid #eee;
  color: #ccc; }

.shortcut:hover {
  text-decoration: none;
  background: #007bff;
  color: #fff; }

.login-page {
  position: fixed;
  top: 3.5rem;
  left: 0;
  right: 0;
  bottom: 0; }

.FadeIn-enter {
  opacity: 0; }

.FadeIn-enter-active {
  transition: opacity 0.5s ease-in; }

.FadeIn-enter-end {
  opacity: 1; }

/* ----------------------------- */
.FadeIn-leave {
  opacity: 1; }

.FadeIn-leave-active {
  transition: opacity 0.5s ease-in; }

.FadeIn-leave-end {
  opacity: 0; }

.Appear-enter {
  opacity: 0;
  height: 0; }

.Appear-enter-active {
  transition: opacity 0.5s ease-out, height 0.3s ease-out; }

.Appear-enter-end {
  opacity: 1; }

/* ----------------------------- */
.Appear-leave {
  opacity: 1; }

.Appear-leave-active {
  transition: opacity 0.4s ease-out, height 0.3s ease-out; }

.Appear-leave-end {
  opacity: 0;
  height: 0; }

/**
 * Success message
 */
.SuccessMessage {
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 0.95; }

.SucessAnimation {
  /* ----------------------------- */ }
  .SucessAnimation-enter {
    opacity: 0; }
  .SucessAnimation-enter-active {
    transition: all 0.15s ease-in; }
  .SucessAnimation-enter-end {
    opacity: 1; }
  .SucessAnimation-leave {
    opacity: 1; }
  .SucessAnimation-leave-active {
    transition: all 0.65s ease-out; }
  .SucessAnimation-leave-end {
    opacity: 0; }

/**
 * Page nav
 */
.PageNav {
  /* ----------------------------- */ }
  .PageNav-enter {
    opacity: 0;
    -webkit-transform: translateX(30%);
            transform: translateX(30%); }
  .PageNav-enter-active {
    transition: opacity 0.7s ease-out, -webkit-transform 0.5s ease-out;
    transition: opacity 0.7s ease-out, transform 0.5s ease-out;
    transition: opacity 0.7s ease-out, transform 0.5s ease-out, -webkit-transform 0.5s ease-out; }
  .PageNav-enter-end {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .PageNav-leave {
    opacity: 1; }
  .PageNav-leave-active {
    position: absolute;
    width: 100%;
    transition: opacity .7s ease-out, -webkit-transform 0.5s ease-out;
    transition: opacity .7s ease-out, transform 0.5s ease-out;
    transition: opacity .7s ease-out, transform 0.5s ease-out, -webkit-transform 0.5s ease-out; }
  .PageNav-leave-end {
    opacity: 0;
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }

