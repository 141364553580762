.LoginWidget {
  #Login-Logo {
    max-width: 13rem;
    margin-left: 0.65rem;
  }
  
  .header,
  .footer {
    justify-content: center;
  }
}

.App > .content {
  height: 100vh;
}

.Modal.MessageModal {
  .content {
    margin-top: 3rem;
    max-width: 35rem;
    height: 20rem;
    
    & > .header {
      text-align: center;
      font-size: 1.25em;
      align-items: center;
      justify-content: center;
    }

    & > .body {
      display: flex;
      flex-direction: column;
      padding: var(--padVert) var(--padHoriz);
      font-size: 1.1em;
      max-width: 30rem;
      align-self: center;
      justify-content: center;
      height: calc(20rem - var(--modalFooterHeight) - var(--modalHeaderHeight));
      margin-left: auto;
      margin-right: auto;
    }
    & > .footer {
      .ButtonBar {
        justify-content: center;
        margin-bottom: var(--padVert);
        width: 100%;

        button {
          padding: 1rem 2.5rem;
        }
      }
    }
  }
}

.Fullpage {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}