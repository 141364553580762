.ErrorPage {
  .Button {
    margin-top: 2rem;
  }
}


.StartPage {
  #Inline-Logo {
    width: 7rem;
  }
  
  .LogoutMenu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
  }
  
  .Hero-Influencer {
    position: relative;
  }

  .HeroContainer {
    background-color: black;
    width: 100%;
  }

  #Page-Logo {
    width: 16rem;
    margin-bottom: 1rem;
    z-index: 999;
  
    @media (max-width: 576px) {
      width: 12rem;
    }
  }

  .HeroBody-Stripe {
    padding-right: 2rem;
    padding-left: 2rem;
    transition: all 1.2s ease-in-out;

    &.Tagline_0 {
      background-color: #F3BD9B59;
      color: #fff;
    }
    &.Tagline_0 {
      background-color: #7890B259;
      color: #fff;
    }
    &.Tagline_2 {
      background-color: #0A3E6159;
      color: #fff;
    }

    @media (max-width: 576px) {
      transform: scale(0.8) translateY(-85%);
    }
  }

  .HeroOverlay {
    z-index: 1;
    position: absolute;
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0), rgba(0,0,0,0.6));
  }
  .HeroContainer .Hero-Influencer.HeroBody {
    z-index: 9;
    position: absolute;
    bottom: 12%;
    margin-top: 0;
  }
}

.StartPage-HeroImagesContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  margin-right: -12px;
  max-width: 150vw;
}
.StartPage-HeroImageCol {
  display: flex;
  flex-direction: column;
  margin-bottom: -12px;
  margin-right: 12px;
  flex-grow: 1; // Fill available width
  max-width: calc(100vw/2);

  .StartPage-HeroImage {
    position: relative;
    margin-bottom: 12px;
    flex: 1 1 auto; // Fill available height
    min-height: 20%;
    
    img {
      display: block;
      
      &.visible {
        height: 100%;
        position: absolute;
        object-fit: cover;
      }
      
      &.hidden {
        width: auto;
        height: 100%;
        visibility: hidden;
      }
    }

    &:nth-child(1) {
    }
  }
  
}

/*
 Animations
 */

 .FrontTopLeft--Animation,
 .FrontTop--Animation,
 .FrontTopRight--Animation,
 .FrontBottomLeft--Animation,
 .FrontBottom--Animation,
 .FrontBottomRight--Animation {
  &-leave {
    opacity: 1;
  }
  
  &-leave-active {
    transition: all 1.1s ease-in;
  }

  &-enter-active {
    transition: all 1.5s ease-out;
  }
  
  &-enter-end {
    opacity: 1;
  }
}

.FrontTopLeft--Animation {
  &-enter,
  &-leave-end {
    transform: translate(-60%, -60%);
    opacity: 0;
  }
}
.FrontTop--Animation {
  &-enter,
  &-leave-end {
    transform: translate(0, -60%);
    opacity: 0;
  }
}
.FrontTopRight--Animation {
  &-enter,
  &-leave-end {
    transform: translate(60%, -60%);
    opacity: 0;
  }
}
.FrontBottomLeft--Animation {
  &-enter,
  &-leave-end {
    transform: translate(-60%, 60%);
    opacity: 0;
  }
}
.FrontBottom--Animation {
  &-enter,
  &-leave-end {
    transform: translate(0%, 60%);
    opacity: 0;
  }
}
.FrontBottomRight--Animation {
  &-enter,
  &-leave-end {
    transform: translate(60%, 60%);
    opacity: 0;
  }
}


.TypeWriter--Animation {
  &-leave {
    opacity: 1;
  }
  
  &-leave-active {
    transition: all 1.1s ease-in;
  }

  &-enter,
  &-leave-end {
    // transform: translate(60%, 60%);
    opacity: 1;
  }

  &-enter-active {
    transition: all 1.5s ease-out;
  }
  
  &-enter-end {
    opacity: 1;
  }
}