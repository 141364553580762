.formlib-AutoCompleteDropdown {
  display: none;
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
  margin-left: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  flex-direction: column-reverse;
  
  &.showTypeAhead {
    display: flex;
  }


  .formlib-AutoComplete-TypeAhead {
    pointer-events: none; /* This tag obscrues the intranet tag preventing it from being removed */
    padding: 0.1rem 0.5rem;
    position: absolute;
    top: 0;
    left: 0;
  }

  .formlib-AutoComplete-TypeAheadPrefix {
    visibility: hidden;
  }

  .formlib-AutoComplete-TypeAheadSuffix {
    color: #ccc;
  }
}

.formlib-AutocompleteItem {
  padding: 0.6em;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  text-align: left; // new

  &:hover {
    background-color: lighten(#2193c4, 10%) !important;
    color: white;

    h3,
    h4 {
      color: white;
    }
  }

  &:last-child {
    border-bottom: 0;
  }
}

.formlib-AutocompleteItem--Highlight {
  background-color: #2191c4;
  color: white;
}

.formlib-AutosuggestItemThumbnail {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin: 0.2rem 0.5rem 0.2rem 0;
}

.formlib-AutocompleteItem--Animation {
  &-leave {
    // Leave animation start state
    // width: auto;
    opacity: 1;
  }

  &-leave-active {
    // Leave animation transitions
    overflow: hidden;
    transition: height 0.3s ease-out, opacity 0.2s ease-in;
  }

  &-leave-end {
    // Leave animation end state
    // width: 0;
    height: 0;
    opacity: 0;
  }

  &-enter {
    // Enter animation start state
    height: 0;
    opacity: 0;
  }

  &-enter-active {
    // Enter animation transitions
    transition: height 0.2s ease-out, opacity 0.5s ease-in;
  }

  &-enter-end {
    // Enter animation end state
    opacity: 1;
  }
}

#topSpots__Field {
  input.form-input {
    width: 25em;
  }

  .form-input-field {
    position: relative;
  }

  .GeoPointField-static_map {
    position: absolute;
    width: 6em;
    right: 0;
    bottom: 0;
  }
}
