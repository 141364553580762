.WorkflowStatus {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .Dropdown {
    --dropdownHoverBg: transparent;
    --dropdownHoverFg: inherit;

    min-width: 12rem;
  }

  .status {
    color: var(--secondaryBg);
    font-size: 1.5em;
    text-align: right;
    font-weight: 600;
    text-transform: uppercase;
  }

  .Button {
    width: 100%;
    text-align: left;
  }

  h3 {
    font-size: 0.85em;
    margin-bottom: 0;
  }

  .Dropdown .header {
    font-size: 1.2em;
    margin-bottom: 1rem;
  }

  .Icon-Arrow {
    color: var(--iconFill);
    fill: currentColor;
    stroke: currentColor;
    transform: rotate(-90deg);
    &.active {
      color: var(--iconAccentedFill);
    }
  }
}