.Onboarding,
.ContentPage.Onboarding {
  background-color: var(--pageBg);
  justify-content: flex-start; // <- not on kameleonten

  #Page-Logo {
    position: sticky;
    z-index: 9;
    top: 0; // <- not on kameleonten
    left: 0; // 50%
    width: 100%;  // 15rem
    padding: 1rem var(--padHoriz) var(--padVert); // <- not on kameleonten
    background-color: var(--menuBg); // <- not on kameleonten
    opacity: 1;
    transition: 0.2s ease-in;
    text-align: center; // <- not on kameleonten

    .logo { // <- not on kameleonten
      display: inline-block;
      margin-left: 12px;
      max-width: 13rem;
      width: 100%;
    }
  }

  .Onboarding-Content {
    width: 100%;
    max-width: var(--maxFormWidth);
    margin: 0 auto;
    justify-self: center; // <- not on kameleonten

    .body {
      padding-bottom: var(--footerHeight);
    }

    .section.has-columns { // <- not on kameleonten
      gap: calc(4 * var(--gapHoriz));
    }
  }

  h1 {
    text-align: center;
  }

  h2 {
    margin: 2.5rem 0 2rem;
    text-align: center;
    width: 100%;
  }

  h3 {
    text-align: center;
    font-size: 1.5em;
    width: 100%;
  }

  h4 {
    font-size: 1em;
  }
  
  .Onboarding-Link-Button {
    margin: 1rem auto;
  }

  .Onboarding-ActionContainer {
    display: flex;
    padding: 2rem 0 0.5rem;
    margin: 0 auto;
    max-width: 35rem;


    @media (min-width: 576px) {
      padding-top: 4rem;
    }
  }
  
  .Onboarding-Action-Button {
    min-width: 15rem;
  }

  // .Onboarding-Avatar,
  // .AvatarImageFieldWidget {
  //   display: block;
  //   margin: 4rem auto 2rem;
  //   border-radius: 50%;
  //   width: 100%;
  //   min-width: 8rem;
  //   max-width: 12rem;
  // }

  .AvatarImageFieldWidget {
    margin: 3em 0 2em;
  }
  .Onboarding-Avatar {
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    width: auto;
  }

  .Onboarding-AcceptTerms {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    text-align: center;

    @media (min-width: 576px) {
      flex-direction: row;
      border-top: 1px solid #eee;

      .form-row {
        margin-bottom: 0.5rem;
      }
    }

    .form-row {
      position: relative;
    }
    .form-input-checkbox {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      margin: 0;
    }
    .form-input-label {
      margin-left: 1.5rem;
      vertical-align: 1px;
    }
  }
}

// Page navigation animation
.OnboardingPageNav {
  &-enter .Onboarding-Content {
    opacity: 0;
    transform: translateX(50%);
  }
  &-enter-active .Onboarding-Content {
    transition: opacity 0.4s ease-out, transform 0.5s ease-in;
  }
  &-enter-end .Onboarding-Content {
    opacity: 1;
    transform: translateX(0);
  }
  /* ----------------------------- */
  &-leave .Onboarding-Content {
    opacity: 1;
    transform: translateX(0);
  }
  &-leave-active {
    position: absolute;
    top: 0;
    width: 100%;
    .Onboarding-Content {
      transition: opacity .4s ease-out, transform 0.5s ease-in;
    }
  }
  &-leave-end .Onboarding-Content {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.Onboarding,
.ContentPage.Onboarding {
  // v-- not in kameleonten
  & > .body {
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    align-items: center;
    justify-content: center;

    .column {
      max-width: 35rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .keep-together {
    break-inside: avoid;
    display: block;
  }

  .Onboarding-ActionBar {
    // margin-top: 2rem;
    // margin-bottom: 1rem;
    background-color: var(--pageBg);
    width: 100%;
    text-align: center;

    &.sticky-vert {
      margin-bottom: 0;
      border-top: 1px solid #eee;
    }

    .formlib-FormRows {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0 var(--padHoriz);
      gap: 2rem;
      text-align: center;
      width: 100%;

      .form-row {
        margin-bottom: 0;
      }
    }

    button {
      margin: var(--padVert) var(--padHoriz);
      padding: 1rem;
      min-width: 15rem;
    }

    .form-row {
      flex-grow: 0;
      label {
        margin-bottom: 0;
      }
    }

    @media (min-width: 576px) {
      flex-direction: row;

      .form-row {
        margin-bottom: 0.5rem;
      }
    }
  }

  .Onboarding-Hero {
    position: absolute;
    display: block;
    width: 100%;
    height: 22rem;
    top: 0;
    left: 0;
    background-color: #eee;
    border: none;
  }

  .Onboarding-Hero-Field {
    position: absolute;
    display: block;
    width: 100%;
    height: 22rem;
    top: 0;
    left: 0;

    .upload {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0rem;
      right: 0rem;
      width: 15rem;
      height: 10rem;

      color: var(--iconFill);
      font-weight: 400;
      
      .IconButton {
        color: var(--iconInvertedFill);
        &:hover {
          color: var(--iconHover);
        }
        &:active {
          color: var(--iconActive);
        }
      }
    }
  }

  .HeroContainer {
    position: relative;
    flex-shrink: 0;
    flex-grow: 0;
    margin-top: 0;
    width: 100%;
    height: 23.75rem;
    max-width: var(--maxHeaderWidth);

    .Hero {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
    }

    .Hero-Field {
      height: 100%;
    }
    
    .AvatarContainer {
      
    }
  }

  .Hero-Field.formlib-FileUploadWidget .upload {
    color: white;
    .IconButton {
      color: white;
      &:hover {
        color: var(--iconHover);
      }
      &:active {
        color: var(--iconActive);
      }
    }
  }

  
  .Presentation {
    padding: 0 var(--padHoriz);

    .select-template {
      padding: 1rem 1rem;
    }
  
    label#title {
      display: none
    }
  
    h3 {
      display: flex;
      align-items: center;
      text-align: left;
      font-size: 1em;
      font-weight: 400;
      margin-bottom: 0;
      margin-top: 1rem;
  
      button {
        margin-left: auto;
        font-size: 1em;
      }

      &:hover {
        background-color: var(--listItemHoverColor);
      }
    }
  
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        font-size: 0.85em;
        line-height: 1.75em;
      }
    }
  }
  // ^-- not in kameleonten
}


.Onboarding.EditModal {

  .IEditItem {
    flex-shrink: 0;
    padding: calc(var(--headerHeight) + 2rem) 0 var(--footerHeight);
    min-height: calc(100% - var(--footerHeight));
  }
  
  .formlib-FormRows {
    margin-top: 0;
  }

  .form-input-label#title,
  .form-input-label#body {
    display: none;
  }

  .form-input#title__Field {
    font-size: 1.5em;
  }

  .NavBar {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: var(--headerHeight);
    max-width: var(--maxHeaderWidth);
    padding: 0 var(--padHoriz);
    z-index: 9;
    border-bottom: 1px solid var(--inputBorderColor);

  }

  .MobiledocToolbar {
    position: fixed;
    top: 0;
    left: 50%;
    z-index: 9;
    transform: translateX(-50%);
    height: calc(var(--headerHeight) - 1px);
    max-width: var(--maxContentWidth);
    padding: 0 var(--padHoriz);
    background-color: var(--pageBg);

    .leading,
    .trailing {
      display: none;
    }
  }

  .ActionsToolbar {
    position: fixed;
    bottom: 0;

    .Toolbar {
      border-top  : 1px solid var(--inputBorderColor);
      background-color: var(--pageBg);
    }
  }

  .ButtonBar.SaveActions {
    padding: var(--padVert) var(--padHoriz);

    button:first-child {
      margin-right: 1rem;
    }
  }
}