.PageFooter {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  width: 100%;
  margin-top: auto;
}

.PageFooterBody {
  font-weight: 100;
  color: #faddb4;
  font-size: 0.75em;
  padding: 0.5em;
  line-height: 1.25em;

  @media (max-width: 576px) {
    padding: 0.5em;
    font-size: 0.7em;
  }
}