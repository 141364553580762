.MobiledocToolbar {
  .ButtonClose {
    margin: 0 var(--padHoriz);
  }

  .Button {
    padding: 2px;

    img {
      width: 2rem;
    }
  }

  .Dropdown-Wrapper .Button,
  .Button.dropdown-toggle {
    padding: var(--padVert) var(--padHoriz);
  }
}