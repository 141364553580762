.ImageListField-InlineMediaLibrary {
  --modalFooterHeight: 10rem;
  height: calc(100vh - var(--modalFooterHeight))!important;
  border-bottom: 1px solid var(--inputRowLineColor);
  .content {
    overflow-y: hidden;
  }
}

.MediaLibrary {
  position: relative;
  height: 100%;
  width: 100%;

  .filter {
    position: absolute;
    z-index: 1;
    top: 0.3rem;
    right: 0.3rem;
    width: calc(20rem - 0.6rem);

    #search__Field {
      height: calc(1.7em + .75rem + 2px);
      padding: .375rem .75rem .375rem 1em;
      font-size: 1rem;
      font-weight: 300;
      line-height: 1.7;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: calc((1.7em + 0.75rem) / 2);
    }
  }

  & > .body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    padding-right: 0;

    .thumbnails {
      flex-grow: 1;
      height: 100%;
      overflow-y: scroll;
      position: relative;
      padding-bottom: var(--padVert);
      border-radius: 0;
      gap: var(--gapVert) var(--gapHoriz);
    }

    .thumbnailDetails {
      width: 20rem;
      margin-top: 4rem;
      padding: var(--padVert) var(--padHoriz);
      overflow-y: auto;

      h2 {
        font-weight: 300;
      }

      .Button,
      .info {
        margin-bottom: var(--formRowGap);
      }
    }

  }

  .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  .thumbnailDetails {
    &.no-selection {
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 1.3em;
      }
      .Button {
        align-self: center;
        margin-bottom: 1rem;
      }
      .info {
        text-align: center;
        font-size: 0.75em;
      }
    }
  }

  .list {
    position: relative;
    height: 100%;
  }

  .MessageCanvas.withOverlay {
    background-color: var(--backdropFill);
    z-index: 9;
  }

  .formlib-UploadProgress {
    --backdropFill: rgba(0,0,0,0.6);
  }

  .DragNDroppable {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.6s ease-in;
    border-radius: 0;
    
    .text {
      display: none;
    }
    
    &.DragNDroppable--drag {
      position: absolute;
      pointer-events: all;
      opacity: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,0.6);
      
      .text {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 400;
        color: white;
        font-size: 1.85em;
      }
    }
  }
}
