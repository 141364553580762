.FullName {
  font-size: 1.7em;
  display: block;
  margin: 0 auto;
}

.AvatarWrapper {
  transform-origin: bottom center;
  width: 100%;
}

.ListFilter {
  width: 100%;
  margin: 0.5em auto;
  padding: 0.5em;
  font-size: 1em;
}

.placeholder {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}