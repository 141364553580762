.GuidePage {
  h1 {
    margin: 2rem 0 1.5rem;
  }

  .Actions {
    h2 {
      font-family: 'Adobe Caslon Pro', serif;
      font-weight: 600;
      font-size: 0.8em;
      margin: 0 1rem;
    }
  }

  .GuidePage-Next {
    margin: 2rem auto;
    max-width: 40rem; /* same as .mobiledoc container*/
    
    .col:first-child {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      flex-grow: 0;
      margin-left: auto;
      min-width: 12.5rem;
      max-width: 25rem;
      border-top: 2px solid var(--primary-20);
    }

    h3 {
      margin: 0;
      text-align: left;
      font-size: 0.9em;
    }
    h4 {
      margin: 0;
      text-align: left;
      font-size: 0.7em;
    }
    .btn {
      display: block;
      margin: 0 0 0 -5px;
    }
  }
}