.MediaCard.isTemplate {
  & > .Gallery {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 56%;
    background-color: var(--inputRowLineColor);

    &:after {
      content: 'Media';
      font-size: 2em;
      font-weight: bold;
      opacity: 0.15;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  & > .body {
    pointer-events: none;

    caption {
      width: 20em;
      height: 2em;
      margin: 0.75rem 0;
      background-color: var(--inputRowLineColor);
    }
  }
}

.MediaCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
    vertical-align: top;
  }

  .ImageField.card {
    flex-direction: row;
  }

  .card-img {
    width: 100%;
  }

  .body {
    width: 100%;
  }

  caption {
    font-size: 1em;
    margin-bottom: 1rem;
    text-align: center;
  }
}

/* Chrome for editing modal, should be refactored into a single place */

.MediaCardModal {

  .ImageField.card {
    flex-direction: column;
  }

  .card-img {
    margin-right: 0;
    height: 10rem;
    width: auto;
    margin-right: auto;
  }

  .card-body {
    width: 100%;
  }

  .card-footer {
    text-align: left;
  }

  .ImageField-Action {
    display: inline-block;
    padding: 0.5rem;

    &:hover {
      background-color: rgba(255,255,255,0.6);
    }
  }
}
