.ListCard.isTemplate {
  & > h2 {
    display: block;
    width: 15em;
    height: 1.5em;
    background-color: var(--inputRowLineColor);
  }

  & > .Gallery {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 56%;
    background-color: var(--inputRowLineColor);

    &:after {
      content: 'List';
      font-size: 2em;
      font-weight: bold;
      opacity: 0.15;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  & > .body {
    pointer-events: none;

    p {
      display: block;
      width: 20em;
      height: 2em;
      margin: 0.75rem 0;
      background-color: var(--inputRowLineColor);
    }
    
    ul.ListCard-TheList {
      display: block;
      width: 100%;
      height: 10em;
      background-color: var(--inputRowLineColor);
    }

    Button {
      background-color: var(--inputRowLineColor);
    }
  }
}

.ListCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem auto;

  h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 1em;
  }

  img {
    width: 100%;
  }

  .body {
    width: 100%;
  }

  .Button {
    margin: 0.5rem auto 0 calc(50% - var(--maxContentWidthNarrow) / 2);
  }
  
  .ListCard-TheList {
    position: relative;
    max-width: var(--maxContentWidthNarrow);
    margin: 0 auto;
  
    li {
      list-style-type: decimal;
      list-style-position: inside;
      font-weight: 100;
      margin-bottom: 0.75em;
      line-height: 1.3em;
    }
  }
}

.FadeOut:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2rem;
  z-index: 1;
  opacity: 1;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.Icon-Clock,
.Icon-People {
  vertical-align: text-bottom;
}

.ListCardModal {
  #gps__Field .form-input-object-field {
    display: flex;
    justify-content: space-between;

    .form-row input {
      border-top: none;
      border-right: none;
      border-radius: 0;
      max-width: 4rem;
    }  
  }
}

.ListCardLightbox {
  .media-body {
    width: 100%;
  }
  .ListCard-TheListWrapper {
    display: flex;
  }

  .ListCard-TheList {
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;
    counter-reset: list;
    padding-left: 0;
    padding-right: 0;
    margin: 2.5rem auto;

    li::before {
      counter-increment: list;
      content: counter(list) ".";
      position: absolute;
      left: 0;
      top: 0;
      font-size: 2rem;
      line-height: 1em;
      font-weight: 700;
      color: #bdbdbd;
    }

    li {
      position: relative;
      padding-left: 4.5rem;
      padding-right: 4.5rem;
      margin: 0 auto 2rem 0;
    }

    .body {
      width: auto;
      padding: 0;
    }

    img {
      max-height: 30rem;
      object-fit: cover;
    }

    .thumbnail {
      max-height: 120px;
      max-width: 120px;
    }
  }
}